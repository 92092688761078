import axios from "axios";

const HOST = process.env.REACT_APP_HOST || "localhost";

const PORT = process.env.REACT_APP_PORT || "3500";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://" + HOST + ":" + PORT + "/api";

export default axios.create({
  baseURL: API_BASE_URL,
});

export const axiosPrivate = axios.create({
  baseURL: API_BASE_URL,

  headers: { "Content-Type": "application/json" },

  withCredentials: true,
});
