import { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Form from "../../utilities/Forms";
import useAxiosPrivate from "../auths/hooks/useAxiosPrivate";
import useAuth from "../auths/hooks/useAuth";
import { Box } from "@mui/system";

const Consultant = () => {
  const errRef = useRef();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    lastname: "",
    firstname: "",
    personnelNumber: "",
    consultant_id: "",
    einheit: "",
    standort: "",
  });

  const [validate, setValidate] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmedPassword, setShowConfirmedPassword] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  const axiosPrivate = useAxiosPrivate();
  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const setConsultant = (data) => {
      setFormData((prevState) => ({
        ...prevState,
        lastname: data?.lastname,
        firstname: data?.firstname,
        email: data?.email,
        personnelNumber: data?.personnelNumber,
        consultant_id: data?.consultant_id,
        standort: data?.standort,
        einheit: data?.einheit,
      }));
    };

    const getConsultant = async () => {
      try {
        const response = await axiosPrivate.get("/consultants/" + auth.consultant_id);
        isMounted && setConsultant(response.data);
      } catch (err) {
        console.log(err);
        navigate("/login", { replace: true });
      }
    };

    getConsultant();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [auth.consultant_id, axiosPrivate, navigate]);

  const saveConsultant = async () => {
    try {
      await axiosPrivate
        .post("/consultants", {
          consultant_id: formData.consultant_id,
          password_new: formData.password,
          email: formData.email,
          firstname: formData.firstname,
          lastname: formData.lastname,
          standort: formData.standort,
          einheit: formData.einheit,
        })
        .then(() => {
          setSuccess(true);
          setFormData((prevState) => ({
            ...prevState,
            password: "",
            confirmPassword: "",
          }));
        });
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response" + err);
      } else if (err.response?.status === 409) {
        setErrMsg("Username Taken");
      } else {
        setErrMsg("Registration Failed");
      }
      errRef.current?.focus();
    }
  };

  const validateConsultant = () => {
    let isValid = true;

    let validator = Form.validator({
      einheit: {
        value: formData.einheit,
        isRequired: true,
      },
      standort: {
        value: formData.standort,
        isRequired: true,
      },
      lastname: {
        value: formData.lastname,
        isRequired: true,
      },
      firstname: {
        value: formData.firstname,
        isRequired: true,
      },
      email: {
        value: formData.email,
        isEmail: true,
      },
      password: {
        value: formData.password,
        isRequired: true,
        isPwd: true,
      },
      confirmPassword: {
        value: formData.confirmPassword,
        isRequired: true,
        isConfirmPwd: true,
      },
    });

    if (validator !== null) {
      setValidate({
        validate: validator.errors,
      });

      isValid = false;
    }
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validate = validateConsultant();

    if (validate) {
      saveConsultant();
    }
  };

  const togglePasswordVisibility = (setter, currentState) => {
    setter(!currentState);
  };

  const translateRole = (role) => {
    switch (role) {
      case 9030:
        return "Berater";
      case 8888:
        return "Auditor";
      case 1105:
        return "Führungskraft";
      case 6868:
        return "Admin";
      default:
        return "Undefiniert";
    }
  };

  const logout = async () => {
    setAuth(null);
  };

  const convertCompetences = (wordlist) => {
    return wordlist.sort().join(", ");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const formFields = [
    { name: "lastname", label: "Name", placeholder: "Name" },
    { name: "firstname", label: "Vorname", placeholder: "Vorname" },
    { name: "email", label: "Email", placeholder: "Email", type: "email" },
    { name: "einheit", label: "Einheit", placeholder: "Einheit" },
    { name: "standort", label: "Standort", placeholder: "Standort" },
  ];

  return (
    <>
      {success ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <div className="col-12 col-md-7 col-lg-6 auth-main-col text-center">
            <div className="d-flex flex-column align-content-end">
              <div className="auth-body mx-auto">
                <div className="row g-0 auth-wrapper" style={{ flexDirection: "column", justifyContent: "center" }}>
                  <h1>Erfolgreich eingeloggt</h1>
                  <p>Willkommen im Überleitungstool. Sie sind wie folgt authorisiert:</p>
                  <div>Berater OE: {auth.consultant_id}</div>
                  <div>Rolle: {translateRole(auth.role)}</div>
                  <div>Initialpasswort geändert: {auth.firstLogin ? "Ja" : "Nein"}</div>
                  <br />
                  <div>Zugriffsrecht über: </div>
                  {convertCompetences(auth.competences)}
                  <br />
                  <br />
                  <Link to="/login">Bitte erneut einloggen</Link>
                </div>
              </div>
            </div>
          </div>
        </Box>
      ) : (
        <div className="row g-0 auth-wrapper">
          <div className="col-12 col-md-7 col-lg-6 auth-main-col text-center">
            <div className="d-flex flex-column align-content-end">
              <div className="auth-body mx-auto">
                <p>Profilbearbeitung</p>
                <div className="auth-form-container text-start">
                  <div className="personnelNumber mb-3">
                    <p>OE-Einheit: {formData.personnelNumber}</p>
                  </div>
                  <div className="consultant_id mb-3">
                    <p>Personalnummer: {formData.consultant_id}</p>
                  </div>
                  <form className="auth-form" method="POST" onSubmit={handleSubmit} autoComplete="off">
                    {formFields.map(({ name, label, placeholder, type = "text" }, idx) => (
                      <div key={idx} className="mb-3">
                        <label htmlFor={name} className="form-label">
                          {label}
                        </label>
                        <input
                          type={type}
                          className={`form-control ${validate.validate && validate.validate[name] ? "is-invalid " : ""}`}
                          id={name}
                          name={name}
                          value={formData[name]}
                          placeholder={placeholder}
                          onChange={handleInputChange}
                        />
                        <div
                          className={`invalid-feedback text-start ${validate.validate && validate.validate[name] ? "d-block" : "d-none"}`}
                        >
                          {validate.validate && validate.validate[name] ? validate.validate[name][0] : ""}
                        </div>
                      </div>
                    ))}

                    <label htmlFor="password" className="form-label">
                      Passwort
                    </label>
                    <div className="password mb-3">
                      <div className="input-group">
                        <input
                          type={showPassword ? "text" : "password"}
                          className={`form-control ${validate.validate && validate.validate.password ? "is-invalid " : ""}`}
                          name="password"
                          id="password"
                          placeholder="Passwort"
                          onChange={handleInputChange}
                        />
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-sm"
                          onClick={() => togglePasswordVisibility(setShowPassword, showPassword)}
                        >
                          <i className={showPassword ? "far fa-eye" : "far fa-eye-slash"}></i>{" "}
                        </button>
                        <div
                          className={`invalid-feedback text-start ${validate.validate && validate.validate.password ? "d-block" : "d-none"}`}
                        >
                          {validate.validate && validate.validate.password ? validate.validate.password[0] : ""}
                        </div>
                      </div>
                    </div>

                    <label htmlFor="confirmPassword" className="form-label">
                      Bestätige Passwort
                    </label>
                    <div className="confirmPassword mb-3">
                      <div className="input-group">
                        <input
                          type={showConfirmedPassword ? "text" : "password"}
                          className={`form-control ${validate.validate && validate.validate.confirmPassword ? "is-invalid " : ""}`}
                          name="confirmPassword"
                          id="confirmPassword"
                          placeholder="Bestätige Passwort"
                          onChange={handleInputChange}
                        />
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-sm"
                          onClick={() => togglePasswordVisibility(setShowConfirmedPassword, showConfirmedPassword)}
                        >
                          <i className={showConfirmedPassword ? "far fa-eye" : "far fa-eye-slash"}></i>{" "}
                        </button>
                        <div
                          className={`invalid-feedback text-start ${validate.validate && validate.validate.confirmPassword ? "d-block" : "d-none"}`}
                        >
                          {validate.validate && validate.validate.confirmPassword ? validate.validate.confirmPassword[0] : ""}
                        </div>
                      </div>
                    </div>

                    <div className="text-center">
                      <button type="submit" className="btn btn-primary w-100 theme-btn mx-auto">
                        Speichern
                      </button>
                    </div>
                  </form>

                  <hr />
                  <div className="auth-option text-center pt-2">
                    Zurück zum Login?
                    <Link className="text-link" to="/login" onClick={logout}>
                      Ausloggen
                    </Link>
                  </div>
                  <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
                    {errMsg}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Consultant;
