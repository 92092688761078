import { Button, Grid, TextField } from "@mui/material";
import React, { useCallback, useState } from "react";
import useAxiosPrivate from "../auths/hooks/useAxiosPrivate";
//const CLIENT_LIST = require('../../utilities/client_list');

const AdminControl = () => {
  const axiosPrivate = useAxiosPrivate();
  const [consultant_id, setConsultant_id] = useState("");
  const [verbund_person_nr, setVerbund_person_nr] = useState("");

  const setVerbundLock = useCallback(
    async (lock) => {
      //Axios Call
      console.log("setVerbundLock button");

      const item = {
        verbund_person_nr: verbund_person_nr,
        field_name: "is_locked",
        field_value: lock,
      };
      try {
        await axiosPrivate.post("/client_bundles/updateSingleField", item);

        if (lock) alert("Verbund Kunden Daten " + verbund_person_nr + " wird gesperrt.");
        else alert("Verbund Kunden Daten " + verbund_person_nr + " wird entsperrt.");
      } catch (err) {
        if (!err?.response) {
          console.log("No Server Response" + err);
        } else {
          console.log("SubmitChange Failed");
        }
      }
    },
    [verbund_person_nr, axiosPrivate],
  );

  const unlockConsultant = useCallback(async () => {
    //Axios Call
    console.log("Unlock button");
    try {
      await axiosPrivate.post("/client_bundles/isdone", {
        consultant_ids: [consultant_id],
        is_locked: false,
      });
      alert("Alle Kunden betreut direkt von Berater OE " + consultant_id + " wurde entsperrt. ");
    } catch (err) {
      if (!err?.response) {
        alert("No Server Response" + err);
      } else {
        alert(err.response);
      }
    }
  }, [consultant_id]);

  const lockdown = useCallback(async () => {
    //Axios Call
    console.log("lockdown button");
    try {
      await axiosPrivate.post("/administration", {
        control_option: "lockdown",
        value: "true",
      });
      alert("Alle Daten werden gesperrt, keine Änderungen mehr möglich!");
    } catch (err) {
      if (!err?.response) {
        alert("No Server Response" + err);
      } else {
        alert(err.response);
      }
    }
  }, []);

  const releaseLockdown = useCallback(async () => {
    //Axios Call
    console.log("releaseLockdown button");
    try {
      await axiosPrivate.post("/administration", {
        control_option: "lockdown",
        value: "false",
      });
      alert("Lockdown wurde aufgehoben.");
    } catch (err) {
      if (!err?.response) {
        alert("No Server Response" + err);
      } else {
        alert(err.response);
      }
    }
  }, []);
  /*
  const buildVerbund = useCallback(() => {
    //Axios Call
    console.log('buildVerbund');
    try {
      alert('Verbund wird gebildet, bitte ein paar Minuten warten und die Einheit überprüfen.');
      axiosPrivate.post('/administration/buildVerbund');
    } catch (err) {
      if (!err?.response) {
        alert('No Server Response' + err);
      } else {
        alert(err.response);
      }
    }
  }, []);
   const buildEinheit = useCallback(() => {
    //Axios Call
    console.log('buildEinheit');
    try {
      alert('Einheit wird gebildet, bitte ein paar Minuten warten und die Einheit überprüfen.');
      axiosPrivate.post('/administration/buildEinheit', {
        p_field: 'person_nr',
        q_field: 'risk_unit_nr',
        name_field: 'person_name',
      });
    } catch (err) {
      if (!err?.response) {
        alert('No Server Response' + err);
      } else {
        alert(err.response);
      }
    }
  }, []); */

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Grid container dir="row">
        <h1>Entsperren Verbund Kundendaten vom Verbund Person Nr.</h1>
        <Grid container dir="row" alignItems="flex-end">
          <Grid item>
            <TextField
              id="verbund_person_nr"
              label="Verbund Person Nr"
              variant="standard"
              onChange={(e) => setVerbund_person_nr(e.target.value)}
            />
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={() => setVerbundLock(true)}>
              Sperren
            </Button>
            <Button variant="contained" onClick={() => setVerbundLock(false)}>
              Entsperren
            </Button>
          </Grid>
        </Grid>
        <p></p>
        <p></p>
        <h1>Entsperren Kundenbearbeitung vom Berater</h1>
        <Grid container dir="row" alignItems="flex-end">
          <Grid item>
            <TextField id="consultant_id" label="Berater OE" variant="standard" onChange={(e) => setConsultant_id(e.target.value)} />
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={unlockConsultant}>
              Entsperren
            </Button>
          </Grid>
        </Grid>
        <p></p>
        <p></p>
        <h1>Lockdown / Entsperrung alle Bearbeitungen</h1>
        <Grid container dir="row" alignItems="flex-end">
          <Grid item>
            <Button variant="contained" onClick={lockdown}>
              Lockdown
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={releaseLockdown}>
              Lockdown Aufheben
            </Button>
          </Grid>
        </Grid>
        <p></p>
        <p></p>
      </Grid>
    </div>
  );
};

export default AdminControl;
