import React from "react";

import { ListItemButton, ListItemText, useTheme } from "@mui/material";

import { useNavigate } from "react-router-dom";

import useAuth from "../auths/hooks/useAuth";

export default function DrawerItem({ item, nested, onClose }) {
  const theme = useTheme();

  const navigate = useNavigate();

  const { auth } = useAuth();

  if (item.roles && !item.roles.includes(auth.role)) {
    return null;
  }

  return (
    <>
      <ListItemButton
        onClick={() => {
          navigate(item.path, { replace: true });

          onClose();
        }}
        sx={{ paddingLeft: nested ? theme.spacing(4) : theme.spacing(2) }}
      >
        <ListItemText primary={item.title} />
      </ListItemButton>
    </>
  );
}
