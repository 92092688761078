import { useState, useRef } from "react";
import { Link } from "react-router-dom";
import Form from "../../utilities/Forms";
import axios from "../api/axios";

const Forgot = () => {
  const [email, setEmail] = useState("");
  const [validate, setValidate] = useState({});
  const [consultant_id, setConsultant_id] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const errRef = useRef();

  const validateforgotPassword = () => {
    let isValid = true;

    let validator = Form.validator({
      email: {
        value: email,
        isRequired: true,
        isEmail: true,
      },
      consultant_id: {
        value: consultant_id,
        isRequired: true,
      },
    });

    if (validator !== null) {
      setValidate({
        validate: validator.errors,
      });

      isValid = false;
    }
    return isValid;
  };

  const forgotPassword = async (e) => {
    e.preventDefault();

    const validate = validateforgotPassword();

    if (validate) {
      try {
        await axios
          .post("/password-reset", {
            consultant_id,
            email,
          })
          .then(() => {
            setValidate({});
            setEmail("");
            alert("Ein Mail zum Zurücksetzen des Passworts wurde an: " + email + " geschickt.");
          });
      } catch (err) {
        console.log({ err });
        if (!err?.response) {
          setErrMsg("Server ist nicht erreichbar.");
        } else if (err.response?.status === 400) {
          setErrMsg(err.response?.data?.message);
        } else {
          setErrMsg("Login fehlgeschlagen : " + err.response?.data?.message);
        }
        errRef.current?.focus();
        //console.log(errMsg);
      }
    }
  };

  return (
    <div className="row g-0 auth-wrapper">
      {/* <div className="col-12 col-md-5 col-lg-6 h-100 auth-background-col">
                <div className="auth-background-holder"></div>
                <div className="auth-background-mask"></div>
            </div> */}

      <div className="col-12 col-md-7 col-lg-6 auth-main-col text-center">
        <div className="d-flex flex-column align-content-end">
          <div className="auth-body mx-auto">
            <p>Passwort Email Reset</p>
            <div className="auth-form-container text-start">
              <form className="auth-form" method="POST" onSubmit={forgotPassword} autoComplete={"off"}>
                <div className="consultant_id mb-3">
                  <input
                    type="consultant_id"
                    className={`form-control ${validate.validate && validate.validate.consultant_id ? "is-invalid " : ""}`}
                    id="consultant_id"
                    name="consultant_id"
                    value={consultant_id}
                    placeholder="Personalnummer"
                    onChange={(e) => setConsultant_id(e.target.value)}
                  />

                  <div
                    className={`invalid-feedback text-start ${validate.validate && validate.validate.consultant_id ? "d-block" : "d-none"}`}
                  >
                    {validate.validate && validate.validate.consultant_id ? validate.validate.consultant_id[0] : ""}
                  </div>
                </div>
                <div className="email mb-3">
                  <input
                    type="email"
                    className={`form-control ${validate.validate && validate.validate.email ? "is-invalid " : ""}`}
                    id="email"
                    name="email"
                    value={email}
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                  />

                  <div className={`invalid-feedback text-start ${validate.validate && validate.validate.email ? "d-block" : "d-none"}`}>
                    {validate.validate && validate.validate.email ? validate.validate.email[0] : ""}
                  </div>
                </div>

                <div className="text-center">
                  <button type="submit" className="btn btn-primary w-100 theme-btn mx-auto">
                    Passwort zurücksetzen
                  </button>
                </div>
              </form>

              <hr />
              <div className="auth-option text-center pt-2">
                <Link className="text-link" to="/login">
                  Zurück zum Login
                </Link>
              </div>
              <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
                {errMsg}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgot;
