import React, { useState } from "react";
import { Login, Logout, Person, Help } from "@mui/icons-material";
import { Avatar, IconButton, ListItemIcon, ListItemText, MenuItem, Popover } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import useAuth from "../auths/hooks/useAuth";
import CLIENT_LIST from "../../utilities/client_list";

export default function User() {
  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const subject = "[" + process.env.REACT_APP_CLIENT + "][Überleitungstool Support Ticket]";
  const email = "Ueberleitungstool@zeb.de";

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const Mailto = ({ email, subject, body, children }) => {
    return <a href={`mailto:${email}?subject=${encodeURIComponent(subject) || ""}&body=${encodeURIComponent(body) || ""}`}>{children}</a>;
  };

  const onLogoutClick = () => {
    setAnchorEl(null);
    setAuth(null);
    navigate("/logout", { replace: true });
  };

  if (!auth) {
    return null;
  }

  return (
    <>
      <Box>
        <IconButton color="primary" edge="start" onClick={handleClick}>
          <Avatar sx={{ bgcolor: "white" }}>
            <Person color="primary" />
          </Avatar>
        </IconButton>
      </Box>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {auth ? (
          <>
            <MenuItem onClick={() => navigate("/consultant", { replace: true })}>
              <ListItemIcon>
                <Person />
              </ListItemIcon>
              <ListItemText primary="Profil bearbeiten" />
            </MenuItem>
            <MenuItem onClick={onLogoutClick}>
              <ListItemIcon>
                <Logout />
              </ListItemIcon>
              <ListItemText primary="Ausloggen" />
            </MenuItem>
          </>
        ) : (
          <MenuItem onClick={() => navigate("/login", { replace: true })}>
            <ListItemIcon>
              <Login />
            </ListItemIcon>
            <ListItemText primary="Einloggen" />
          </MenuItem>
        )}
        {process.env.REACT_APP_CLIENT === CLIENT_LIST.HASPA ? (
          <MenuItem onClick={() => navigate("/help", { replace: true })}>
            <ListItemIcon>
              <Help />
            </ListItemIcon>
            <ListItemText primary="Hilfe per Mail" />
          </MenuItem>
        ) : (
          <MenuItem>
            <ListItemIcon>
              <Help />
            </ListItemIcon>
            <Mailto email={email} subject={subject} body="Hi Zeb Team, ...">
              Hilfe per Mail
            </Mailto>
          </MenuItem>
        )}
      </Popover>
    </>
  );
}
