import React, { useCallback, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
//import { Button, ButtonGroup, Grid } from '@mui/material';
import { LicenseManager } from "ag-grid-enterprise";
import useAxiosPrivate from "../auths/hooks/useAxiosPrivate";
// import { grey } from '@mui/material/colors';
import CLIENT_LIST from "../../utilities/client_list";
import CLIENT_TYP from "../../utilities/client_type_list";
// Import minimal modules required for charts

LicenseManager.setLicenseKey(process.env.REACT_APP_AGGRID_LICENCE);

const renderEuro = (p) => {
  const amount = p.value;
  return (
    Math.round(amount)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €"
  );
};

const percentFormatter = (params) => {
  const value = params.value;
  if (isNaN(value) || "" || !value) {
    return "0%";
  }
  return (value * 100).toFixed(2).toString() + "%";
};

const toFixed2Formatter = (params) => {
  const value = params.value;
  if (isNaN(value) || "" || !value) {
    return "0";
  }
  return value.toFixed(2).toString();
};

// const setPrinterFriendly = (api) => {
//   const eGridDiv = document.querySelector('#myStatisticGrid');
//   eGridDiv.style.width = '';
//   eGridDiv.style.height = '';
//   api.setDomLayout('print');
// };

const Statistics = () => {
  const axiosPrivate = useAxiosPrivate();
  const gridRef = useRef();
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState();
  const getRowId = (params) => params.data.consultant_id_target;

  const [columnDefs] = useState([
    //setColumnDefs

    {
      headerName: "Berater Information",
      children: [
        {
          field: "consultant_id_target",
          headerName: process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK ? "Berater Nr." : "Berater OE",
          suppressHeaderMenuButton: true,
        },
        {
          field: "lastname",
          headerName: "Nachname",
          //
          suppressHeaderMenuButton: true,
          initialWidth: 180,
        },
        {
          field: "firstname",
          headerName: "Vorname",
          initialWidth: 180,
          suppressHeaderMenuButton: true,
        },
        {
          field: "consultant_typ",
          headerName:
            process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK
              ? "Beratertyp"
              : process.env.REACT_APP_CLIENT === CLIENT_LIST.HASPA
                ? "Funktion"
                : "Beratertyp OE",
        },
        {
          field: "einheit",
          headerName: "Einheit",
          editable: true,
          hide: process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK ? true : false,
        },
        {
          field: "standort",
          headerName: "Standort",
          editable: true,
          hide: process.env.REACT_APP_CLIENT === CLIENT_LIST.SKSP ? false : true,
        },
        {
          field: "sollstelle",
          headerName: "Sollstelle",
          valueFormatter: toFixed2Formatter,
          filter: "agNumberColumnFilter",
          editable: true,
          hide: process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK || process.env.REACT_APP_CLIENT === CLIENT_LIST.HASPA,
        },
        {
          field: "capacity",
          headerName: "Ist Kapazität",
          valueFormatter: toFixed2Formatter,
          filter: "agNumberColumnFilter",
          editable: true,
        },
      ],
    },
    {
      headerName: "Statistics",
      children: [
        {
          field: "client_count",
          headerName: process.env.REACT_APP_CLIENT === CLIENT_LIST.HASPA ? "Anzahl der Lenkungskunden" : "Anzahl der Kunden",
          filter: "agNumberColumnFilter",
        },
        {
          field: "pure_segment_count",
          headerName: "Anzahl korrekte Segmente",
          filter: "agNumberColumnFilter",
          hide: true,
        },
        {
          field: "segment_purity",
          headerName: "Segmentreinheit",
          filter: "agNumberColumnFilter",
          valueFormatter: percentFormatter,
          hide: true,
        },
        {
          field: "sum_cap",
          headerName: "Auslastung bei 1 MAK",
          hide: true,
          filter: "agNumberColumnFilter",
          valueFormatter: percentFormatter,
        },
        {
          headerName: "Auslastung",
          valueGetter: (params) => {
            const ist_capacity = params.getValue("capacity");
            const sum_cap = params.getValue("sum_cap");
            return ist_capacity == 0 ? 0.0 : sum_cap / ist_capacity;
          },
          valueFormatter: percentFormatter,
          filter: "agNumberColumnFilter",
        },
        {
          field: "sum_fin",
          headerName: "Obligo",
          cellRenderer: renderEuro,
          filter: "agNumberColumnFilter",
          hide: process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK ? true : false,
        },
        {
          field: "sum_turn_over",
          headerName: "Umsatz",
          cellRenderer: renderEuro,
          filter: "agNumberColumnFilter",
          hide: process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK || process.env.REACT_APP_CLIENT === CLIENT_LIST.HASPA,
        },
        {
          field: "sum_vol",
          headerName: "Vermögen",
          cellRenderer: renderEuro,
          filter: "agNumberColumnFilter",
          hide: process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK ? true : false,
        },
        {
          field: "sum_wp",
          headerName: "WP-Vermögen",
          cellRenderer: renderEuro,
          filter: "agNumberColumnFilter",
          hide: process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK || process.env.REACT_APP_CLIENT === CLIENT_LIST.SKPDH ? true : false,
        },
        {
          field: "sum_verbund_haspa_spar",
          headerName: process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK ? "Aktivvolumen" : "Sparfähigkeit",
          cellRenderer: renderEuro,
          filter: "agNumberColumnFilter",
          //hide: process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK ? false : true,
        },
        {
          field: "sum_verbund_ek_bew",
          headerName: process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK ? "Passivvolumen" : "Einkommen",
          cellRenderer: renderEuro,
          filter: "agNumberColumnFilter",
          //hide: process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK ? false : true,
        },
      ],
    },
  ]);
  const defaultColDef = useMemo(() => {
    return {
      //flex: 1,
      filter: true,
      sortable: true,
      floatingFilter: true,
      resizable: true,
      initialWidth: 130,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      // backgroundColor: grey,
    };
  }, []);

  const submitChange = useCallback(
    //TODO update the field in zielberaterliste bzw. beraterliste
    async (item) => {
      //console.log('update client bundle data for: ', item);
      console.log("update client bundle data ");
      try {
        await axiosPrivate.post("/consultants/zielberater", item);
      } catch (err) {
        if (!err?.response) {
          console.log("No Server Response" + err);
        } else {
          console.log("SubmitChange Failed");
        }
      }
    },
    [axiosPrivate],
  );

  const onCellValueChanged = useCallback((event) => {
    //console.log(event);
    // make axios call to update the data in the backend
    const oldData = event.data;
    const field = event.colDef.field;
    const newValue = event.newValue;

    // Check if the new value is a valid number
    const parsedValue = parseFloat(newValue);
    if (isNaN(parsedValue)) {
      alert("Invalid value: Please enter a valid number.");
      return; // Exit the function if the value is not a valid number
    }

    const newData = { ...oldData, [field]: parsedValue };
    //console.log('onCellValueChanged, updating ' + field + ' to ' + event.newValue);
    const tx = {
      update: [newData],
    };
    event.api.applyTransaction(tx);
    //console.log({ newData });

    //Axios call
    submitChange(newData);
  }, []);

  const readData = useCallback(async () => {
    return await axiosPrivate
      .get("consultants/stats")
      .then((res) => res.data)
      .then((data) => {
        setRowData(data);
      });
  }, [axiosPrivate]);

  const onGridReady = useCallback(
    (params) => {
      readData();
      gridRef.current.api = params.api;
    },
    [readData],
  );
  const onFirstDataRendered = useCallback(() => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);
  // const onBtPrint = useCallback(() => {
  //   //const api = gridRef.current.api;
  //   //setPrinterFriendly(api);
  //   console.log('print');
  //   setTimeout(function () {
  //     print();
  //     //setNormal(api);
  //   }, 2000);
  // }, []);
  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agTotalAndFilteredRowCountComponent", align: "left" },
        { statusPanel: "agTotalRowCountComponent", align: "center" },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    };
  }, []);
  return (
    <div style={containerStyle}>
      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        {/* <Grid container dir="row" mb={2} justifyContent="space-between">
          <Grid item>
            <ButtonGroup>
              <Button variant="contained" onClick={onBtPrint}>
                Drucken
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid> */}
        <div style={{ flexGrow: "1", height: "calc(100vh - 150px" }}>
          {/* height: 100% is not enough as AGGrid don't know the wrapper's height */}
          <div id="myStatisticGrid" className="ag-theme-alpine" style={gridStyle}>
            <AgGridReact
              enableCharts={true}
              enableRangeSelection={true}
              ref={gridRef}
              rowData={rowData}
              getRowId={getRowId}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              onFirstDataRendered={onFirstDataRendered}
              onCellValueChanged={onCellValueChanged}
              animateRows={true}
              statusBar={statusBar}
              onGri
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
