class Form {
  /**
   * Validate Login
   * @param str
   * @returns boolean
   */
  static validEmail(str) {
    let regex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return regex.test(str);
  }

  static validPwd(str) {
    let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
    return regex.test(str);
  }

  /**
   * Minimum length of string
   * @param str
   * @param length
   * @returns
   */
  static minLength(str, length) {
    let isInvalid = false;

    if (str.length < length) {
      isInvalid = true;
    }

    return isInvalid;
  }

  /**
   * Form Validator
   * @param  obj
   * @returns
   */
  static validator(obj) {
    let keys = Object.entries(obj);
    let results = [];
    let validations = null;
    let pwd = "";
    let confirmPwd = "";

    keys.map((key) => {
      if ("isRequired" in key[1] && key[1].isRequired) {
        if (key[1].value.length === 0) {
          results.push({
            [key[0]]: [`${key[0]} ist ein Pflichtfeld.`],
          });
        } else {
          if ("isEmail" in key[1] && key[1].isEmail) {
            let isValidEmail = Form.validEmail(key[1].value);

            if (!isValidEmail) {
              results.push({
                [key[0]]: [`${key[0]} muss eine gültige Emailadresse sein.`],
              });
            }
          }

          if ("minLength" in key[1] && Form.minLength(key[1].value, key[1].minLength)) {
            results.push({
              [key[0]]: [`${key[0]} muss mindestens ${key[1].minLength} Zeichen enthalten.`],
            });
          }

          if ("isPwd" in key[1]) {
            pwd = key[1].value;
            //console.log({ pwd });
            let isValidpwd = Form.validPwd(pwd);
            if (!isValidpwd) {
              results.push({
                [key[0]]: [
                  `${key[0]} muss 8 bis 24 Zeichen enthalten, davon Groß- und Kleinbuchstaben, Nummer und speziale Zeichen aus !@#$%`,
                ],
              });
            }
          }

          if ("isConfirmPwd" in key[1]) {
            confirmPwd = key[1].value;
          }
        }
      } else if ("isEmail" in key[1]) {
        let isValidEmail = Form.validEmail(key[1].value);

        if (!isValidEmail) {
          results.push({
            [key[0]]: [`${key[0]} muss eine gültige Emailadresse sein`],
          });
        }
      } else if ("minLength" in key[1] && Form.minLength(key[1].value, key[1].minLength)) {
        results.push({
          [key[0]]: [`${key[0]} muss mindestens ${key[1].minLength} Zeichen enthalten.`],
        });
      } else if ("isPwd" in key[1]) {
        let isValidpwd = Form.validPwd(key[1].value);
        if (!isValidpwd) {
          results.push({
            [key[0]]: [`${key[0]} muss 8 bis 24 Zeichen enthalten, davon Groß- und Kleinbuchstaben, Nummer und speziale Zeichen aus !@#$%`],
          });
        }
      }
      return results;
    });
    //console.log('Matching...');
    //console.log({ confirmPwd });
    //console.log({ pwd });
    if (pwd !== confirmPwd)
      results.push({
        ["confirmPassword"]: [`Bestätigtes Passwort muss mit dem Passwort übereinstimmen`],
      });

    results = Object.assign({}, ...results.map((result) => result));

    //console.log({ results });

    if (Object.keys(results).length > 0) {
      validations = {
        errors: results,
      };
    } else {
      validations = null;
    }

    return validations;
  }
}

export default Form;
