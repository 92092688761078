import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/views/Login";
import Forgot from "./components/views/Forgot";
import Consultant from "./components/views/Consultant";

import DataGrid from "./components/views/DataGrid"; // import DataGrid from "./components/DataGrid/DataGrid";
import Statistics from "./components/views/Statistics";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/system";
import createCustomTheme from "./customMuiTheme";
import Layout from "./components/Layout/Layout";
import UploadConsultants from "./components/views/UploadConsultants";
import UploadSegment from "./components/views/UploadSegment";
import UploadCompetence from "./components/views/UploadCompetence";
import UploadBundle from "./components/views/UploadBundle";
import BundleListe from "./components/views/BundleListe";
import UploadClientPerson from "./components/views/UploadClientPerson";
import ClientPersonListe from "./components/views/ClientPersonListe";
import UploadClient from "./components/views/UploadClient";
import ClientListe from "./components/views/ClientListe";
import ProgressAbgabe from "./components/views/ProgressAbgabe";
import ProgressZuordnung from "./components/views/ProgressZuordnung";
import ProgressAufnahme from "./components/views/ProgressAufnahme";

import RequireAuth from "./components/auths/RequireAuth";
import Unauthorized from "./components/auths/Unauthorized";
import { AuthProvider } from "./components/auths/context/AuthProvider";
import Abgabe from "./components/views/Abgabe";
import GroupDataGrid from "./components/views/GroupDataGrid";
import GridtoGrid from "./components/views/GridtoGrid";
import EinheitBearbeiten from "./components/views/EinheitBearbeiten";
import EinheitSichten from "./components/views/EinheitSichten";
import EinheitProgress from "./components/views/EinheitProgress";
import Aufnahme from "./components/views/Aufnahme";
import Zuordnen from "./components/views/Zuordnen";
import Gesamtbestand from "./components/views/Gesamtbestand";
import ResetPwd from "./components/views/ResetPwd";
import CompetenceListe from "./components/views/CompetenceListe";
import SegmentListe from "./components/views/SegmentListe";
import BeraterListe from "./components/views/BeraterListe";
import ZielberaterListe from "./components/views/ZielberaterListe";
import UploadZielberater from "./components/views/UploadZielberater";
import AdminControl from "./components/views/AdminControl";
import Segmentpruefung from "./components/views/Segmentpruefung";
const ROLES_LIST = require("./components/auths/roles_list");

import { ErrorBoundary } from "react-error-boundary";
import SegmentpruefungProgress from "./components/views/SegmentpruefungProgress";
import OverwriteZielberater from "./components/views/OverwriteZielberater";
import Help from "./components/views/Help";

const ErrorFallback = ({ error }) => {
  // Fallback UI for displaying the error
  return <div>An error occurred: {error.message}</div>;
};

const SimpleCRM = () => {
  const theme = createCustomTheme();

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme>
          <Router>
            <AuthProvider>
              <Layout>
                <Routes>
                  {/* public routes */}
                  <Route path="/login" element={<Login />} />
                  <Route path="/logout" element={<Login />} />

                  <Route path="/forgot-password" element={<Forgot />} />
                  <Route path="/reset-password" element={<ResetPwd />} />
                  <Route path="/unauthorized" element={<Unauthorized />} />
                  <Route
                    element={
                      <RequireAuth allowedRoles={[ROLES_LIST.Consultant, ROLES_LIST.Supervisor, ROLES_LIST.Admin, ROLES_LIST.Auditor]} />
                    }
                  >
                    <Route path="/help" element={<Help />} />
                  </Route>
                  <Route
                    element={
                      <RequireAuth allowedRoles={[ROLES_LIST.Consultant, ROLES_LIST.Supervisor, ROLES_LIST.Admin, ROLES_LIST.Auditor]} />
                    }
                  >
                    <Route path="/einheitbildung" element={<GridtoGrid />} />
                  </Route>
                  <Route
                    element={
                      <RequireAuth allowedRoles={[ROLES_LIST.Consultant, ROLES_LIST.Supervisor, ROLES_LIST.Admin, ROLES_LIST.Auditor]} />
                    }
                  >
                    <Route path="/einheitbearbeiten" element={<EinheitBearbeiten />} />
                  </Route>
                  <Route
                    element={
                      <RequireAuth allowedRoles={[ROLES_LIST.Consultant, ROLES_LIST.Supervisor, ROLES_LIST.Admin, ROLES_LIST.Auditor]} />
                    }
                  >
                    <Route path="/einheitpruefung" element={<GroupDataGrid />} />
                  </Route>
                  <Route
                    element={
                      <RequireAuth allowedRoles={[ROLES_LIST.Consultant, ROLES_LIST.Supervisor, ROLES_LIST.Admin, ROLES_LIST.Auditor]} />
                    }
                  >
                    <Route path="/einheitsichten" element={<EinheitSichten />} />
                  </Route>
                  <Route
                    element={
                      <RequireAuth allowedRoles={[ROLES_LIST.Consultant, ROLES_LIST.Supervisor, ROLES_LIST.Admin, ROLES_LIST.Auditor]} />
                    }
                  >
                    <Route path="/einheitprogress" element={<EinheitProgress />} />
                  </Route>
                  <Route
                    element={
                      <RequireAuth allowedRoles={[ROLES_LIST.Consultant, ROLES_LIST.Supervisor, ROLES_LIST.Admin, ROLES_LIST.Auditor]} />
                    }
                  >
                    <Route path="/segment" element={<Segmentpruefung />} />
                  </Route>
                  <Route
                    element={
                      <RequireAuth allowedRoles={[ROLES_LIST.Consultant, ROLES_LIST.Supervisor, ROLES_LIST.Admin, ROLES_LIST.Auditor]} />
                    }
                  >
                    <Route path="/abgabe" element={<Abgabe />} />
                  </Route>
                  <Route element={<RequireAuth allowedRoles={[ROLES_LIST.Supervisor, ROLES_LIST.Admin, ROLES_LIST.Auditor]} />}>
                    <Route path="/zuordnen" element={<Zuordnen />} />
                  </Route>
                  <Route
                    element={
                      <RequireAuth allowedRoles={[ROLES_LIST.Consultant, ROLES_LIST.Supervisor, ROLES_LIST.Admin, ROLES_LIST.Auditor]} />
                    }
                  >
                    <Route path="/aufnahme" element={<Aufnahme />} />
                  </Route>
                  <Route
                    element={
                      <RequireAuth allowedRoles={[ROLES_LIST.Consultant, ROLES_LIST.Supervisor, ROLES_LIST.Admin, ROLES_LIST.Auditor]} />
                    }
                  >
                    <Route path="/gesamtbestand" element={<Gesamtbestand />} />
                  </Route>

                  <Route
                    element={
                      <RequireAuth allowedRoles={[ROLES_LIST.Consultant, ROLES_LIST.Supervisor, ROLES_LIST.Admin, ROLES_LIST.Auditor]} />
                    }
                  >
                    <Route path="/consultant" element={<Consultant />} />
                  </Route>
                  <Route
                    element={
                      <RequireAuth allowedRoles={[ROLES_LIST.Consultant, ROLES_LIST.Supervisor, ROLES_LIST.Admin, ROLES_LIST.Auditor]} />
                    }
                  >
                    <Route path="/dataGrid" element={<DataGrid />} />
                  </Route>
                  <Route
                    element={
                      <RequireAuth allowedRoles={[ROLES_LIST.Consultant, ROLES_LIST.Supervisor, ROLES_LIST.Admin, ROLES_LIST.Auditor]} />
                    }
                  >
                    <Route path="/segmentProgress" element={<SegmentpruefungProgress />} />
                  </Route>
                  <Route
                    element={
                      <RequireAuth allowedRoles={[ROLES_LIST.Consultant, ROLES_LIST.Supervisor, ROLES_LIST.Admin, ROLES_LIST.Auditor]} />
                    }
                  >
                    <Route path="/abgabeProgress" element={<ProgressAbgabe />} />
                  </Route>
                  <Route
                    element={
                      <RequireAuth allowedRoles={[ROLES_LIST.Consultant, ROLES_LIST.Supervisor, ROLES_LIST.Admin, ROLES_LIST.Auditor]} />
                    }
                  >
                    <Route path="/zuordnungProgress" element={<ProgressZuordnung />} />
                  </Route>
                  <Route
                    element={
                      <RequireAuth allowedRoles={[ROLES_LIST.Consultant, ROLES_LIST.Supervisor, ROLES_LIST.Admin, ROLES_LIST.Auditor]} />
                    }
                  >
                    <Route path="/aufnahmeProgress" element={<ProgressAufnahme />} />
                  </Route>
                  <Route element={<RequireAuth allowedRoles={[ROLES_LIST.Supervisor, ROLES_LIST.Admin, ROLES_LIST.Auditor]} />}>
                    <Route path="/stats" element={<Statistics />} />
                  </Route>
                  <Route element={<RequireAuth allowedRoles={[ROLES_LIST.Admin]} />}>
                    <Route path="/admin_control" element={<AdminControl />} />
                  </Route>
                  <Route element={<RequireAuth allowedRoles={[ROLES_LIST.Admin]} />}>
                    <Route path="/upload_segment_list" element={<UploadSegment />} />
                  </Route>
                  <Route element={<RequireAuth allowedRoles={[ROLES_LIST.Admin]} />}>
                    <Route path="/segment_list" element={<SegmentListe />} />
                  </Route>
                  <Route element={<RequireAuth allowedRoles={[ROLES_LIST.Admin]} />}>
                    <Route path="/upload_competence" element={<UploadCompetence />} />
                  </Route>
                  <Route element={<RequireAuth allowedRoles={[ROLES_LIST.Admin]} />}>
                    <Route path="/competence" element={<CompetenceListe />} />
                  </Route>
                  <Route element={<RequireAuth allowedRoles={[ROLES_LIST.Admin]} />}>
                    <Route path="/upload" element={<UploadConsultants />} />
                  </Route>
                  <Route element={<RequireAuth allowedRoles={[ROLES_LIST.Admin]} />}>
                    <Route path="/beraterliste" element={<BeraterListe />} />
                  </Route>
                  <Route element={<RequireAuth allowedRoles={[ROLES_LIST.Admin]} />}>
                    <Route path="/upload_zielberater" element={<UploadZielberater />} />
                  </Route>
                  <Route element={<RequireAuth allowedRoles={[ROLES_LIST.Admin]} />}>
                    <Route path="/overwrite_zielberater" element={<OverwriteZielberater />} />
                  </Route>
                  <Route element={<RequireAuth allowedRoles={[ROLES_LIST.Admin]} />}>
                    <Route path="/zielberaterliste" element={<ZielberaterListe />} />
                  </Route>
                  <Route element={<RequireAuth allowedRoles={[ROLES_LIST.Admin]} />}>
                    <Route path="/upload_bundle" element={<UploadBundle />} />
                  </Route>
                  <Route element={<RequireAuth allowedRoles={[ROLES_LIST.Admin]} />}>
                    <Route path="/bundleliste" element={<BundleListe />} />
                  </Route>
                  <Route element={<RequireAuth allowedRoles={[ROLES_LIST.Admin]} />}>
                    <Route path="/upload_clientperson" element={<UploadClientPerson />} />
                  </Route>
                  <Route element={<RequireAuth allowedRoles={[ROLES_LIST.Admin]} />}>
                    <Route path="/clientpersonliste" element={<ClientPersonListe />} />
                  </Route>
                  <Route element={<RequireAuth allowedRoles={[ROLES_LIST.Admin]} />}>
                    <Route path="/upload_client" element={<UploadClient />} />
                  </Route>
                  <Route element={<RequireAuth allowedRoles={[ROLES_LIST.Admin]} />}>
                    <Route path="/clientliste" element={<ClientListe />} />
                  </Route>
                  <Route path="/" element={<Login />} />
                </Routes>
              </Layout>
            </AuthProvider>
          </Router>
        </CssBaseline>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default SimpleCRM;
