import { useRef, useState } from "react";
import Form from "../../utilities/Forms";
import useAxiosPrivate from "../auths/hooks/useAxiosPrivate";

const ResetPwd = () => {
  const errRef = useRef();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [validate, setValidate] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmedPassword, setShowConfirmedPassword] = useState(false);

  const [consultant_id, setConsultant_id] = useState("");

  const [errMsg, setErrMsg] = useState("");
  const [setSuccess] = useState(false);

  const axiosPrivate = useAxiosPrivate();

  const resetPassword = async () => {
    try {
      await axiosPrivate
        .post("/password-reset", {
          consultant_id,
          password: password,
          email,
        })
        .then(() => {
          setSuccess(true);
          //clear state and controlled inputs
          setPassword("");
          setConfirmPassword("");
        });
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 409) {
        setErrMsg("Username Taken");
      } else {
        setErrMsg("Registration Failed");
      }
      errRef.current?.focus();
    }
  };

  const validateConsultant = () => {
    let isValid = true;

    let validator = Form.validator({
      consultant_id: {
        value: consultant_id,
        isRequired: true,
      },
      email: {
        value: email,
        isRequired: false,
        isEmail: true,
      },
      password: {
        value: password,
        isRequired: true,
        isPwd: true,
      },
      confirmPassword: {
        value: confirmPassword,
        isRequired: true,
        isConfirmPwd: true,
      },
    });

    if (validator !== null) {
      setValidate({
        validate: validator.errors,
      });

      isValid = false;
    }
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // if button enabled with JS hack
    const validate = validateConsultant();

    if (validate) {
      resetPassword();
    }
  };

  const togglePassword = () => {
    if (showPassword) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  };

  const toggleConfirmedPassword = () => {
    if (showConfirmedPassword) {
      setShowConfirmedPassword(false);
    } else {
      setShowConfirmedPassword(true);
    }
  };

  return (
    <>
      {" "}
      <div className="row g-0 auth-wrapper">
        {/* <div className="col-12 col-md-5 col-lg-6 h-100 auth-background-col">
                <div className="auth-background-holder"></div>
                <div className="auth-background-mask"></div>
            </div> */}

        <div className="col-12 col-md-7 col-lg-6 auth-main-col text-center">
          <div className="d-flex flex-column align-content-end">
            <div className="auth-body mx-auto">
              <p>Profilbearbeitung</p>
              <div className="auth-form-container text-start">
                <form className="auth-form" method="POST" onSubmit={handleSubmit} autoComplete={"off"}>
                  <div className="email mb-3">
                    <input
                      type="email"
                      className={`form-control ${validate.validate && validate.validate.email ? "is-invalid " : ""}`}
                      id="email"
                      name="email"
                      value={email}
                      //required
                      placeholder="Email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <div className={`invalid-feedback text-start ${validate.validate && validate.validate.email ? "d-block" : "d-none"}`}>
                      {validate.validate && validate.validate.email ? validate.validate.email[0] : ""}
                    </div>
                  </div>

                  <div className="password mb-3">
                    <div className="input-group">
                      <input
                        type={showPassword ? "text" : "password"}
                        className={`form-control ${validate.validate && validate.validate.password ? "is-invalid " : ""}`}
                        name="password"
                        id="password"
                        //value={password}
                        placeholder="Passwort"
                        //required
                        onChange={(e) => setPassword(e.target.value)}
                      />

                      <button type="button" className="btn btn-outline-primary btn-sm" onClick={(e) => togglePassword(e)}>
                        <i className={showPassword ? "far fa-eye" : "far fa-eye-slash"}></i>{" "}
                      </button>
                      <div
                        className={`invalid-feedback text-start ${validate.validate && validate.validate.password ? "d-block" : "d-none"}`}
                      >
                        {validate.validate && validate.validate.password ? validate.validate.password[0] : ""}
                      </div>
                    </div>
                  </div>

                  <div className="confirmPassword mb-3">
                    <div className="input-group">
                      <input
                        type={showConfirmedPassword ? "text" : "password"}
                        className={`form-control ${validate.validate && validate.validate.confirmPassword ? "is-invalid " : ""}`}
                        name="confirmPassword"
                        id="confirmPassword"
                        //value={password}
                        required
                        placeholder="Bestätige Passwort"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />

                      <button type="button" className="btn btn-outline-primary btn-sm" onClick={(e) => toggleConfirmedPassword(e)}>
                        <i className={showConfirmedPassword ? "far fa-eye" : "far fa-eye-slash"}></i>{" "}
                      </button>
                      <div
                        className={`invalid-feedback text-start ${
                          validate.validate && validate.validate.confirmPassword ? "d-block" : "d-none"
                        }`}
                      >
                        {validate.validate && validate.validate.confirmPassword ? validate.validate.confirmPassword[0] : ""}
                      </div>
                    </div>
                  </div>
                  <div className="consultant_id mb-3">
                    <input
                      type="text"
                      className={`form-control ${validate.validate && validate.validate.name ? "is-invalid " : ""}`}
                      id="consultant_id"
                      name="consultant_id"
                      value={consultant_id}
                      placeholder="Personalnummer"
                      onChange={(e) => setConsultant_id(e.target.value)}
                    />

                    <div
                      className={`invalid-feedback text-start ${
                        validate.validate && validate.validate.consultant_id ? "d-block" : "d-none"
                      }`}
                    >
                      {validate.validate && validate.validate.consultant_id ? validate.validate.consultant_id[0] : ""}
                    </div>
                  </div>

                  <div className="text-center">
                    <button type="submit" className="btn btn-primary w-100 theme-btn mx-auto">
                      Speichern
                    </button>
                  </div>
                </form>

                <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
                  {errMsg}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPwd;
