import React, { useCallback, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
//import { Button, ButtonGroup, Grid } from '@mui/material';
import { LicenseManager } from "ag-grid-enterprise";
import useAxiosPrivate from "../auths/hooks/useAxiosPrivate";
//import { grey } from '@mui/material/colors';
import { useNavigate } from "react-router-dom";
import CLIENT_LIST from "../../utilities/client_list";

// Import minimal modules required for charts

LicenseManager.setLicenseKey(process.env.REACT_APP_AGGRID_LICENCE);

const BeraterListe = () => {
  const axiosPrivate = useAxiosPrivate();
  const gridRef = useRef();
  const navigate = useNavigate();
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState();
  const getRowId = (params) => params.data.consultant_id;

  const LOGIN_URL = "/login";

  const [columnDefs] = useState([
    //setColumnDefs
    {
      field: "consultant_id",
      headerName: "Berater OE",
    },
    {
      field: "access_status",
      headerName: "access_status",
      editable: true,
    },
    {
      field: "personnelNumber",
      headerName: "Personalnummer",
      editable: true,
    },
    {
      field: "consultant_typ",
      headerName: "Beratertyp",
      editable: true,
    },
    {
      field: "einheit",
      headerName: "Einheit",
      editable: true,
    },
    {
      field: "standort",
      headerName: "Standort",
      editable: true,
      hide: process.env.REACT_APP_CLIENT === CLIENT_LIST.SKSP ? false : true,
    },
    {
      field: "email",
      headerName: "E-Mail",
      editable: true,
    },
    {
      field: "firstname",
      headerName: "Vorname",
      editable: true,
    },
    {
      field: "lastname",
      headerName: "Nachname",
      editable: true,
    },
    {
      field: "is_supervisor",
      headerName: "is_supervisor",
      editable: true,
    },
    {
      field: "read_only",
      headerName: "read_only",
      editable: true,
    },
    {
      field: "login_times",
      headerName: "login_times",
      filter: "agNumberColumnFilter",
      editable: true,
    },
  ]);
  const defaultColDef = useMemo(() => {
    return {
      //flex: 1,
      filter: true,
      sortable: true,
      floatingFilter: true,
      resizable: true,
      minWidth: 150,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      enableCellChangeFlash: true,
    };
  }, []);

  const readData = useCallback(async () => {
    try {
      await axiosPrivate
        .get("consultants/all")
        .then((res) => res.data)
        .then((data) => {
          setRowData(data);
          //console.log(data);
        });
    } catch (err) {
      if (!err?.response) {
        console.log("No Server Response" + err);
      } else {
        console.log("SubmitChange Failed" + err.response.toString);
      }
      navigate(LOGIN_URL);
    }
  }, [axiosPrivate]);

  const submitChange = useCallback(
    async (item) => {
      //      console.log('update Consultant data for: ', item);
      console.log("update Consultant data");

      try {
        await axiosPrivate.post("/administration/updateConsultant", item);
      } catch (err) {
        if (!err?.response) {
          console.log("No Server Response" + err);
        } else {
          console.log("SubmitChange Failed");
        }
      }
    },
    [axiosPrivate],
  );
  const onCellEditRequest = useCallback(
    (event) => {
      const oldData = event.data;
      const field = event.colDef.field;
      const newData = { ...oldData };
      newData[field] = event.newValue;
      //console.log('onCellEditRequest, updating ' + field + ' to ' + event.newValue);
      const tx = {
        update: [newData],
      };
      event.api.applyTransaction(tx);
      //console.log({ newData });

      //Axios call
      submitChange(newData);
    },
    [submitChange],
  );
  const onGridReady = useCallback(
    (params) => {
      readData();
      gridRef.current.api = params.api;
    },
    [readData],
  );
  const onFirstDataRendered = useCallback(() => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);
  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agTotalAndFilteredRowCountComponent", align: "left" },
        { statusPanel: "agTotalRowCountComponent", align: "center" },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    };
  }, []);
  return (
    <div style={containerStyle}>
      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        {/*
          <Grid container dir="row" mb={2} justifyContent="space-between">
            <Grid item>
              <ButtonGroup>
                <Button variant="contained" onClick={onBtPrint}>
                  Drucken
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        */}
        <div style={{ flexGrow: "1", height: "calc(100vh - 150px" }}>
          {/* height: 100% is not enough as AGGrid don't know the wrapper's height */}
          <div id="myStatisticGrid" className="ag-theme-alpine" style={gridStyle}>
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              getRowId={getRowId}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              onFirstDataRendered={onFirstDataRendered}
              onCellEditRequest={onCellEditRequest}
              readOnlyEdit={true}
              animateRows={true}
              statusBar={statusBar}
              onGri
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeraterListe;
