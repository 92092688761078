import ExcelJS from "exceljs";
import iconv from "iconv-lite";
import { useCallback, useRef, useState, React } from "react";
import useAxiosPrivate from "../auths/hooks/useAxiosPrivate";

export default function UploadConsultants() {
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState("");
  const axiosPrivate = useAxiosPrivate();

  const upsertConsultants = useCallback(
    async (item) => {
      console.log("upsertConsultants");
      //Type transfer of the IDs
      item.consultant_id = item.consultant_id.toString();
      item.personnelNumber = item.personnelNumber.toString();

      try {
        await axiosPrivate.post("/consultants/upsertConsultants", item);
      } catch (err) {
        if (!err?.response) {
          console.log("No Server Response " + err);
        } else {
          alert("Failure beim Hochladen, bitte überprüfe die Datei Datenqualität." + item);
          console.log("upsertConsultants Failed " + err);
        }
      }
    },
    [axiosPrivate],
  );

  const readUploadConsultantsFile = async (e) => {
    e.preventDefault();

    if (!e.target.files) {
      setErrMsg("No file to read");
      return;
    }

    try {
      const file = e.target.files[0];
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(file);
      const worksheet = workbook.worksheets.find((sheet) => sheet.name);

      if (!worksheet) {
        alert("Nichts wurde hochgeladen.");
        return;
      }

      const headerRow = worksheet.getRow(1).values;
      let updated_entries = 0;

      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        if (rowNumber === 1) {
          // Skip header row
          return;
        }

        const rowData = {};

        row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
          const header = headerRow[colNumber];
          let cellData = "";

          if (cell.value === undefined || cell.value === null) {
            cellData = "";
          } else if (typeof cell.value === "object") {
            if (cell.value instanceof Date) {
              // Convert date to string or handle it as you need
              cellData = cell.value.toISOString();
            } else if (cell.value.text !== undefined) {
              cellData = cell.value.text.trim();
            } else {
              // Handle other object types as needed
              cellData = JSON.stringify(cell.value);
            }
          } else if (typeof cell.value === "string") {
            cellData = cell.value.trim();
          } else {
            cellData = cell.value;
          }

          // Check if the data is already UTF-8 encoded
          const isUTF8 = iconv.encodingExists("UTF-8");
          if (isUTF8 && iconv.decode(iconv.encode(cellData, "UTF-8"), "UTF-8") === cellData) {
            rowData[header] = cellData;
          } else {
            // Encode the data to UTF-8
            cellData = iconv.encode(cellData, "UTF-8").toString();
            console.log("converting cell to UTF-8: " + cellData);
            rowData[header] = cellData;
          }
        });
        //console.log('Row data:', rowData);
        if (!rowData[headerRow[1]] && rowData[headerRow[1]] !== 0) {
          //console.log('Error: Row data does not contain an entry for the first cell');
        } else {
          updated_entries = updated_entries + 1;
          upsertConsultants(rowData);
        }
      });

      alert("Datei wurde erfolgreich hochgeladen, " + updated_entries + " Daten sind aktualisiert.");
    } catch (error) {
      setErrMsg("Etwas ist schiefgelaufen, bitte erneut hochladen.");
      console.error(error);
    }
  };

  return (
    <section>
      <form>
        <label htmlFor="upload">
          <h1>Berater Liste Hochladen zum Hinzufügen</h1>
          Excel wie in der Beraterliste steht hochladen. Dabei bitte beachten:
          <ol>
            <li>
              Alle bestehende Berater, die nicht geändert werden soll, müssen nicht wiederholend in der Hochlade Excel stehen. Die
              bestehende Beraterdaten werden unverändert, wenn deren Berater OE nicht in der hochgeladene Excel steht.
            </li>
            <li>Bitte achte drauf, dass die hinzugefügte Berater auch beim Zielberaterliste hinzugefügt wird.</li>
            <li>
              Die Felder Berater OE, Email und Passwort (consultant_id, email, is_supervisor, password) sind Pflichfelder für neue Berater.
              Wenn ein Feld fehlt, wird es nicht hinzugefügt.
            </li>
            <li>
              Es darf keine Quote (Doppelte oder Einfache Anführungszeichen) enthalten sein, weil es in JSON vorreserviert sind. Bitte alle
              Anführungszeichen durch andere Zeichen ersetzen, z.B. durch Unterstrich.
            </li>
            <li>Berater OE (consultant_id) darf keine Duplikate enthalten</li>
            <li>
              Wenn das consultant_id noch nicht exisiert, wird es erneut hinzugefügt. Wenn es schon existiert, wird die andere Werte durch
              hochgeladene Werte ersetzt. Passwort muss in dem Fall nicht immer befüllt sein.
            </li>
            <li>
              Email soll aber gültige Email sein (z.B. keine Umlaute und Sondernzeilen), weil ansonsten der Einloggen und Automatische
              Versand nicht mehr funktionieren können. Dabei muss Groß und Klein schreiben nicht geachtet werden. Beim Hochladen werden alle
              zum kleinschreiben automatisch umgewandet.
            </li>
          </ol>
        </label>
        <br></br>
        <br></br>
        <input type="file" name="upload" id="uploadCredentials" onChange={readUploadConsultantsFile} />
      </form>
      <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
        {errMsg}
      </p>
    </section>
  );
}
