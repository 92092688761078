import React, { useCallback, useState } from "react";
import { AppBar, IconButton, Toolbar, useTheme } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Box } from "@mui/system";
import Drawer from "./Drawer";
import User from "./User";
import useAuth from "../auths/hooks/useAuth";
import zebLogo from "../../assets/zeb.png";

export default function NavBar() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const onClickNavigationMenu = useCallback(() => setOpen(!open), [open]);
  const onCloseNavigationMenu = useCallback(() => setOpen(false), []);

  const { auth } = useAuth();

  return (
    <>
      <AppBar
        position="sticky"
        open={open}
        sx={{
          zIndex: theme.zIndex.drawer + 1,
        }}
      >
        <Box px={{ sm: 3, lg: 4, xl: 8 }}>
          <Toolbar variant="dense" disableGutters>
            {auth && (
              <IconButton color="inherit" edge="start" onClick={onClickNavigationMenu}>
                <MenuIcon />
              </IconButton>
            )}

            <Box flex="1" justifyContent="center" display="flex" alignItems="center">
              <img src={zebLogo} alt="logo" height="50px" />
            </Box>
            <User />
          </Toolbar>
        </Box>
      </AppBar>
      {auth && <Drawer open={open} onClose={onCloseNavigationMenu} first_login={auth.firstLogin} />}
    </>
  );
}
