import React from "react";
import { Grid, Typography } from "@mui/material";

const Help = () => {
  return (
    <div style={{ width: "100%", height: "85vh", display: "flex", justifyContent: "center", backgroundColor: "white" }}>
      <div style={{ textAlign: "center", marginTop: "10vh" }}>
        <Typography variant="h4" gutterBottom mb={10}>
          Support
        </Typography>
        <Grid container direction="row" justifyContent="center">
          <Grid item>
            <p>Bitte wenden Sie sich für Supportanfragen an: <a href="mailto:Ueberleitungstool@zeb.de">Ueberleitungstool@zeb.de</a></p>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Help;
