import React, { useCallback, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { LicenseManager } from "ag-grid-enterprise";
import useAxiosPrivate from "../auths/hooks/useAxiosPrivate";
// import { grey } from '@mui/material/colors';
import CLIENT_LIST from "../../utilities/client_list";
import CLIENT_TYP from "../../utilities/client_type_list";
// Import minimal modules required for charts

LicenseManager.setLicenseKey(process.env.REACT_APP_AGGRID_LICENCE);

const percentFormatter = (params) => {
  const value = params.value;
  if (isNaN(value)) {
    return "0%";
  }
  return (value * 100).toFixed(2).toString() + "%";
};
// const renderBoolean = (p) => {
//   return p.value ? 'Ja' : 'Nein';
// };

const ProgressAbgabe = () => {
  const axiosPrivate = useAxiosPrivate();
  const gridRef = useRef();
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState();
  const getRowId = (params) => params.data._id;

  const [columnDefs] = useState([
    //setColumnDefs
    {
      field: "_id",
      headerName: process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK ? "Berater Nr." : "Berater OE",
    },
    {
      field: "lastname",
      headerName: "Nachname",
    },
    {
      field: "firstname",
      headerName: "Vorname",
    },
    {
      field: "einheit",
      headerName: "Einheit",
      editable: true,
    },
    {
      field: "standort",
      headerName: "Standort",
      editable: true,
      hide: process.env.REACT_APP_CLIENT !== CLIENT_LIST.SKSP,
    },
    {
      field: "Gesamt",
      headerName: "Gesamt",
      filter: "agNumberColumnFilter",
    },
    {
      field: "Beibehalten",
      headerName: "Veto Abgabe",
      filter: "agNumberColumnFilter",
    },
    {
      headerName: "Veto Quote",
      valueGetter: (params) => {
        const veto = params.getValue("Beibehalten");
        const gesamt = params.getValue("Gesamt");
        return gesamt == 0 ? 0.0 : veto / gesamt;
      },
      valueFormatter: percentFormatter,
      filter: "agNumberColumnFilter",
      hide: false,
    },
    {
      field: "Bearbeitet",
      headerName: "Bearbeitet",
      hide: true,
    },
    {
      field: "Abgabe",
      headerName: "Abgabe",
      filter: "agNumberColumnFilter",
      hide: true,
    },

    /*     {
      field: 'Abgegeben',
      headerName: 'Abgegeben',
      filter: 'agNumberColumnFilter',
    }, */
    // {
    //   field: 'Fertiggestellt',
    //   headerName: 'Fertig gestellt ',
    //   cellRenderer: renderBoolean,
    //   filter: 'agNumberColumnFilter',
    // },
  ]);
  const defaultColDef = useMemo(() => {
    return {
      //flex: 1,
      filter: true,
      sortable: true,
      floatingFilter: true,
      resizable: true,
      initialWidth: 130,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      // backgroundColor: grey,
    };
  }, []);

  const readData = useCallback(async () => {
    return await axiosPrivate
      .get("consultants/abgabeProgress")
      .then((res) => res.data)
      .then((data) => {
        setRowData(data);
      });
  }, [axiosPrivate]);

  const onGridReady = useCallback(
    (params) => {
      readData();
      gridRef.current.api = params.api;
    },
    [readData],
  );
  const onFirstDataRendered = useCallback(() => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);
  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agTotalAndFilteredRowCountComponent", align: "left" },
        { statusPanel: "agTotalRowCountComponent", align: "center" },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    };
  }, []);

  return (
    <div style={containerStyle}>
      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <div style={{ flexGrow: "1", height: "calc(100vh - 150px" }}>
          {/* height: 100% is not enough as AGGrid don't know the wrapper's height */}
          <div id="myProgressGrid" className="ag-theme-alpine" style={gridStyle}>
            <AgGridReact
              enableCharts={true}
              enableRangeSelection={true}
              ref={gridRef}
              rowData={rowData}
              getRowId={getRowId}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              onFirstDataRendered={onFirstDataRendered}
              animateRows={true}
              statusBar={statusBar}
              onGri
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressAbgabe;
