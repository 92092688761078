import ExcelJS from "exceljs";
import { useCallback, useRef, useState, React } from "react";
import useAxiosPrivate from "../auths/hooks/useAxiosPrivate";

export default function UploadCompetence() {
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState("");
  const axiosPrivate = useAxiosPrivate();

  const overwriteCompetence = useCallback(
    async (competence_list, length) => {
      alert("overwrite Competence for: " + length + " competence entries");
      try {
        await axiosPrivate.post("/consultants/competences", { competence_list });
      } catch (err) {
        if (!err?.response) {
          console.log("No Server Response" + err);
        } else {
          console.log("overwrite Competence Failed" + err);
        }
      }
    },
    [axiosPrivate],
  );

  const readUploadFile = async (e) => {
    e.preventDefault();

    if (!e.target.files) {
      setErrMsg("No file to read");
      return;
    }

    try {
      const file = e.target.files[0];
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(file);
      const worksheet = workbook.worksheets.find((sheet) => sheet.name);

      if (!worksheet) {
        alert("Nichts wurde hochgeladen.");
        return;
      }

      const headerRow = worksheet.getRow(1).values;
      const json = [];

      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        if (rowNumber === 1) {
          // Skip header row
          return;
        }

        const rowData = {};
        row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
          const header = headerRow[colNumber];
          if (cell.value === undefined || cell.value === null) {
            rowData[header] = "";
          } else if (typeof cell.value === "object") {
            if (cell.value instanceof Date) {
              // Convert date to string or handle it as you need
              rowData[header] = cell.value.toISOString();
            } else if (cell.value.text !== undefined) {
              rowData[header] = cell.value.text.trim();
            } else {
              // Handle other object types as needed
              rowData[header] = JSON.stringify(cell.value);
            }
          } else if (typeof cell.value === "string") {
            rowData[header] = cell.value.trim();
          } else {
            rowData[header] = cell.value;
          }
        });

        if (!rowData[headerRow[1]] && rowData[headerRow[1]] !== 0) {
          //console.log('Error: Row data does not contain an entry for the first cell');
        } else {
          json.push(rowData);
        }
      });

      // axios call
      const jsonString = JSON.stringify(json);
      //console.log(jsonString);
      const length = json.length;
      overwriteCompetence(jsonString, length);
      alert("Datei wurde erfolgreich hochgeladen.");
    } catch (error) {
      setErrMsg("Etwas ist schiefgelaufen, bitte erneut hochladen.");
      console.error(error);
    }
  };

  return (
    <section>
      <form>
        <label htmlFor="uploadCompetence">
          <h1>Kompetenzliste Hochladen/Überschreiben</h1>
        </label>
        <br></br>
        <br></br>
        <p> Kompetenzliste hochladen, um die aktuelle Kompetenzliste komplett zu überschreiben. Bitte beachten: </p>
        <ol>
          <li>
            Consultant_id ist der Login Berater OE und Authorised_id ist die Berater, dessen Kunden der aktuelle Nutzer auch mitbearbeiten
            darf. Ein typischer Fall ist, dass ein Führungskraft Berater OE 9991200 sowohl sich selbst als auch andere Berater OEs Kunden
            z.B. 9991100 betreuen darf. In dem Fall soll zwei Zeilen exisitieren, eine mit 9991200 zu 9991200, eine andere mit 9991200 zu
            9991100.
          </li>
          <li>Duplikate (beide Zeile enthält gleiche Wert, sowohl Berater OE als auch Authorised_id) werden ignoriert</li>
          <li>Keine Leerzeile in Excel darf zwischen den Zeilen existieren. </li>
          <li>
            Da alle Daten überschrieben werden, müssen jedesmal die TestIDs in dieser Liste enthalten, damit Admin (9999999) sowie Test
            Nutzer (999xx00) weiterhin einloggen kann!!
          </li>
        </ol>
        <input type="file" name="uploadCompetence" id="uploadCompetence" onChange={readUploadFile} />
      </form>
      <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
        {errMsg}
      </p>
    </section>
  );
}
