import React, { useCallback, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import useAxiosPrivate from "../auths/hooks/useAxiosPrivate";
//import { grey } from '@mui/material/colors';
import { useNavigate } from "react-router-dom";
//import useAuth from '../auths/hooks/useAuth';

const BundleListe = () => {
  // const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const gridRef = useRef();
  const navigate = useNavigate();
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState();
  const getRowId = (params) => params.data.verbund_person_nr;

  const LOGIN_URL = "/login";

  const [columnDefs] = useState([
    //setColumnDefs
    {
      field: "verbund_person_nr",
      initialWidth: 150,
      columnGroupShow: "close",
      pinned: "left",
      editable: false,
    },
    {
      field: "consultant_id",
      columnGroupShow: "close",
      pinned: "left",
    },
    {
      field: "specialist_consultant_id",
      columnGroupShow: "close",
      pinned: "left",
    },
    {
      field: "consultant_typ",
      columnGroupShow: "open",
      pinned: "left",
    },
    {
      field: "lastname",
      initialWidth: 180,
      pinned: "left",
    },
    {
      headerName: "Details",
      children: [
        {
          field: "firstname",
          initialWidth: 180,
          columnGroupShow: "close",
        },
        { field: "street", columnGroupShow: "open" },
        //{ field: 'house_nr', columnGroupShow: 'open' },
        { field: "city", columnGroupShow: "open" },
        {
          field: "post_nr",
          columnGroupShow: "open",
          chartDataType: "category",
        },
        {
          field: "right_form",
        },
        // {
        //   field: 'verbund_client_age',
        //   columnGroupShow: 'open',
        //   filter: 'agNumberColumnFilter',
        // },
        {
          field: "verbund_ek_bew",
          columnGroupShow: "open",
          filter: "agNumberColumnFilter",
        },
        {
          field: "verbund_haspa_spar",
          columnGroupShow: "open",
          filter: "agNumberColumnFilter",
        },
        {
          field: "verbund_asset",
          columnGroupShow: "open",
          filter: "agNumberColumnFilter",
        },
        {
          field: "verbund_asset_wp",
          columnGroupShow: "open",
          filter: "agNumberColumnFilter",
        },
        {
          field: "verbund_fin_vol",
          columnGroupShow: "open",
          filter: "agNumberColumnFilter",
        },
        {
          field: "verbund_com_turnover",
          columnGroupShow: "open",
          filter: "agNumberColumnFilter",
        },
        // {
        //   field: 'verbund_client_type_old',
        //   columnGroupShow: 'open',
        // },
        {
          field: "double_person_nr",
          initialWidth: 230,
          columnGroupShow: "close",
        },
        {
          field: "segment_old",
          initialWidth: 230,
          columnGroupShow: "close",
        },
        {
          field: "segment_new",
          initialWidth: 230,
          columnGroupShow: "close",
        },
        {
          field: "segment_target",
          initialWidth: 230,
          columnGroupShow: "close",
        },
        {
          field: "grob_segment_old",
          initialWidth: 230,
          columnGroupShow: "close",
        },
        {
          field: "grob_segment_new",
          initialWidth: 230,
          columnGroupShow: "open",
        },
        {
          field: "grob_segment_target",
          initialWidth: 230,
          columnGroupShow: "close",
        },
        {
          field: "fein_segment",
          initialWidth: 230,
          columnGroupShow: "close",
        },
        {
          field: "filial_nr_new",
          columnGroupShow: "open",
          initialWidth: 150,
        },
        // {
        //   field: 'filial_typ_new',
        //   columnGroupShow: 'close',
        // },
      ],
    },
    {
      headerName: "Zuordnung",
      children: [
        {
          field: "consultant_id_new",
          columnGroupShow: "close",
          initialWidth: 150,
        },
        {
          field: "consultant_typ_new",
        },
        {
          field: "consultant_typ_target",
        },
        {
          field: "consultant_id_target",
          columnGroupShow: "close",
        },
        {
          field: "coresp_typ",
          filter: "agSetColumnFilter",
          initialWidth: 200,
          columnGroupShow: "open",
        },
        {
          field: "note",
        },
        {
          field: "reason_abgabe",
        },

        {
          field: "capacity",
          columnGroupShow: "open",
          filter: "agNumberColumnFilter",
        },
        {
          field: "is_pure_segment",
          initialWidth: 80,
          columnGroupShow: "close",
          editable: false,
        },
        {
          field: "is_locked",
          columnGroupShow: "open",
          editable: false,
        },
        {
          field: "last_modified",
          initialWidth: 150,
          editable: false,
        },
        {
          field: "mod_consultant_id",
          initialWidth: 150,
          editable: false,
        },
      ],
    },
    {
      field: "client_status",
      headerName: "Status",
      pinned: "right",
      width: 130,
    },
  ]);
  const defaultColDef = useMemo(() => {
    return {
      //flex: 1,
      filter: true,
      sortable: true,
      floatingFilter: true,
      resizable: true,
      minWidth: 150,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      editable: true,
      //suppressColumnVirtualisation: true,
      // other grid options...
    };
  }, []);
  const submitChange = useCallback(
    async (verbund_person_nr, field_name, field_value) => {
      //console.log('update client bundle data for: ', item);
      console.log("update client bundle data for " + verbund_person_nr);

      const item = {
        verbund_person_nr: verbund_person_nr,
        field_name: field_name,
        field_value: field_value,
      };
      console.log("item: " + JSON.stringify(item));
      try {
        await axiosPrivate.post("/client_bundles/updateSingleField", item);
      } catch (err) {
        if (!err?.response) {
          console.log("No Server Response" + err);
        } else {
          console.log("SubmitChange Failed");
        }
      }
    },
    [axiosPrivate],
  );
  const onCellEditRequest = useCallback(
    (event) => {
      const oldData = event.data;
      const field = event.colDef.field;
      const newData = { ...oldData };
      newData[field] = event.newValue;
      console.log("onCellEditRequest, updating " + field + " to " + event.newValue);
      const tx = {
        update: [newData],
      };
      event.api.applyTransaction(tx);
      //console.log({ newData });
      const verbund_person_nr = event.node.id;
      console.log("verbund_person_nr: " + getRowId);
      //Axios call
      submitChange(verbund_person_nr, field, event.newValue);
    },
    [submitChange],
  );
  const readData = useCallback(async () => {
    try {
      await axiosPrivate
        .get("client_bundles")
        .then((res) => res.data)
        .then((data) => {
          let filteredData = data.foundClient_bundles;

          setRowData(filteredData);
          //setRowData(data);
          //console.log({ data });
        });
    } catch (err) {
      if (!err?.response) {
        console.log("No Server Response" + err);
      } else {
        console.log("readData Failed" + err.response.toString);
      }
      navigate(LOGIN_URL);
    }
  }, [axiosPrivate]);

  const updateColumnsize = () => {
    console.log(gridRef.current.api);
    gridRef.current.api.sizeColumnsToFit();
    //   const allColumnIds = [];
    //   gridRef.current.api.getAllColumns().forEach(function (column) {
    //     allColumnIds.push(column.colId);
    //   });
    //   gridRef.current.api.autoSizeColumns(allColumnIds, true);
  };

  const onGridReady = useCallback(
    (params) => {
      readData();
      gridRef.current.api = params.api;
      updateColumnsize();
    },
    [readData],
  );
  const onFirstDataRendered = useCallback(() => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);
  // const onBtPrint = useCallback(() => {
  //   console.log('print');
  //   setTimeout(function () {
  //     print();
  //     //setNormal(api);
  //   }, 2000);
  // }, []);
  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agTotalAndFilteredRowCountComponent", align: "left" },
        { statusPanel: "agTotalRowCountComponent", align: "center" },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    };
  }, []);
  return (
    <div style={containerStyle}>
      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <div style={{ flexGrow: "1", height: "calc(100vh - 150px" }}>
          {/* height: 100% is not enough as AGGrid don't know the wrapper's height */}
          <div id="myStatisticGrid" className="ag-theme-alpine" style={gridStyle}>
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              getRowId={getRowId}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              onFirstDataRendered={onFirstDataRendered}
              readOnlyEdit={true}
              //enableCellChangeFlash={true}
              onCellEditRequest={onCellEditRequest}
              animateRows={true}
              statusBar={statusBar}
              onGri
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BundleListe;
