import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../auths/hooks/useAxiosPrivate";
import useAuth from "../auths/hooks/useAuth";
import { Button, Grid } from "@mui/material";
import CLIENT_ROLES_LIST from "../../utilities/client_role_list";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import CLIENT_LIST from "../../utilities/client_list";
import ROLES_LIST from "../auths/roles_list";

const greenColor = "#006400";

const LOGIN_URL = "/login";
const LEADER_ROLE = "Verbundführer";
const KUNDEN_ROLLEN = ["Verbundführer", "Verbundmitglied", "Einzelkunde"];
const BRANCH_LIST = [
  "Abbrucharbeiten",
  "Abfüllen und Verpacken",
  "Agenturtankstelle in fremden Namen *****",
  "Allg.bildende weiterführ. Schule Sek I *",
  "Allg.bildende weiterführ. Schule Sek II*",
  "Allgemeine Fachhochschulen",
  "Allgemeine Gebäudereinigung",
  "Allgemeine politische Erwachsenenbildung",
  "Allgemeine Sekretariats-/Schreibdienste",
  "Altenheime Alten-/Behindertenwohnheime",
  "Ambulante soziale Dienste",
  "Anbau Getreide (ohne Reis) Hülsenfrucht*",
  "Anbau Kartoffeln, sons. Wurzeln & Knolle",
  "Anbau sonst. Obst Nüsse (ohne Erdbeeren)",
  "Anbau sonstiger mehrjähriger Pflanzen",
  "Anbau von Erdbeeren",
  "Anbau von Gemüse und Melonen",
  "Anbau von Kern- und Steinobst",
  "Anbau von Reis",
  "Anbau von sonst. einjährigen Pflanzen",
  "Anbau von Wein- und Tafeltrauben",
  "Anbau Zimmerpflanze Beet-/Balkonpflanzen",
  "Anbringen Stuckatur Gipserei Verputzerei",
  "andere wirtsch. Dienstleist.f. Unternehm",
  "Anerkannte Wertpapierhandelsunternehmen",
  "Anlageberatung",
  "Antiquariate",
  "Apotheken",
  "Arbeitgeberverband",
  "Architekturbüro Garten-/Landschaftsgest.",
  "Architekturbüro Orts-/Region-/Landesplan",
  "Architekturbüros für Hochbau",
  "Arztpraxen für Allgemeinmedizin",
  "Augenoptiker",
  "Auskunfteien",
  "Ausländische Finanzgesellschaften",
  "Autowaschanlagen",
  "Bars",
  "Bau von Gebäuden (ohne Fertigteilbau)",
  "Bau von Straßen",
  "Baugewerbe a.n.g.",
  "Bautischlerei und -schlosserei",
  "Bauträger für Nichtwohngebäude",
  "Bauträger für Wohngebäude1",
  "Be-/Verarb. Naturwerksteine Natursteine",
  "Befristete Überlassung v. Arbeitskräften",
  "Behandl. & Beseitigung gefährl. Abfälle",
  "Behandl. Beseitigung n.gefährl. Abfälle",
  "Bergbau auf Uran- und Thoriumerze",
  "Berufliche Erwachsenenbildung",
  "Berufs-/Fachakademie Schule Gesundheitsw",
  "Berufsbildende weiterführende Schule",
  "Berufsorganisationen",
  "Beseitigung Umweltschmutz sonst.Entsorg.",
  "Bestattungsinstitute",
  "Betrieb Bahnhof Personenverkehr Omnibus",
  "Betrieb Datenverarbeitung-E. für Dritte",
  "Betrieb der Sammelkanalisation",
  "Betrieb Flughafen & Landeplatz Luftfahr*",
  "Betrieb historischer Stätte Gebäude & a*",
  "Betrieb Verkehrswege f. Straßenfahrzeuge",
  "Betrieb von Baumschule",
  "Betrieb von Brütereien",
  "Betrieb von Häfen",
  "Betrieb von Kläranlagen",
  "Betrieb von Parkhaus und Parkplatz",
  "Betrieb von Sportanlagen",
  "Betrieb von Taxis",
  "Betrieb von Wasserstraßen",
  "Bibliotheken und Archive",
  "Binden Druckerzeugnisse & verb. Dienstl.",
  "Botanische/zoologische Gärten/Naturparks",
  "Braunkohlenbergbau",
  "Brücken- und Tunnelbau",
  "Buchführung (o.Datenverarbeitungsdienst)",
  "Bundesanstalt Landwirtschaft/Ernährung",
  "Bürgschaftsbanken (Kreditgarantiegem.)",
  "Büros für Innenarchitektur",
  "Cafés",
  "Campingplätze",
  "Copy-Shops, spez. Sekretariatsdienst, **",
  "Dachdeckerei und Bauspenglerei",
  "Dämmung gg. Kälte Wärme Schall Erschütt.",
  "Datenverarbeitung Hosting & verb.Tätigk.",
  "Desinfektion und Schädlingsbekämpfung",
  "Detekteien",
  "Deutsche Bahn AG",
  "Deutsche Post AG",
  "Deutsche Telekom AG",
  "Dienstl. f. Gewinnung von Erdöl & Erdgas",
  "Dienstl.f. Forstwirtschaft Holzeinschlag",
  "Dienstleist. sons. Bergbau Steine Erden",
  "Dienstleistung f. die darstellende Kunst",
  "Dienstleistung für Unterhaltung Erholung",
  "Dienstleistungen für den Unterricht",
  "Diskotheken und Tanzlokale",
  "Dolmetschen",
  "Drahtlose Telekommunikation",
  "Druck- und Medienvorstufe",
  "Drucken a.n.g.",
  "Drucken von Zeitungen",
  "Eigene Vermögensverw.Privatiers/Rentiers",
  "Eigene Vermögensverwaltung Unternehmen",
  "Ein-Objekt-Leasinggesellschaften",
  "Einzelh. Antiquität & antike Teppiche",
  "Einzelh. Blume Pflanze Sämerei Düngemit*",
  "Einzelh. Datenverarbeitung Software Per*",
  "Einzelh. elektrische Haushaltsgeräte",
  "Einzelh. Fahrrad Fahrradteile/-zubehör",
  "Einzelh. Fisch Meeresfrucht Fischerzeug*",
  "Einzelh. Foto-/optische Erzeugnisse ****",
  "Einzelh. Geräte Unterhaltungselektronik",
  "Einzelh. keramischer Erzeugn. Glaswaren",
  "Einzelh. Kosmetik Körperpflegemittel",
  "Einzelh. Kunstgegenstand Bild Kunstgew.*",
  "Einzelh. medizinische orthopädische Art*",
  "Einzelh. Metall-/Kunststoffwaren a.n.g.",
  "Einzelh. mit Kraftwagenteilen/-zubehör",
  "Einzelh. mit Lederwaren und Reisegepäck",
  "Einzelh. mit Obst Gemüse Kartoffeln",
  "Einzelh. mit Zeitschriften & Zeitungen",
  "Einzelh. Musikinstrumente & Musikalien",
  "Einzelh. Nahrung/Getränk/Tabak a. MARKT",
  "Einzelh. Nahrung/Getränk/Tabak o.Schwerp",
  "Einzelh. Schreib-/Papierwaren, Büroart.*",
  "Einzelh. sons. Güter Verkaufsstand Markt",
  "Einzelh. Sport Camping (o. Campingmöbel)",
  "Einzelh. Teppich Tapete Fußbodenbeläge *",
  "Einzelh. Textil Bekleidung Schuhe Stand",
  "Einzelh. verschiedener Waren (o. Nahr.M)",
  "Einzelh. Waren versch.Art (NichtNahrung)",
  "Einzelh. zoologisch. Bedarf/leb. Tieren",
  "Einzelhandel Anstrich Bau- & Heimwerker",
  "Einzelhandel bespielter Ton- Bildträger",
  "Einzelhandel Haushaltsgegenstände a.n.g.",
  "Einzelhandel mit Back- und Süßwaren",
  "Einzelhandel mit Bekleidung",
  "Einzelhandel mit Büchern",
  "Einzelhandel mit Fleisch & Fleischwaren",
  "Einzelhandel mit Getränken",
  "Einzelhandel mit Schuhen",
  "Einzelhandel mit sonst. Gebrauchtwaren",
  "Einzelhandel mit Spielwaren",
  "Einzelhandel mit Tabakwaren",
  "Einzelhandel mit Textilien",
  "Einzelhandel mit Uhren und Schmuck",
  "Einzelhandel mit Wohnmöbeln",
  "Einzelhandel Telekommunikationsgeräten",
  "Einzelhandel vom Lager mit Brennstoffen",
  "Eissalons",
  "Elek.erz. EE mit Fremdbezug z.Verteilung",
  "Elek.erz. EE ohne Verteilung",
  "Elek.erz.EE ohne Fremdbezug z.Verteilung",
  "Elek.handel - rein Erneuerbare Energien",
  "Elek.übertragung rein Erneuerb. Energien",
  "Elek.verteilung rein Erneuerb. Energien",
  "Elektrizitätserzeugung Fremdbezug Vert.*",
  "Elektrizitätserzeugung o.Fremdbezug z.V*",
  "Elektrizitätserzeugung ohne Verteilung",
  "Elektrizitätshandel",
  "Elektrizitätsübertragung",
  "Elektrizitätsverteilung",
  "Elektroinstallation",
  "Entwicklung Programmierung Internet-Prä.",
  "Erbr. v. Dienstleist. f.d. Verkehr ang*",
  "Erbring. sonst. Informationsdienstleist.",
  "Erbringung Beratung Informationstechn. *",
  "Erbringung Dienstl. Informationstechnol.",
  "Erbringung sonst. Dienstl. Landverkehr *",
  "Erbringung sonst. Dienstl. Luftfahrt ***",
  "Erbringung sonst. Dienstl. Schifffahrt *",
  "Erbringung sonst. Dienstleistung Sport *",
  "Erbringung sonst. gärtnerischer Dienstl.",
  "Erbringung sonst. juristische Dienstlst.",
  "Erbringung sonst. Reservierungsdienstl.",
  "Erbringung sonst. Verpflegungsdienstl.",
  "Erbringung sonstiger Dienstleistungen **",
  "Erholungs- und Ferienheime",
  "Erneuerb. Gaserz./Biogas ohne Verteilung",
  "Errichtung von Fertigteilbauten",
  "Erschließung von unbebauten Grundstücken",
  "Erzeug. erste Bearbeit. Blei/Zink/Zinn *",
  "Erzeugung 1.Bearbeitung sons. NE-Metalle",
  "Erzeugung erste Bearbeitung Edelmetalle",
  "Erzeugung Roheisen Stahl Ferrolegierung",
  "Erzeugung und erste Bearbeitung Kupfer",
  "Event-Caterer",
  "Facharztpraxen",
  "Factoringgesellschaften (keine MFIs)",
  "Fahr- und Flugschulen",
  "Ferienhäuser und Ferienwohnungen",
  "Ferienzentren",
  "Fernsehveranstalter",
  "Filmverleih/-vertrieb (o. Videotheken)",
  "Finanzhandelsinstitute gem. § 25f KWG",
  "Finanzierungsinst. (o.MFIs), S-Finanz",
  "Fischverarbeitung",
  "Fitnesszentren",
  "Fleischverarbeitung",
  "Förderinstitute der Landesbanken",
  "Forschung Entwicklung Biotechnologie",
  "Forschung Rechts-/Wirtschafts-/Sozialw.*",
  "Forstwirtschaft",
  "Fotografie",
  "Fotolabors",
  "Frachtumschlag",
  "Freie Tankstelle in eigenem Namen ******",
  "Friedhöfe und Krematorien",
  "Frisörsalons",
  "Fußboden-/Fliesenlegerei, Tapeziererei *",
  "Garten- und Landschaftsbau",
  "Gas/Wasser/Heizungs/Lüftungs-Klimainst.*",
  "Gaserzeugung mit Fremdbezug z.Verteilung",
  "Gaserzeugung o. Fremdbezug z. Verteilung",
  "Gaserzeugung ohne Verteilung",
  "Gashandel durch Rohrleitungen",
  "Gasthöfe",
  "Gasverteilung durch Rohrleitungen",
  "Gemischte Landwirtschaft",
  "Gemischte Öko-Landwirtschaft",
  "Gerüstbau",
  "Geschl.ImmoFonds gewerbl.genutz.Objekte",
  "Geschl.ImmoFonds wohnwirt.genutz.Objekte",
  "Gewerkschaften",
  "Gewerkschaften NMP",
  "Gewinnung Natur(werk)stein Kalk Gips",
  "Gewinnung von Erdöl",
  "Gewinnung von Kies, Sand, Ton und Kaolin",
  "Gewinnung von Steinen und Erden a.n.g.",
  "Glasergewerbe",
  "Grafik- und Kommunikationsdesign",
  "Großh. Baustoffe mineralische Bauelement",
  "Großh. Bergwerks-/Bau-/Baustoffmaschinen",
  "Großh. Datenverarbeitung PeripherieSoft",
  "Großh. elektronische Bauteile Telekomm.*",
  "Großh. Erze, Eisen, Stahl, Halbzeuge",
  "Großh. Fahrrad Sport Camping (o. Möbel)",
  "Großh. Fertigwaren, ohne a. Schwerpunkt",
  "Großh. Flurförderzeuge & Fahrzeuge a.n.g",
  "Großh. Geräte d. Unterhaltungselektronik",
  "Großh. Getreide/Rohtabak/Saatgut/Futterm",
  "Großh. Holz Baustoffe Sanitär Anstrichm.",
  "Großh. Install.bedarf Gas Wasser Heizung",
  "Großh. Karton Papier Pappe Schreibe Büro",
  "Großh. keramischer Erzeugn. & Glaswaren",
  "Großh. kosmetischer Erzeugn. & Pflegem.",
  "Großh. Kraftwagenteilen & -zubehör",
  "Großh. Lederwaren Reisegepäck Werbeart.*",
  "Großh. m. Kaffee, Tee, Kakao & Gewürzen",
  "Großh. m. Spielwaren & Musikinstrumente",
  "Großh. medizinisch orthopädisch dental A",
  "Großh. Metall-/Kunststoffwaren Bauzwecke",
  "Großh. Milch Milcherzeugnisse Eier Öl",
  "Großh. mit elektrischen Haushaltsgeräten",
  "Großh. mit pharmazeutischen Erzeugnissen",
  "Großh. mit Werkzeugen & Kleineisenwaren",
  "Großh. mit Zucker, Süßwaren & Backwaren",
  "Großh. Möbel, Teppich, Lampen, Leuchten",
  "Großh. NE-Erze, NE-Metalle, NE-Halbzeug",
  "Großh. nicht-elektr. Haushaltsgeräte ***",
  "Großh. Rohstoffe Halb-/Fertigware, o.Sc*",
  "Großh. sonst. Ausrüstung & techn.Bedarf*",
  "Großh. sonst. Büromaschine/-einrichtung*",
  "Großh. Sonst. Holzhalbwaren Bauelemente",
  "Großh. sonstige Nahrung Genuss Getränke*",
  "Großh. Tapete Bodenbelag (ohne Teppich)",
  "Großh. Textil-/Näh-/Strickmaschinen",
  "Großh. tiefgefrorener Nahrungsm. o.Schw*",
  "Großhandel Altmaterialien & Reststoffen",
  "Großhandel Foto- & optische Erzeugnisse",
  "Großhandel landwirtschaftliche Maschinen",
  "Großhandel mit Anstrichmitteln",
  "Großhandel mit Bekleidung",
  "Großhandel mit Blumen und Pflanzen",
  "Großhandel mit Büromöbeln",
  "Großhandel mit chemischen Erzeugnissen",
  "Großhandel mit festen Brennstoffen",
  "Großhandel mit Fisch Fischerzeugnisse",
  "Großhandel mit Flachglas",
  "Großhandel mit Fleisch und Fleischwaren",
  "Großhandel mit Getränken",
  "Großhandel mit lebenden Tieren",
  "Großhandel mit Mehl & Getreideprodukten",
  "Großhandel mit Mineralölerzeugnissen",
  "Großhandel mit Nahrungs-/Genussmittel **",
  "Großhandel mit Obst Gemüse Kartoffeln",
  "Großhandel mit Roh- und Schnittholz",
  "Großhandel mit Rohstoffen & Halbwaren",
  "Großhandel mit Sanitärkeramik",
  "Großhandel mit Schuhen",
  "Großhandel mit sonstigen Halbwaren",
  "Großhandel mit sonstigen Maschinen",
  "Großhandel mit Tabakwaren",
  "Großhandel mit Textilien",
  "Großhandel mit Uhren und Schmuck",
  "Großhandel mit Werkzeugmaschinen",
  "Großhandel Wasch-/Putz-/Reinigungsmittel",
  "Grundschulen",
  "Güterabfertigung Schienen-/Straßenfahrz.",
  "Güterbeförderung Eisenbahnverkehr (o.DB)",
  "Güterbeförderung im Straßenverkehr",
  "Güterbeförderung in d. Binnenschifffahrt",
  "Güterbeförderung See- Küstenschifffahrt",
  "Haltung Legehennen Konsumeiererzeugung",
  "Haltung von anderen Rindern",
  "Haltung von Milchkühen",
  "Haltung von Pferden und Eseln",
  "Haltung von Schafen und Ziegen",
  "Haltung von Schweinen",
  "Handel Krafträder/-zubehör, Instandhalt.",
  "Handel Kraftwagen Gesamtgewicht > 3,5t",
  "Handel m. Kraftwagen Gesamtgewicht <3,5t",
  "Handelsv. kosmetischer Erz. Körperpflege",
  "Handelsverm. Baustoffe u. Anstrichmittel",
  "Handelsverm. Brennstoffe Erze Metall   *",
  "Handelsverm. Bücher Zeitungen Musikalie",
  "Handelsverm. Büromaschine DV-Geräte/Per*",
  "Handelsverm. Eisen-/Metall-/Kunststoff *",
  "Handelsverm. Elektrotechnik Elektronik",
  "Handelsverm. Heim- Haustextil Bodenbelag",
  "Handelsverm. Installationsbedarf für GWH",
  "Handelsverm. Labor-/medizin./Pflegebed.*",
  "Handelsverm. landwirtschaftl. Maschinen",
  "Handelsverm. landwirtschaftl.Erz. Tiere*",
  "Handelsverm. Maschinen (o. landwirt.Ma*)",
  "Handelsverm. Meterware Bekleidung Wäsche",
  "Handelsverm. Möbel Einrichtung Antiquit.",
  "Handelsverm. Papier Pappe Schreibwaren *",
  "Handelsverm. Putz- und Reinigungsmittel",
  "Handelsverm. Rohholz Holzhalbwaren BauE*",
  "Handelsverm. Schuhe Leder Reisegepäck",
  "Handelsverm. sons. Nahrung Getränk Tabak",
  "Handelsverm. Spielwaren Musikinstrumente",
  "Handelsverm. Uhr Edelmetallwaren Schmuck",
  "Handelsverm. Ware o.ausgepr. Schwerpunkt",
  "Handelsvermittl. elektr. Haushaltsgeräte",
  "Handelsvermittl. Feinmech., Foto, Optik",
  "Handelsvermittl. keramischer Erzeugnisse",
  "Handelsvermittl. Unterhaltungselektronik",
  "Handelsvermittl. Wein Sekt Spirituosen",
  "Handelsvermittlung Bekleidungszubehör",
  "Handelsvermittlung sonstiger Waren a.n.g",
  "Handelsvermittlung von Bekleidung",
  "Handelsvermittlung von Werkzeugen",
  "Handelsvermittlung von Zucker Süßwaren",
  "Hausmeisterdienste",
  "Heilpraktikerpraxen",
  "Herst. & Runderneuerung Bereifung Reifen",
  "Herst. Anstrichmittel Druckfarben Kitten",
  "Herst. auswechselb.Werkzeug Metallbearb.",
  "Herst. Baubedarfsartikel aus Kunststoff",
  "Herst. Dampfkessel (ohne Zentralheizung)",
  "Herst. Datenverarbeitungs-/periph. Gerät",
  "Herst. elektrisch. elektron. Ausr. KfZ",
  "Herst. elektrischer Lampen & Leuchten",
  "Herst. elektrischer Mess-/Kontroll-/Nav*",
  "Herst. Elektrizitäts(...)einrichtungen",
  "Herst. Elektromotor Generator Transform*",
  "Herst. Erfrischungsgetränk Mineralwasser",
  "Herst. Erzeugn. Beton Zement Kalksandst*",
  "Herst. Fahrrad und Behindertenfahrzeugen",
  "Herst. Filme Videofilme Fernsehprogramme",
  "Herst. Futtermittel für sonstige Tiere",
  "Herst. Geräte d. Unterhaltungselektronik",
  "Herst. gewebter Oberbekleidung Damen/Mäd",
  "Herst. gewebter Oberbekleidung Herren/Ju",
  "Herst. gewirkt gestrickte Oberbekleidung",
  "Herst. Gipserzeugnisse für den Bau",
  "Herst. Glasfaser und Waren daraus",
  "Herst. handgeführter Werkzeuge m. Motor",
  "Herst. Haushalt Hygiene Toil. Zellstoff",
  "Herst. Heizkörper/-kessel f. Zentral-H.*",
  "Herst. Holzwaren a.n.g. (...) ohne Möbel",
  "Herst. homogenisierte diätetisch Nahrung",
  "Herst. hydraulischer pneumatischer Komp.",
  "*Herst. kälte-/lufttechnisches Erzeugnis",
  "Herst. keramischer Erzeugn. f. Technik *",
  "Herst. keramischer Haushaltsware Ziergut",
  "Herst. konfekt.Textile (ohne Bekleidung)",
  "Herst. Körperpflegemittel & Duftstoffe",
  "Herst. Lager Getriebe Zahnrad AntriebsE*",
  "Herst. land-/forstwirtschaftl. Maschinen",
  "Herst. Leder Lederfaserstoff Felle *****",
  "Herst. Maschine best. Wirtschaftszweige",
  "Herst. Maschine Metallerzeugung Walzwerk",
  "Herst. Maschine Nahrungs-/Genussm./Tabak",
  "Herst. Maschine Papiererzeugung/-verarb.",
  "Herst. Maschinen Verarbeitung Kunststoff",
  "Herst. Maschinenspannzeuge & sonst. Zub.",
  "Herst. medizintechnischer Apparate",
  "Herst. nicht-elek. Mess/Kontroll/Naviga*",
  "Herst. Nutzkraftwagen und NKW-Motoren",
  "Herst. Öle Fette (ohne Margarine u.ä.)",
  "Herst. optischer fotografischer Geräte",
  "Herst. orthopädischer Erzeugnisse",
  "Herst. Personenkraftwagen PKW-Motoren",
  "Herst. pharmazeutischer Spezialitäten **",
  "Herst. Platte Folie Schlauch Kunststoff*",
  "Herst. pulvermetallurgischer Erzeugnisse",
  "Herst. Pumpen und Kompressoren a.n.g.",
  "Herst. Rohrform- Rohrverschluss-/R-Verb*",
  "Herst. Sägen Maschinenwerkzeuge f. Holz",
  "Herst. Schleifkörper Schleifmittel *****",
  "Herst. Schloss Beschlag unedler Metalle",
  "Herst. Schmuck Gold-/Silberschmiedeware*",
  "Herst. Schreibware Bürobedarf aus Papier",
  "Herst. Seife Wasch-/Reinigungs-/Polierm.",
  "Herst. sons. anorganischer Chemikalien *",
  "Herst. sons. nicht WZspez. Maschine ****",
  "Herst. sons. organischer Chemikalien ***",
  "Herst. sons.Erz. Beton Zement Gips a.n.g",
  "Herst. sons.Erz. nichtmetall. Mineralien",
  "Herst. sonst. chemischer Erzeugnisse ang",
  "Herst. sonst. Elektr. & elektr. Draht",
  "Herst. sonst. elektronischer Bauelemente",
  "Herst. sonst. Konstruktion Fertigbauteil",
  "Herst. sonst. Teile Zubehör f Kraftwagen",
  "Herst. sonst. Waren Papier Karton Pappe",
  "Herst. sonstiger gewirk./gestrickt Bekl.",
  "Herst. sonstiger Textilwaren a.n.g.",
  "Herst. v. Geräten für die Landwirtschaft",
  "Herst. v. Ziegeln & sonstiger Baukeramik",
  "Herst. Verbrennungsmotor/Turbine (ohne*)",
  "Herst. Veredlung Bearb. sonstiges Glas *",
  "Herst. Verpackungsmittel aus Kunststoff",
  "Herst. Verpackungsmittel Lagerbehälter *",
  "Herst. Vliesstoff u.Erzeugn.daraus (o.B*",
  "Herst. von Batterien und Akkumulatoren",
  "Herst. von elektrischen Haushaltsgeräten",
  "Herst. von Futtermitteln für Nutztiere",
  "Herst. von Mörtel und anderem Beton",
  "Herst. von pyrotechnischen Erzeugnissen",
  "Herst. von Solarzellen und Solarmodulen",
  "Herst. von sonstigen Ofen und Brennern",
  "Herst. Wellpapier/-pappe Verpackungsm. *",
  "Herst. Werkzeugmaschinen f. harte Stoffe",
  "Herst. Werkzeugmaschinen f. Steine Beton",
  "Herst. Werkzeugmaschinen Metallbearbeit.",
  "Herst.elektrisches Installationsmaterial",
  "Herstell. Apfelwein & andere Fruchtweine",
  "Herstell. Büromaschine (o. DV-Geräte/Per",
  "Herstell. Düngemittel Stickstoffverbind.",
  "Herstell. Furnier-/Sperrholz-/Spanplatte",
  "Herstell. gewirkter & gestrickter Stoff",
  "Herstell. Karosserie Aufbauten Anhänger",
  "Herstell. keramischer Wand-/Bodenfliesen",
  "Herstell. Ladenmöbel sonst. Objektmöbel",
  "Herstell. pharmazeutischer Grundstoffe",
  "Herstell. sonst. elektrischer Geräte & *",
  "Herstell. sonstiger Werkzeugmaschinen **",
  "Herstell. Stahlrohre (o. PräzisionsStR)",
  "Herstell. Süßwaren (ohne Dauerbackwaren)",
  "Herstell. Telekommunikationstechnik ****",
  "Herstell. v Frischbeton (Transportbeton)",
  "Herstell. v. Hebezeuge und Fördermittel",
  "Herstell. von Arbeits-/Berufsbekleidung",
  "Herstellung Ausbauelemente aus Metall",
  "Herstellung Backwaren (o. Dauerbackware)",
  "Herstellung Drahtwaren Ketten Federn",
  "Herstellung Kunststoffe in Primärformen",
  "Herstellung Sammelbehälter Tanks Metall",
  "Herstellung sonst. Bekleidung & -zubehör",
  "Herstellung sonst. Erzeugnissen a.n.g.",
  "Herstellung sonstiger Fahrzeuge a.n.g.",
  "Herstellung sonstiger Kunststoffwaren",
  "Herstellung sonstiger Möbel a.n.g.",
  "Herstellung sonstiger Nahrungsmittel ***",
  "Herstellung von Armaturen a.n.g.",
  "Herstellung von Bau-/Baustoffmaschinen",
  "Herstellung von Besen und Bürsten",
  "Herstellung von bestückten Leiterplatten",
  "Herstellung von Bier",
  "Herstellung von Blankstahl",
  "Herstellung von Büromöbeln",
  "Herstellung von Chemiefasern",
  "Herstellung von Dauerbackwaren",
  "Herstellung von Fantasieschmuck",
  "Herstellung von Farbstoffen & Pigmenten",
  "Herstellung von Fertiggerichten",
  "Herstellung von Flachglas",
  "Herstellung von Freiformschmiedestücken",
  "Herstellung von Frucht- und Gemüsesaft",
  "Herstellung von Glasfaserkabeln",
  "Herstellung von Handwerkzeugen",
  "Herstellung von Hohlglas",
  "Herstellung von Holz- und Zellstoff",
  "Herstellung von Industriegasen",
  "Herstellung von kaltgezogenem Draht",
  "Herstellung von Klebstoffen",
  "Herstellung von Krafträdern",
  "Herstellung von Küchenmöbeln",
  "Herstellung von Matratzen",
  "Herstellung von Metallkonstruktionen",
  "Herstellung von Metallwaren a.n.g.",
  "Herstellung von Miederwaren",
  "Herstellung von Musikinstrumenten",
  "Herstellung von Papier, Karton und Pappe",
  "Herstellung von Parketttafeln",
  "Herstellung von Polstermöbeln",
  "Herstellung von Präzisionsstahlrohren",
  "Herstellung von Press-/Zieh-/Stanzteile",
  "Herstellung von Prüfmaschinen",
  "Herstellung von Sanitärkeramik",
  "Herstellung von Schuhen",
  "Herstellung von Solarwärmekollektoren",
  "Herstellung von sonstigen Gummiwaren",
  "Herstellung von sonstigen Werkzeugen",
  "Herstellung von Speiseeis",
  "Herstellung von Spielwaren",
  "Herstellung von Spirituosen",
  "Herstellung von Sportgeräten",
  "Herstellung von technischen Textilien",
  "Herstellung von Teigwaren",
  "Herstellung von Teppichen",
  "Herstellung von Uhren",
  "Herstellung von Waffen und Munition",
  "Herstellung von Würzmitteln und Soßen",
  "Herstellung von Zement",
  "Holdinggesell. ohne Managementfunkt.",
  "Holzeinschlag",
  "Hörfunkveranstalter",
  "Hotels (ohne Hotels garnis)",
  "Hotels garnis",
  "Imbissstuben u.Ä.",
  "Industrie-, Produkt- und Mode-Design",
  "Industrie-Stiftungen",
  "Ingenieurbüro Bautechnik Gesamtplanung",
  "Ingenieurbüro techn. Fachplanung Design*",
  "Inkassobüros",
  "Installation Maschinen Ausrüstungen ang.",
  "Instandhaltng Reparatur Kraftwagen <3,5t",
  "Instandhaltng Reparatur Kraftwagen >3,5t",
  "Institutionen für Finanzierungsleasing",
  "Interior Design und Raumgestaltung",
  "Internetserviceprovider",
  "Jagd Fallenstellerei damit verb. Tätigk.",
  "Jugendherbergen und Hütten",
  "Kabelnetzleitungstiefbau",
  "Kapitalanlagegesellsch. (Fondsmanag.) **",
  "Kartoffelverarbeitung",
  "Kauf Verkauf eig. GewerbeGrundstück/Geb.",
  "Kauf/Verkauf eig. Wohngrundstücken/-geb*",
  "Kindergärten",
  "Kinos",
  "Kirchl. Körperschaft öffentl. Recht NMP",
  "Kirchliche Körperschaft öffentl. Recht",
  "Komplementärgesell. ohne GF-Befugnis",
  "Kosmetiksalons",
  "Krankenhäuser (ohne Hochschulkliniken *)",
  "Krankenversicherungen",
  "Kulturunterricht",
  "Lackieren von Kraftwagen",
  "Lagerei",
  "landwirtschaftl Dienstleist. Pflanzenbau",
  "landwirtschaftl Dienstleist. Tierhaltung",
  "Leasing nichtfinanz. immater. Vermögen *",
  "Lebensversicherungen",
  "Lederverarbeitung (o. Herst. Lederbekl.)",
  "Leichtmetallgießereien",
  "Leihhäuser",
  "Leitungsgeb. Telekommunik. (o. Telekom)",
  "Luft- und Raumfahrzeugbau",
  "Mahl- und Schälmühlen",
  "Maler- und Lackierergewerbe",
  "Markt- und Meinungsforschung",
  "Massagepraxis Krankengymnastikpraxis ***",
  "Mechanik a.n.g.",
  "Meeresaquakultur",
  "Messe- Ausstellung- Kongressveranstalter",
  "Mgt-Holdingges. finanziell Anteilsbesitz",
  "Mgt-Holdingges. mit aktiv Vers.-geschäft",
  "Mieter- und Hausbesitzervereine",
  "Mieter- und Hausbesitzervereine NMP",
  "Milchverarbeitung (o. Herst. Speiseeis)",
  "Mineralölverarbeitung",
  "Museen",
  "Nach der Ernte anfallende Tätigkeiten",
  "Nachbearbeitung und sonstige Filmtechnik",
  "nn",
  "Notariate",
  "Oberflächenveredlung und Wärmebehandlung",
  "öff./rechtl. Industrie-& Handelskammer *",
  "Offene Investmentvermögen (ohne GeldmF)",
  "öffentlich-rechtliche Berufsvertretungen",
  "Öko-Anbau v. Gemüse und Melonen",
  "Öko-Anbau v.Getreide Hülsenfr. u.Ölsaate",
  "Ökologische Milchviehhaltung",
  "Ökologische Schweinehaltung",
  "Opern- und Schauspielhaus Konzerthalle",
  "Orchester, Kapelle, Chor, Ballettgruppe",
  "Org. Bildung/Wissensch./Forschung/Kultur",
  "Org. freie Wohlfahrtspflege/Jugendhilfe",
  "Org. Sport- und Gesundheitswesens",
  "Org. Sport- und Gesundheitswesens NMP",
  "Org.Bildg./Wissensch./Forschg/Kultur NMP",
  "Org.freie Wohlfahrtspfl./Jugendhilfe NMP",
  "Patentanwaltskanzleien",
  "Pensionen",
  "Pensionskasse & Pensionsfonds (o. ZVA) *",
  "Personenbeförd Omnibus-Linienfernverkehr",
  "Personenbeförd. im Nahverkehr (ohne Taxi",
  "Personenbeförderg See-/Küstenschifffahrt",
  "Personenbeförderung Binnenschifffahrt",
  "Personenbeförderung Eisenbahnfern (o.DB)",
  "Personenbeförderung im Landverkehr a.n.g",
  "Personenbeförderung in der Luftfahrt",
  "Personenbeförderung Omnibus-Gelegenheit*",
  "Pflegeheime",
  "Polit. Parteien, sonst. Org. o.Erw.zweck",
  "Polit.Parteien,sonst.Org.o.Erw.zweck NMP",
  "Postdienste Dienstleister (o. Deut.Post)",
  "Praxen Steuerberater Steuerbevollmächt.",
  "Praxen vereid. BuchprüferIn Gesellschaft",
  "Praxen WirtschaftsprüferInnen WPGesell.*",
  "Praxis psychologische PsychotherapeutIn",
  "Private Equity Fonds.u.Venture Capital F",
  "Private Wach- und Sicherheitsdienste",
  "Privatquartiere",
  "Public-Relations-Beratung",
  "Raumtransport",
  "Rechtsanwaltskanzleien mit Notariat",
  "Rechtsanwaltskanzleien ohne Notariat",
  "Reinigung von Verkehrsmitteln",
  "Reisebüros",
  "Reiseveranstalter",
  "Reparatur Datenverarbeitungsgeräte & P*",
  "Reparatur elektr. Haushalts-/Gartengerät",
  "Reparatur elektronische optische Geräte",
  "Reparatur Geräte Unterhaltungselektronik",
  "Reparatur Instandh. Luft-/Raumfahrzeuge",
  "Reparatur Instandhaltung von Fahrzeugen",
  "Reparatur Möbel Einrichtungsgegenständen",
  "Reparatur von elektrischen Ausrüstungen",
  "Reparatur von Maschinen",
  "Reparatur von Metallerzeugnissen",
  "Reparatur von Schuhen und Lederwaren",
  "Reparatur von sonstigem Gebrauchsgut",
  "Reparatur von sonstigen Ausrüstungen",
  "Reparatur von Telekommunikationsgeräten",
  "Reparatur von Uhren und Schmuck",
  "Restaurant mit herkömmlicher Bedienung",
  "Restaurants mit Selbstbedienung",
  "Risiko- und Schadensbewertung",
  "Rohrleitungstiefbau Brunnenbau Kläranl.",
  "Rückgewinnung sortierter Werkstoffe",
  "Rückversicherungen",
  "S- Kreditpartner (Mitgl.S-Finanzgruppe) ",
  "Säge-, Hobel- und Holzimprägnierwerke",
  "Sammlung nicht gefährlicher Abfälle",
  "Saunas, Solarien, Bäder u.Ä.",
  "Schaden- und Unfallversicherungen",
  "Schankwirtschaften",
  "Schiffbau (ohne Boots- und Yachtbau)",
  "Schlachten (ohne Schlachten v. Geflügel)",
  "Schornstein-/Feuerungs-/Industrieofenbau",
  "Schornsteinreinigung",
  "Selbstst. Artistin Artisten Zirkusgruppe",
  "Selbstst. Journalisten Pressefotografen",
  "Selbstst. KomponistInn Musikbearbeit.In*",
  "Selbstst. Künstler Film/Fernseh/Bühne/*",
  "Selbstständige bildende KünstlerInnen",
  "Selbstständige(r) RestauratorInnen",
  "Selbstständige(r) SchriftstellerInnen",
  "Sicherheitsdienst mithilfe Alarmsystem *",
  "Sons. freiberufl. wissenschaftl. techn.T",
  "Sons. Kirche, religiös & weltanschaul.V*",
  "Sons. mit Finanzdienstleistung verb.Tät*",
  "Sons. selbstständ. Tätigk. Gesundheitsws",
  "Sons. soziale Betreuung Älterer Behinder",
  "Sons.Kirche,religiöse,weltanschaul.V.NMP",
  "Sonst. Einzelh. a.n.g. (im Verkaufsraum)",
  "Sonst. Einzelh. Nahrung Getränke Tabak *",
  "Sonst. Einzelh. Nahrungs-/Genussmittel",
  "Sonst. Einzelhandel (nicht Verkaufsraum)",
  "Sonst. Finanzierungsinstitute² (o.***)",
  "Sonst. Forschung Natur Ingenieur Agrar *",
  "Sonst. Heime (o. Erholungs-/Ferienheim)",
  "Sonst. Kreditinstitute, sofern keine MFI",
  "Sonst. Post-/Kurier- & Expressdienste",
  "Sonst. spez. Reinigung Gebäude Maschinen",
  "Sonst. Verarbeitung von Obst und Gemüse",
  "Sonst. Versand- & Internet-Einzelhandel",
  "Sonst. Verwaltung Führung Unternehmen **",
  "Sonstige Bauinstallation a.n.g.",
  "Sonstige Beherbergungsstätte a.n.g.",
  "Sonstige Finanzinstitutionen",
  "Sonstige getränkegeprägte Gastronomie",
  "Sonstige Haltung von Nutzgeflügel",
  "Sonstige Ingenieurbüros",
  "Sonstige Reinigung a.n.g.",
  "Sonstige Softwareentwicklung",
  "Sonstige Telekommunikation a. n. g.",
  "Sonstige Tierhaltung",
  "Sonstige Überlassung von Arbeitskräften",
  "Sonstiger Ausbau a.n.g.",
  "Sonstiger NE-Metallerzbergbau",
  "Sonstiger Tiefbau a.n.g.",
  "Sonstiges Sozialwesen a.n.g.",
  "Sonstiges Verlagswesen (ohne Software)",
  "Sonstiges Veterinärwesen",
  "Spedition",
  "Spielbanken und Spielklubs",
  "Spielhallen & Betrieb von Spielautomaten",
  "Spinnstoffaufbereitung und Spinnerei",
  "Sport- und Freizeitunterricht",
  "Sportvereine",
  "Station. Einrichtung psychosozial Suchtb",
  "Stiftungen (o. Industrie-Stiftungen) NMP",
  "Stiftungen (ohne Industrie-Stiftungen)",
  "Süßwasseraquakultur",
  "Süßwasserfischerei",
  "Tabakverarbeitung",
  "Tagesbetreuung von Kindern",
  "Tätigkeit von VersicherungsmaklerInnen",
  "Techn./physikal./chemische Untersuchung",
  "Test- und Suchbohrung",
  "Theater- und Konzertveranstalter",
  "Theaterensembles",
  "Tierarztpraxen",
  "Tonstudio Herstellung v. Hörfunkbeiträge",
  "Transport in Rohrfernleitungen",
  "Übersetzen",
  "Übrige Finanzdienstleist.inst.(ohne 664)",
  "Umzugstransporte",
  "Universitäten",
  "Unternehmensberatung",
  "Unternehmensbeteiligungsgesell.u.Sonstig",
  "Unterricht a.n.g.",
  "Varietés und Kleinkunstbühnen",
  "Verarbeitung Kaffee/Tee/Kaffee-Ersatz **",
  "Verband der Versicherungsunternehmen",
  "Verbände des Kreditgewerbes",
  "Verbriefungszweckgesellschaften",
  "Veredlung und Bearbeitung von Flachglas",
  "Veredlung von Textilien und Bekleidung",
  "Vergnügungs- und Themenparks",
  "Vergnügungslokale",
  "Verlegen von bespielten Tonträgern",
  "Verlegen von Büchern",
  "Verlegen von Computerspielen",
  "Verlegen von Musikalien",
  "Verlegen von sonstiger Software",
  "Verlegen von Zeitschriften",
  "Verlegen von Zeitungen",
  "Verm. sons. Maschinen Geräte bewegl. Sa*",
  "Vermarktung von Werbezeit u. Werbefläche",
  "Vermessungsbüros",
  "Vermiet.Verpacht.eig./gelst.Gewerbegrdst",
  "Vermiet.Verpacht.eig./gelst.Wohngrndstke",
  "Vermietung Büromaschinen, DV-Geräte, ***",
  "Vermietung Kraftwagen Ges.Gewicht <3,5t",
  "Vermietung Kraftwagen Ges.Gewicht >3,5t",
  "Vermietung landwirtschaftliche Maschinen",
  "Vermietung Sport- und Freizeitgeräten",
  "Vermietung von Baumaschinen und -geräten",
  "Vermietung von Luftfahrzeugen",
  "Vermietung von sonstigen Gebrauchsgütern",
  "Vermietung von Wasserfahrzeugen",
  "Vermittl. Gewerbegrundstück/Nichtwohngeb",
  "Vermittl. Wohngrundstücke Wohngebäude **",
  "Vermittlung von Arbeitskräften",
  "Versand-Internet-Einzelh. Textil Schuhe*",
  "Versicherungsdienstleist. Pensionskasse*",
  "Versteigerungsgewerbe",
  "Vervielfältigung besp. Ton-/Bildträger *",
  "Verwaltung Gewerbegrundstücke/-gebäude *",
  "Verwaltung Wohngrundstücke Wohngebäude *",
  "Videotheken",
  "Vorbereitende Baustellenarbeiten",
  "Vorsorge- und Rehabilitationsklinik",
  "W.-Förderung Beherbergung und Tourismus",
  "W.-Förderung Infrastruktur",
  "W.-Förderung Verkehr",
  "Wagniskapitalbeteiligungsgesellschaften",
  "Wärme- und Kälteversorgung",
  "Wäscherei und chemische Reinigung",
  "Wasserbau",
  "Wassergewinnung m. Fremdbezug Verteilung",
  "Wassergewinnung o.Fremdbezug z.Verteilng",
  "Wasserverteilung ohne Gewinnung",
  "Weberei",
  "Webportale",
  "Werbeagenturen",
  "Wertpapier- und Terminbörsen & Clearing",
  "Wett-, Toto- und Lotteriewesen",
  "Wirtschaftsförderung/Tourismusförderung",
  "Wirtschaftsvereinigungen",
  "Wohnungsunternehmen mit Spareinrichtung²",
  "Zahnarztpraxen",
  "Zahntechnische Laboratorien",
  "Zerlegen von Schiffs- und Fahrzeugwracks",
  "Zimmerei und Ingenieurholzbau",
  "Zusatzversorgungsanstalt öffentl. Dienst",
];

const renderEuro = (p) => {
  const amount = p.value;
  return (
    Math.round(amount)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €"
  );
};

const mostFrequentValueGetter = (params) => {
  const values = params.values;
  const frequencyMap = {};
  let maxFrequency = 0;
  let mostFrequentValue = null;

  values.forEach((value) => {
    const frequency = (frequencyMap[value] || 0) + 1;
    frequencyMap[value] = frequency;
    if (frequency > maxFrequency) {
      maxFrequency = frequency;
      mostFrequentValue = value;
    }
  });

  return mostFrequentValue;
};

const customCellRenderer = (params) => {
  //console.log('customCellRenderer');
  const node = params.node;
  //console.log(params);
  if (node.group && !node.footer) {
    const representativeRow = node.allLeafChildren.find((childNode) => childNode.data.role === CLIENT_ROLES_LIST.LEADER_ROLE);
    //console.log(representativeRow);
    if (representativeRow) {
      const data = representativeRow.data[params.colDef.field];

      return <span>{data}</span>;
    }
  }
  // Render the default cell value for other rows
  //console.log(params);
  return params.value ? String(params.value) : null;
};

// const customCellEuroRenderer = (params) => {
//   console.log('customCellRenderer');
//   const node = params.node;
//   //console.log(params);
//   if (node.group && !node.footer) {
//     const representativeRow = node.allLeafChildren.find(
//       (childNode) => childNode.data.role === CLIENT_ROLES_LIST.LEADER_ROLE,
//     );
//     console.log(representativeRow);
//     if (representativeRow) {
//       const data = representativeRow.data[params.colDef.field];

//       return (
//         Math.round(data)
//           .toString()
//           .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
//       );
//     }
//   }
//   // Render the default cell value for other rows
//   console.log(params);
//   return params.value
//     ? Math.round(params.value)
//         .toString()
//         .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
//     : null;
// };

const customBearbeiterRenderer = (params) => {
  //console.log('customCellRenderer');
  const node = params.node;
  //console.log(params);
  if (node.group && !node.footer) {
    var groupHasNonEmptyString = node.allLeafChildren.some(function (child) {
      return child.data.mod_consultant_id !== "" && child.data.mod_consultant_id !== undefined;
    });

    if (groupHasNonEmptyString) {
      return <SyncAltIcon sx={{ color: greenColor }} size="large" />;
    } else {
      return <></>;
    }
  }
  // Render the default cell value for other rows
  //console.log(node);
  const last_modified_consulutant = node.data.mod_consultant_id;
  if (last_modified_consulutant) {
    return last_modified_consulutant;
  } else {
    return <></>;
  }
};

const rightColumns = [
  {
    rowDrag: true,
    maxWidth: 50,
    filter: false,
    suppressHeaderMenuButton: true,
    rowDragText: (params, dragItemCount) => {
      if (dragItemCount > 1) {
        return dragItemCount + " Kunden";
      }
      return params.rowNode.data.lastname + "__:__" + params.rowNode.data.lastname;
    },
  },
  {
    headerName: "Details Aufblättern",
    children: [
      {
        field: "wirtschaftsverbund_nr",
        headerName: process.env.REACT_APP_CLIENT === CLIENT_LIST.SKDU ? "Akquisitionsverbund Nr." : "Wirtschaftsverbund Nr.",
        minWidth: 80,
      },
      {
        field: "person_nr",
        minWidth: 80,
        headerName: "Personen Nr.",
      },
      {
        field: "lastname",
        minWidth: 100,
        headerName: "Nachname",
        columnGroupShow: "close",
      },
      {
        field: "firstname",
        minWidth: 100,
        headerName: "Vorname",
        columnGroupShow: "close",
      },
      {
        field: "right_form",
        minWidth: 100,
        headerName: "Rechtsform",
        columnGroupShow: "open",
        hide: true,
      },
      {
        field: "consultant_id",
        headerName: "OE Kundenbetreuer",
        filter: "agSetColumnFilter",
        aggFunc: mostFrequentValueGetter,
        columnGroupShow: "open",
      },
      {
        field: "role",
        minWidth: 100,
        headerName: "Rolle der Person",
        editable: true,
        filter: "agSetColumnFilter",
        cellEditor: "agSelectCellEditor",
        cellEditorParams: {
          values: KUNDEN_ROLLEN,
        },
      },
      {
        field: "branch",
        minWidth: 200,
        headerName: "Wirtschaftszweig",
        //columnGroupShow: 'open',
        editable: true,
        filter: "agSetColumnFilter",
        //cellEditor: 'agRichSelectCellEditor',
        cellEditor: "agSelectCellEditor",
        cellEditorParams: {
          values: BRANCH_LIST,
          //cellHeight: 20,
          //formatValue: (value) => value.toUpperCase(),
          //searchDebounceDelay: 500,
        },
        cellRenderer: customCellRenderer,
      },
      {
        field: "client_type",
        minWidth: 100,
        headerName: "Kundentyp",
        columnGroupShow: "open",
        hide: process.env.REACT_APP_CLIENT === CLIENT_LIST.SKDU ? true : false,
      },
      {
        field: "double_person_nr",
        minWidth: 100,
        headerName: "Doppelte Kunden",
        columnGroupShow: "open",
        hide: process.env.REACT_APP_CLIENT === CLIENT_LIST.SKPDH ? false : true,
      },
      {
        field: "lei_code",
        minWidth: 100,
        headerName: "LEI Code",
        editable: true,
        hide: process.env.REACT_APP_CLIENT === CLIENT_LIST.SKDU ? false : true,
      },
      {
        field: "active_volume",
        headerName: "Aktivvolumen",
        cellRenderer: renderEuro,
        aggFunc: "sum",
        filter: "agNumberColumnFilter",
        columnGroupShow: "open",
        editable: true,
      },
      {
        field: "aussen_umsatz",
        headerName: "Außenumsatz",
        columnGroupShow: "open",
        cellRenderer: renderEuro,
        aggFunc: "sum",
        filter: "agNumberColumnFilter",
        editable: true,
      },
    ],
  },
];

const defaultColDef = {
  flex: 1,
  minWidth: 80,
  filter: true,
  sortable: true,
  floatingFilter: true,
  resizable: true,
  wrapHeaderText: true,
  autoHeaderHeight: true,
};
const autoGroupColumnDef = {
  headerName: "Verbund Vorschlag",
  minWidth: 80,
  maxWidth: 500,
  wrapHeaderText: true,
  autoHeaderHeight: true,
};

const EinheitBearbeiten = () => {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const [leftApi, setLeftApi] = useState(null);
  const [rightApi, setRightApi] = useState(null);
  const [rawData, setRawData] = useState([]);
  const [leftRowData, setLeftRowData] = useState(null);
  const [rightRowData, setRightRowData] = useState([]);

  const [collapsed, setCollapsed] = useState(true);
  const [showAllClients, setShowAllClients] = useState(false);
  const [leftColumns, setLeftColumns] = useState([
    //setColumnDefs
  ]);

  useEffect(() => {
    //console.log('segmentNames updated... ');
    // Update the columnDefs state when fetchedData changes
    setLeftColumns([
      {
        rowDrag: true,
        maxWidth: 80,
        resizable: true,
        suppressHeaderMenuButton: true,
        filter: false,
        rowDragText: (params, dragItemCount) => {
          if (dragItemCount > 1) {
            return dragItemCount + " Kunden";
          }
          return params.rowNode.data.lastname + "__:__" + params.rowNode.data.firstname;
        },
      },
      {
        colId: "checkbox",
        maxWidth: 50,
        checkboxSelection: true,
        suppressHeaderMenuButton: true,
        //headerCheckboxSelection: true,
        filter: false,
      },
      {
        headerName: "Details Aufblättern",
        children: [
          {
            field: "mod_consultant_id",
            headerName: "Bearbeitet",
            // valueGetter: function (params) {
            //   return params;
            // },
            cellRenderer: customBearbeiterRenderer,
            //columnGroupShow: 'open',
          },
          {
            field: "verbund_nr",
            //headerName: 'Betreuung Einheit',
            rowGroup: true,
            //aggFunc: 'first',
            hide: true,
            suppressSizeToFit: true,
            sort: "asc",
          },
          {
            field: "wirtschaftsverbund_nr",
            headerName: process.env.REACT_APP_CLIENT === CLIENT_LIST.SKDU ? "Akquisitionsverbund Nr." : "Wirtschaftsverbund Nr.",
            minWidth: 80,
            cellRenderer: customCellRenderer,
          },
          {
            field: "person_nr",
            minWidth: 100,
            headerName: "Personen Nr.",
            cellRenderer: customCellRenderer,
          },

          {
            field: "lastname",
            minWidth: 100,
            headerName: "Nachname",
            columnGroupShow: "close",
            cellRenderer: customCellRenderer,
          },
          {
            field: "firstname",
            minWidth: 100,
            headerName: "Vorname",
            columnGroupShow: "close",
            cellRenderer: customCellRenderer,
          },
          {
            field: "right_form",
            minWidth: 100,
            headerName: "Rechtsform",
            columnGroupShow: "open",
            cellRenderer: customCellRenderer,
            hide: true,
          },
          {
            field: "consultant_id",
            headerName: "OE Kundenbetreuer",
            filter: "agSetColumnFilter",
            cellRenderer: customCellRenderer,
            editable: true,
            columnGroupShow: "open",
          },
          {
            field: "role",
            minWidth: 100,
            headerName: "Rolle der Person",
            columnGroupShow: "open",
          },
          {
            field: "branch",
            minWidth: 200,
            headerName: "Wirtschaftszweig",
            //columnGroupShow: 'open',
            editable: true,
            filter: "agSetColumnFilter",
            //cellEditor: 'agRichSelectCellEditor',
            cellEditor: "agSelectCellEditor",
            cellEditorParams: {
              values: BRANCH_LIST,
              //cellHeight: 20,
              //formatValue: (value) => value.toUpperCase(),
              //searchDebounceDelay: 500,
            },
            cellRenderer: customCellRenderer,
          },
          {
            field: "double_person_nr",
            minWidth: 100,
            headerName: "Doppelte Kunden",
            columnGroupShow: "open",
            hide: process.env.REACT_APP_CLIENT === CLIENT_LIST.SKPDH ? false : true,
          },
          {
            field: "lei_code",
            minWidth: 50,
            headerName: "LEI Code",
            editable: true,
            cellRenderer: customCellRenderer,
            hide: process.env.REACT_APP_CLIENT === CLIENT_LIST.SKDU ? false : true,
          },
          {
            field: "active_volume",
            headerName: "Aktivvolumen",
            cellRenderer: renderEuro,
            aggFunc: "sum",
            //cellRenderer: customCellEuroRenderer,
            filter: "agNumberColumnFilter",
            columnGroupShow: "open",
            editable: true,
          },
          {
            field: "aussen_umsatz",
            headerName: "Außenumsatz",
            columnGroupShow: "open",
            cellRenderer: renderEuro,
            aggFunc: "sum",
            //cellRenderer: customCellEuroRenderer,
            filter: "agNumberColumnFilter",
            editable: true,
          },
        ],
      },
    ]);
  }, [leftRowData]);

  const saveVerbund = useCallback(
    //Axios Call
    async () => {
      try {
        console.log("saveVerbund");

        // construct the person ID list
        let client_id_list = [];
        let role_leader = "";
        let refuse = false;
        rightApi.forEachNode((node) => {
          const data = node.data;
          //console.log(data);
          var id = data.person_nr;
          if (data.role === LEADER_ROLE) {
            if (role_leader !== "" && role_leader !== id) {
              refuse = true;
            } else {
              role_leader = id;
            }
          }
          client_id_list.push(id);
          //rightApi.setDeltaSort(true);
        });
        if (refuse) {
          alert("Unterschiedliche Kunden haben Verbundführer Rolle, bitte nur einen entscheiden.");
        } else {
          const uniqueSortedItems = [...new Set(client_id_list)].sort();
          //console.log({ uniqueSortedItems });
          if (role_leader === "") {
            alert("Bitte einen Kunden auswählen, um deren Person Nr. als Verbundführer zu setzen.");
          } else {
            console.log("call /clients/saveVerbund");
            await axiosPrivate.post("/clients/saveVerbund", {
              client_id_list: uniqueSortedItems,
              leader: role_leader,
            });
            loadGrids();
          }
        }
      } catch (err) {
        console.log("saveVerbund failed:" + err);
      }
    },
    [axiosPrivate, rightApi, rightRowData, leftApi],
  );

  const loadGrids = useCallback(async () => {
    //reading data
    console.log("read data and rawData.length: " + rawData.length);
    const method = showAllClients ? "clients/myclients" : "clients/myOwnClients";
    //if (!rawData.length) {
    try {
      setRightRowData([]);

      await axiosPrivate
        .get(method, { timeout: 600000 })
        .then((res) => res.data)
        .then((data) => {
          console.log("read data successful");
          let filteredData = data;
          setRawData(filteredData);
          setLeftRowData([...filteredData]);
          if (leftApi) {
            leftApi.deselectAll();
          }
        });
    } catch (err) {
      if (!err?.response) {
        console.log("No Server Response for: " + method + " : " + err);
      } else {
        console.log("ReadData Failed by loadGrids " + err);
      }
      navigate(LOGIN_URL);
    }
    //}
  }, [auth.competences, axiosPrivate, leftApi, rightApi, showAllClients]);

  const getRowId = (params) => params.data._id; //person_nr + '_RID_' + params.data.risk_unit_nr;

  const addGridDropZone = (side, api) => {
    const dropApi = side === "Left" ? rightApi : leftApi;
    const dropZone = dropApi.getRowDropZoneParams({
      onDragStop: async function (params) {
        const nodes = params.nodes;
        const nodedata = nodes.map(function (node) {
          return node.data;
        });

        api.applyTransaction({
          remove: nodedata,
        });
      },
    });
    api.addRowDropZone(dropZone);
  };
  useEffect(() => {
    if (rightApi && leftApi) {
      addGridDropZone("Right", rightApi);
      addGridDropZone("Left", leftApi);
    }
  }, [leftApi, rightApi]);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agTotalAndFilteredRowCountComponent", align: "left" },
        { statusPanel: "agTotalRowCountComponent", align: "center" },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    };
  }, []);

  const onGridReady = (params, side) => {
    //console.log('onGridReady');
    if (side === 0) {
      setLeftApi(params.api);
      //setLeftColumnApi(params.columnApi);
    }

    if (side === 1) {
      setRightApi(params.api);
    }
    loadGrids();
  };

  const batchAssignEinzelKunde = useCallback(async () => {
    let selectedData = leftApi.getSelectedRows();

    if (!selectedData) {
      console.log("no data selected to process");
      return;
    }
    let client_person_id_list = [];
    if (selectedData.length > 0) {
      console.log("batchAssignNoEinheit for " + selectedData.length + " clients.");
      for (const item of selectedData) {
        var id = item.person_nr;
        //console.log({ id });
        client_person_id_list.push(id);
      }
      //console.log({ client_person_id_list });

      try {
        await axiosPrivate.post("clients/einzelkunde", {
          client_person_id_list,
        });
      } catch (err) {
        if (!err?.response) {
          console.log("No Server Response for put clients/einzelkunde" + err);
        } else {
          console.log("batchAssignNoEinheit Failed " + err.response.toString);
        }
      }
    }
    loadGrids();
  }, [axiosPrivate, leftApi]);

  const toggleCollapse = useCallback(() => {
    if (collapsed) {
      leftApi.expandAll();
      setCollapsed(false);
    } else {
      leftApi.collapseAll();
      setCollapsed(true);
    }
  });

  const loadAllClients = useCallback(async () => {
    //if (!rawData.length) {
    try {
      await axiosPrivate
        .get("clients/myclients", { timeout: 600000 })
        .then((res) => res.data)
        .then((data) => {
          console.log("read complete data successful");
          let filteredData = data;
          setRawData(filteredData);
          setLeftRowData([...filteredData]);
          if (leftApi) {
            leftApi.deselectAll();
          }
        });
    } catch (err) {
      if (!err?.response) {
        console.log("No Server Response for clients/myclients: " + err);
      } else {
        console.log("ReadData Failed by loadAllClients " + err);
      }
      navigate(LOGIN_URL);
    }
    //}
  }, [rawData, axiosPrivate, leftApi]);

  const loadMyClients = useCallback(async () => {
    //if (!rawData.length) {
    try {
      console.log("loadMyClients");
      await axiosPrivate
        .get("clients/myOwnClients", { timeout: 600000 })
        .then((res) => res.data)
        .then((data) => {
          console.log("read complete data successful");
          let filteredData = data;
          setRawData(filteredData);
          setLeftRowData([...filteredData]);
          if (leftApi) {
            leftApi.deselectAll();
          }
        });
    } catch (err) {
      if (!err?.response) {
        console.log("No Server Response for clients/myOwnClients: " + err);
      } else {
        console.log("ReadData Failed by loadMyClients " + err);
      }
      navigate(LOGIN_URL);
    }
    //}
  }, [rawData, axiosPrivate, leftApi]);

  const toggleLeftData = useCallback(async () => {
    console.log("toggleLeftDate");
    if (showAllClients) {
      setShowAllClients(false);
      loadMyClients();
    } else {
      setShowAllClients(true);
      //alert('Bitte einige Minuten warten, bis die Seite sich neu lädt.');
      loadAllClients();
    }
  }, [rawData, axiosPrivate, leftApi, showAllClients]);

  const clearFilter = useCallback(() => {
    // leftApi.clearFilter();
    leftApi.setFilterModel(null);
  }, [leftApi]);

  const getTopToolBar = () => (
    <div className="example-toolbar panel panel-default">
      <div className="panel-body">
        {/* <input type="checkbox" id="toggleCheck" checked={checkBoxSelected} onChange={onCheckboxChange} />
        <label htmlFor="toggleCheck">Menge Bearbeitung</label> */}
        <button onClick={toggleCollapse}>{collapsed ? "Aufblättern" : "Ausblenden"}</button>
        <button onClick={toggleLeftData}>{showAllClients ? "Nur meine Kunden" : "Alle Kunden"}</button>
        <button onClick={clearFilter}>Filter Zurücksetzen</button>
        {
          <span className="input-group-button">
            <button type="button" className="btn btn-default reset" style={{ marginLeft: "5px" }} onClick={loadGrids}>
              <i className="fas fa-redo" style={{ marginRight: "5px" }}></i>Neu laden
            </button>
          </span>
        }
      </div>
    </div>
  );

  const getEinheitButtons = () => (
    <div className="example-toolbar panel panel-default">
      <div className="panel-body">
        <Button variant="contained" onClick={saveVerbund} disabled={auth.role === ROLES_LIST.Auditor}>
          Verbund speichern
        </Button>
      </div>
    </div>
  );

  const submitChange = useCallback(
    async (id, field_name, field_value) => {
      //console.log('update client  data for: ', item);
      console.log("update client data for " + id);

      const item = {
        id: id,
        field_name: field_name,
        field_value: field_value,
      };
      console.log("item: " + JSON.stringify(item));
      try {
        await axiosPrivate.post("/clients/updateSingleField", item);
      } catch (err) {
        if (!err?.response) {
          console.log("No Server Response" + err);
        } else {
          console.log("SubmitChange Failed");
        }
      }
    },
    [axiosPrivate],
  );

  const onCellValueChanged = useCallback((event) => {
    if (auth.role == ROLES_LIST.Auditor) {
      alert("Auditor darf keine Änderung vornehmen.");
      return;
    }
    //console.log(event);
    console.log(onCellValueChanged);
    // make axios call to update the data in the backend
    const oldData = event.data;
    const field = event.colDef.field;
    const newData = { ...oldData };
    newData[field] = event.newValue;
    newData["mod_consultant_id"] = auth.consultant_id;
    //console.log('onCellValueChanged, updating ' + field + ' to ' + event.newValue);
    const tx = {
      update: [newData],
    };
    event.api.applyTransaction(tx);
    //console.log({ newData });

    const row_id = event.node.id;
    console.log("extracted from rowID: " + row_id);
    //Axios call
    if (field !== "role") {
      submitChange(row_id, field, event.newValue);
    }
  }, []);

  const getGridWrapper = (id) => (
    <div className="panel panel-primary" style={{ marginRight: "10px", flex: id === 0 ? "60%" : "40%" }}>
      <div className="panel-heading">{id === 0 ? "Bestehende Kundenverbünde + Einzelkunden" : "Kunden im aktuellen Verbund"}</div>
      <div className="panel-body" style={{ height: "calc(100vh - 180px" }}>
        <AgGridReact
          style={{ height: "100%;" }}
          suppressAggFuncInHeader={true}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          groupDisplayType={"multipleColumns"}
          groupSelectsChildren={true}
          groupSelectsFiltered={true}
          getRowId={getRowId}
          rowDragManaged={true}
          //onRowDragEnd={onRowDrag}
          //onDragStop={id === 0 ? onDragStopR2L : onDragStopL2R}
          animateRows={true}
          rowSelection={id === 0 ? "multiple" : "single"}
          rowDragMultiRow={true}
          suppressRowClickSelection={id === 0 ? true : false}
          //onSelectionChanged={() => onRightSelectionChanged(id)}
          suppressMoveWhenRowDragging={true}
          //onCellEditRequest={onCellEditRequest}
          onCellValueChanged={onCellValueChanged}
          //enableCellChangeFlash={true}
          cellEditorPopup={true}
          statusBar={statusBar}
          rowData={id === 0 ? leftRowData : rightRowData}
          columnDefs={id === 0 ? leftColumns : rightColumns}
          onGridReady={(params) => onGridReady(params, id)}
        ></AgGridReact>
      </div>
    </div>
  );
  return (
    <div className="top-container">
      <Grid container spacing={0}>
        <Grid item xs={7}>
          <Grid container spacing={0}>
            <Grid item xs={10} align="left">
              {getTopToolBar()}
            </Grid>
            <Grid item xs={2} align="right">
              <Button variant="contained" onClick={batchAssignEinzelKunde} disabled={auth.role === ROLES_LIST.Auditor}>
                Einzelkunden
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5} align="right">
          {getEinheitButtons()}
        </Grid>
      </Grid>
      <div className="grid-wrapper ag-theme-alpine">
        {getGridWrapper(0, "3")}
        {getGridWrapper(1, "1")}
      </div>
    </div>
  );
};
export default EinheitBearbeiten;
