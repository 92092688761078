import React, { useCallback, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { LicenseManager } from "ag-grid-enterprise";
import useAxiosPrivate from "../auths/hooks/useAxiosPrivate";
import useAuth from "../auths/hooks/useAuth";
//import { grey } from '@mui/material/colors';

const LOGIN_URL = "/login";

LicenseManager.setLicenseKey(process.env.REACT_APP_AGGRID_LICENCE);

const renderEuro = (p) => {
  const amount = p.value;
  return (
    Math.round(amount)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €"
  );
};

// var rowDrag = function (params) {
//   // only rows that are NOT groups should be draggable
//   return !params.node.group && !params.node.data.is_verbund_leader;
// };

const mostFrequentValueGetter = (params) => {
  const values = params.values;
  const frequencyMap = {};
  let maxFrequency = 0;
  let mostFrequentValue = null;

  values.forEach((value) => {
    const frequency = (frequencyMap[value] || 0) + 1;
    frequencyMap[value] = frequency;
    if (frequency > maxFrequency) {
      maxFrequency = frequency;
      mostFrequentValue = value;
    }
  });

  return mostFrequentValue;
};

const customAggFunc = (params) => {
  const uniquePersons = new Set(params.rowNode.allLeafChildren.map((node) => node.data.person_nr));
  let sum = 0;
  //console.log({ uniquePersons });
  uniquePersons.forEach((person) => {
    const personItems = params.rowNode.allLeafChildren.filter((node) => node.data.person_nr === person);
    const personAmount = personItems.reduce((total, node) => total + node.data.risk_volume, 0);
    sum += personAmount;
  });
  return sum;
};

const processPivotResultColDef = (colDef) => {
  if (colDef.aggFunc === "sum") {
    const field = colDef.person_nr;
    colDef.aggFunc = (params) => {
      const uniqueValues = new Set(params.rowNode.allLeafChildren.map((node) => node.data[field]));
      //console.log({ uniqueValues });
      const sum = Array.from(uniqueValues).reduce((acc, curr) => acc + curr, 0);
      return sum;
    };
  }
};

const GroupDataGrid = ({ ansicht }) => {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const gridRef = useRef();
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState();
  const getRowId = (params) => params.data._id; //unit_name + params.data.person_name;
  const [columnDefs] = useState([
    //setColumnDefs
    {
      headerName: "Details aufblättern",
      children: [
        {
          field: "verbund_person_target",
          headerName: "FK-Einheit (gebildet)",
          rowGroup: true,
          aggFunc: "last",
          hide: true,
          suppressSizeToFit: true,
          sort: "asc",
        },
        {
          field: "unit_name",
          headerName: "Bestehende Einheit Name",
          rowGroup: true,
          hide: true,
          suppressSizeToFit: true,
          sort: "asc",
        },
        {
          field: "person_name",
          headerName: "Einzelkunde Name",
          //rowDrag: rowDrag,
          suppressSizeToFit: true,
          minWidth: 150,
          maxWidth: 400,
          sort: "asc",
        },
        {
          field: "person_nr",
          headerName: "Person Nr.",
          minWidth: 120,
        },
        {
          field: "risk_unit_nr",
          headerName: "Bestehende Einheit Nr.",
          minWidth: 120,
        },
        // {
        //   field: 'risk_unit_contact_pers_nr',
        //   headerName: 'Personennummer Ansprechpartner Risikoeinheit',
        //   minWidth: 120,
        //   columnGroupShow: 'open',
        // },
        {
          field: "mod_consultant_id",
          headerName: "Einheitbilder",
          filter: "agSetColumnFilter",
          aggFunc: mostFrequentValueGetter,
        },
        {
          field: "consultant_id",
          headerName: "Kundenberater",
          filter: "agSetColumnFilter",
          aggFunc: mostFrequentValueGetter,
        },
        {
          field: "risk_volume",
          headerName: "Aktivvolumen",
          aggFunc: "sum",
          cellRenderer: renderEuro,
          filter: "agNumberColumnFilter",
        },
        {
          field: "passive_volume",
          headerName: "Passivvolumen",
          aggFunc: "sum",
          cellRenderer: renderEuro,
          filter: "agNumberColumnFilter",
        },
        {
          field: "sales_fk",
          headerName: "Umsatz",
          cellRenderer: renderEuro,
          aggFunc: "sum",
          filter: "agNumberColumnFilter",
        },
        {
          field: "branch",
          headerName: "Zweigstelle",
          filter: "agSetColumnFilter",
          aggFunc: mostFrequentValueGetter,
        },
        {
          field: "plz",
          headerName: "Postleitzahl",
          filter: "agSetColumnFilter",
          aggFunc: mostFrequentValueGetter,
        },
        {
          field: "eco_branch",
          headerName: "Wirtschaftszweig",
          filter: "agSetColumnFilter",
          aggFunc: mostFrequentValueGetter,
          columnGroupShow: "open",
        },
        {
          field: "right_form",
          filter: "agSetColumnFilter",
          headerName: "Rechtsformausprägung",
          aggFunc: mostFrequentValueGetter,
          columnGroupShow: "open",
        },
      ],
    },
  ]);
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: true,
      sortable: true,
      floatingFilter: true,
      resizable: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      //backgroundColor: grey,
      minWidth: 100,
    };
  }, []);

  const autoGroupColumnDef = useMemo(() => {
    return {
      //headerName: 'Betreuung Einheit',
      minWidth: 220,
      maxWidth: 500,
      sortable: true,
      field: "group_field", // add a field to identify the top level group nodes
      cellRenderer: "agGroupCellRenderer", // use the default group cell renderer
      cellRendererParams: {
        suppressCount: false, // suppress the count label
      },
      aggFunc: customAggFunc,
      children: [
        {
          field: "risk_volume",
          aggFunc: customAggFunc,
        },
      ],
    };
  }, []);

  // const submitChange = useCallback(
  //   async (item) => {
  //     console.log('update client person data for: ', item);
  //     try {
  //       await axiosPrivate.post('/client_persons', item);
  //     } catch (err) {
  //       if (!err?.response) {
  //         console.log('No Server Response' + err);
  //       } else {
  //         console.log('SubmitChange Failed');
  //       }
  //     }
  //   },
  //   [axiosPrivate],
  // );

  const readData = useCallback(async () => {
    var method = "client_persons/assigned";
    try {
      await axiosPrivate
        .get(
          method,
          { data: auth.competences },
          {
            timeout: 600000,
          },
        )
        .then((res) => res.data)
        .then((data) => {
          //console.log({ data });
          setRowData(data);
          gridRef.current.api.refresh;
        });
    } catch (err) {
      if (!err?.response) {
        console.log("No Server Response" + err);
      } else {
        console.log("client_persons/assigned Failed" + err.response.toString);
      }
      navigate(LOGIN_URL);
    }
  }, [ansicht, auth.competences, axiosPrivate]);

  // const onCellEditRequest = useCallback(
  //   (event) => {
  //     const oldData = event.data;
  //     const field = event.colDef.field;
  //     const newData = { ...oldData };
  //     newData[field] = event.newValue;
  //     console.log('onCellEditRequest, updating ' + field + ' to ' + event.newValue);
  //     const tx = {
  //       update: [newData],
  //     };
  //     event.api.applyTransaction(tx);
  //     //console.log({ newData });

  //     //Axios call
  //     submitChange(newData);
  //   },
  //   [submitChange],
  // );

  // const onRowDragMove = useCallback(
  //   (event) => {
  //     var movingNode = event.node;
  //     var overNode = event.overNode;
  //     // find out what verbund group we are hovering over
  //     var groupVerbund;
  //     if (overNode.group) {
  //       // if over a group, we take the group key (which will be the
  //       // verbund_person_target as we are grouping by verbund_person_target)
  //       groupVerbund = overNode.key;
  //     } else {
  //       // if over a non-group, we take the unit_name directly
  //       groupVerbund = overNode.data.verbund_person_target;
  //     }
  //     var needToChangeParent = movingNode.data.verbund_person_target !== groupVerbund;
  //     if (needToChangeParent) {
  //       var movingData = movingNode.data;
  //       movingData.verbund_person_target = groupVerbund;
  //       gridRef.current.api.applyTransaction({
  //         update: [movingData],
  //       });
  //       //Axios call
  //       submitChange(movingData);
  //       gridRef.current.api.clearFocusedCell();
  //     }
  //   },
  //   [submitChange],
  // );

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agTotalAndFilteredRowCountComponent", align: "left" },
        { statusPanel: "agTotalRowCountComponent", align: "center" },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    };
  }, []);

  const onGridReady = useCallback(
    async (params) => {
      //console.log('onGridReady');
      readData();
      gridRef.current.api = params.api;
      //setLockDown(process.env.REACT_APP_FINAL_LOCK);
    },
    [readData],
  );
  const onFirstDataRendered = useCallback(() => {
    //console.log('onFirstDataRendered');
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  return (
    <div style={containerStyle}>
      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <div style={{ flexGrow: "1", height: "calc(100vh - 150px" }}>
          {/* height: 100% is not enough as AGGrid don't know the wrapper's height */}
          <div id="myGroupDataGrid" className="ag-theme-alpine" style={gridStyle}>
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              getRowId={getRowId}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              autoGroupColumnDef={autoGroupColumnDef}
              processPivotResultColDef={processPivotResultColDef}
              groupDisplayType={"multipleColumns"}
              suppressAggFuncInHeader={true}
              //showOpenedGroup={true}
              rowDragEntireRow={true}
              rowDragMultiRow={true}
              rowSelection={"multiple"}
              rowMultiSelectWithClick={true}
              onGridReady={onGridReady}
              onFirstDataRendered={onFirstDataRendered}
              //enableCellChangeFlash={true}
              animateRows={true}
              stopEditingWhenCellsLoseFocus={true}
              readOnlyEdit={true}
              //onCellEditRequest={onCellEditRequest}
              //onRowDragMove={onRowDragMove}
              statusBar={statusBar}
              enableRowGroup={true}
              enablePivot={true}
              onGri
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupDataGrid;
