const ROLES_LIST = require("./components/auths/roles_list");
const CLIENT_LIST = require("./utilities/client_list");
const CLIENT_TYP = require("./utilities/client_type_list");

const einheitbildung_fk = [
  {
    title: "Einheiten Bilden",
    path: "/einheitbildung",
    roles: [ROLES_LIST.Consultant, ROLES_LIST.Supervisor, ROLES_LIST.Admin, ROLES_LIST.Auditor],
  },
  {
    title: "Einheiten Prüfen",
    path: "/einheitpruefung",
    roles: [ROLES_LIST.Consultant, ROLES_LIST.Supervisor, ROLES_LIST.Admin, ROLES_LIST.Auditor],
  },
];

const verbundbildung_pk = [
  {
    title: "Einheiten Sichten",
    path: "/einheitsichten",
    roles: [ROLES_LIST.Consultant, ROLES_LIST.Supervisor, ROLES_LIST.Admin, ROLES_LIST.Auditor],
  },
  {
    title: "Einheiten Bearbeiten",
    path: "/einheitbearbeiten",
    roles: [ROLES_LIST.Consultant, ROLES_LIST.Supervisor, ROLES_LIST.Admin, ROLES_LIST.Auditor],
  },
  {
    title: "Bearbeitungsfortschritt",
    path: "/einheitprogress",
    roles: [ROLES_LIST.Consultant, ROLES_LIST.Supervisor, ROLES_LIST.Admin, ROLES_LIST.Auditor],
  },
];

const segmentpruefung = {
  title: "Segmentprüfung",
  path: "/segment",
  roles: [ROLES_LIST.Consultant, ROLES_LIST.Supervisor, ROLES_LIST.Admin, ROLES_LIST.Auditor],
};

const segmentpruefungFortschritt = {
  title: "Segmentprüfung Bearbeitungsfortschritt",
  path: "/segmentProgress",
  roles: [ROLES_LIST.Consultant, ROLES_LIST.Supervisor, ROLES_LIST.Admin, ROLES_LIST.Auditor],
};

const abgabelist = {
  title: "Abgabeliste",
  path: "/abgabe",
  roles: [ROLES_LIST.Consultant, ROLES_LIST.Supervisor, ROLES_LIST.Admin, ROLES_LIST.Auditor],
};

const oezuordnung = {
  title: "OE zuordnen",
  path: "/zuordnen",
  roles: [ROLES_LIST.Supervisor, ROLES_LIST.Admin, ROLES_LIST.Auditor],
};
const oeaufnahme = {
  title: "OE aufnehmen",
  path: "/aufnahme",
  roles: [ROLES_LIST.Consultant, ROLES_LIST.Supervisor, ROLES_LIST.Admin, ROLES_LIST.Auditor],
};
const gesamtlist = {
  title: "Gesamtliste",
  path: "/gesamtbestand",
  roles: [ROLES_LIST.Consultant, ROLES_LIST.Supervisor, ROLES_LIST.Admin, ROLES_LIST.Auditor],
};

const abgabeFortschritt = {
  title: "Abgabe / Veto Bearbeitung Fortschritt",
  path: "/abgabeProgress",
  roles: [ROLES_LIST.Consultant, ROLES_LIST.Supervisor, ROLES_LIST.Admin, ROLES_LIST.Auditor],
};

const zuordnungFortschritt = {
  title: "OE Zuordnung Bearbeitung Fortschritt",
  path: "/zuordnungProgress",
  roles: [ROLES_LIST.Consultant, ROLES_LIST.Supervisor, ROLES_LIST.Admin, ROLES_LIST.Auditor],
};

const aufnahmeFortschritt = {
  title: "Aufnahme Bearbeitung Fortschritt",
  path: "/aufnahmeProgress",
  roles: [ROLES_LIST.Consultant, ROLES_LIST.Supervisor, ROLES_LIST.Admin, ROLES_LIST.Auditor],
};

const einheitbildung = {
  title: "Einheitbildung",
  roles: [ROLES_LIST.Consultant, ROLES_LIST.Supervisor, ROLES_LIST.Admin, ROLES_LIST.Auditor],
  children: process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK ? einheitbildung_fk : verbundbildung_pk,
};
const segmentpruefungPhase = {
  title: "Segmentprüfung",
  roles: [ROLES_LIST.Consultant, ROLES_LIST.Supervisor, ROLES_LIST.Admin, ROLES_LIST.Auditor],
  children: [segmentpruefung, segmentpruefungFortschritt],
};

const abgabePhase = {
  title: "Kundenabgabe",
  roles: [ROLES_LIST.Consultant, ROLES_LIST.Supervisor, ROLES_LIST.Admin, ROLES_LIST.Auditor],
  children: [abgabelist, abgabeFortschritt],
};

const zuordnungPhase = {
  title: "Kundenaufnahme",
  roles: [ROLES_LIST.Supervisor, ROLES_LIST.Admin, ROLES_LIST.Auditor],
  children:
    process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK
      ? [segmentpruefung, abgabelist, oezuordnung, zuordnungFortschritt]
      : process.env.REACT_APP_CLIENT === CLIENT_LIST.HASPA
        ? [oezuordnung]
        : [oezuordnung, gesamtlist, zuordnungFortschritt],
};

const aufnahmePhase = {
  title: "Kunden-Berater Aufnahme",
  roles: [ROLES_LIST.Consultant, ROLES_LIST.Supervisor, ROLES_LIST.Admin, ROLES_LIST.Auditor],
  children: [oeaufnahme, gesamtlist, aufnahmeFortschritt],
};

const statistic = {
  title: "Statistics",
  roles: [ROLES_LIST.Supervisor, ROLES_LIST.Admin, ROLES_LIST.Auditor],
  children: [
    {
      title: "Auswertung Berater",
      path: "/stats",
      roles: [ROLES_LIST.Supervisor, ROLES_LIST.Admin, ROLES_LIST.Auditor],
    },
  ],
};

const admin_common = {
  title: "Administration Allgemein",
  roles: [ROLES_LIST.Admin],
  children: [
    {
      title: "Admin Kontrollpanel",
      path: "/admin_control",
      roles: [ROLES_LIST.Admin],
    },
    {
      title: "Segment - Hochladen",
      path: "/upload_segment_list",
      roles: [ROLES_LIST.Admin],
    },
    {
      title: "Segment - Überprüfen",
      path: "/segment_list",
      roles: [ROLES_LIST.Admin],
    },
    {
      title: "Kompetenz - Hochladen",
      path: "/upload_competence",
      roles: [ROLES_LIST.Admin],
    },
    {
      title: "Kompetenz - Bearbeiten",
      path: "/competence",
      roles: [ROLES_LIST.Admin],
    },
    {
      title: "Ziel Berater - Überschreiben",
      path: "/overwrite_zielberater",
      roles: [ROLES_LIST.Admin],
    },
    {
      title: "Ziel Berater - Hinzufügen",
      path: "/upload_zielberater",
      roles: [ROLES_LIST.Admin],
    },
    {
      title: "Ziel Berater - Bearbeiten",
      path: "/zielberaterliste",
      roles: [ROLES_LIST.Admin],
    },
    {
      title: "(Alt)Berater - Hinzufügen",
      path: "/upload",
      roles: [ROLES_LIST.Admin],
    },
    {
      title: "(Alt)Berater - Bearbeiten",
      path: "/beraterliste",
      roles: [ROLES_LIST.Admin],
    },
  ],
};
const admin_verbund = {
  title: "Administration Verbundkundendaten",
  roles: [ROLES_LIST.Admin],
  children: [
    {
      title: "Verbundliste - Hochladen",
      path: "/upload_bundle",
      roles: [ROLES_LIST.Admin],
    },
    {
      title: "Verbundliste - Überprüfen",
      path: "/bundleliste",
      roles: [ROLES_LIST.Admin],
    },
  ],
};

const kundenliste_upload_title =
  process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK ? "VB-Kundenliste - Hochladen" : "SK-Kundenliste - Hochladen";
const kundenliste_check_title =
  process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK ? "VB-Kundenliste - Überprüfen" : "SK-Kundenliste - Überprüfen";
const kundenliste_upload_path = process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK ? "upload_clientperson" : "upload_client";
const kundenliste_check_path = process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK ? "clientpersonliste" : "clientliste";

const admin_einheitbildung = {
  title: "Administration Einheitbildung Kundendaten",
  roles: [ROLES_LIST.Admin],
  children: [
    {
      title: kundenliste_upload_title,
      path: kundenliste_upload_path,
      roles: [ROLES_LIST.Admin],
    },
    {
      title: kundenliste_check_title,
      path: kundenliste_check_path,
      roles: [ROLES_LIST.Admin],
    },
  ],
};

let sitemap;
switch (process.env.REACT_APP_CLIENT) {
  case CLIENT_LIST.SKDU:
    sitemap = [einheitbildung, admin_common, admin_einheitbildung];
    break;
  case CLIENT_LIST.SKSP:
    sitemap = [zuordnungPhase, statistic, admin_common, admin_verbund];
    break;
  case CLIENT_LIST.HSK:
  case CLIENT_LIST.SKPDH:
  case CLIENT_LIST.SKHL:
    sitemap = [aufnahmePhase, statistic, admin_common, admin_verbund];
    break;
  case CLIENT_LIST.HASPA:
    sitemap = [zuordnungPhase, statistic, admin_common, admin_verbund]; // abgabePhase,
    break;
  default:
    sitemap = [
      einheitbildung,
      segmentpruefungPhase,
      abgabePhase,
      zuordnungPhase,
      aufnahmePhase,
      statistic,
      admin_common,
      admin_verbund,
      admin_einheitbildung,
    ];
}

export default sitemap;
