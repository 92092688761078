import React from "react";
import { Drawer as MuiDrawer, List, useTheme, Box, IconButton } from "@mui/material";
//import { Link } from 'react-router-dom';
import DrawerGroup from "./DrawerGroup";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import sitemap from "../../sitemap";
import { isSitemapGroup } from "../../utilities/sitemap.utils";
import DrawerItem from "./DrawerItem";
export default function Drawer({ open, onClose, first_login }) {
  const theme = useTheme();

  return (
    <>
      <MuiDrawer
        open={open}
        onClose={onClose}
        anchor="left"
        variant="persistent"
        sx={{
          width: 240,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 240,
            boxSizing: "border-box",
          },
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="flex-end" padding={theme.spacing(0, 1)}>
          <IconButton onClick={onClose}>{open ? <ChevronLeft /> : <ChevronRight />}</IconButton>
        </Box>

        <List sx={{ mt: 1 }}>
          {first_login ? (
            <>
              <p></p>
              <p></p>
              {/* <Link to="/consultant">Initialpasswort ändern</Link> */}
            </>
          ) : (
            sitemap.map((item) =>
              isSitemapGroup(item) ? (
                <DrawerGroup item={item} key={item.title} onClose={onClose} />
              ) : (
                <DrawerItem item={item} key={item.title} nested={false} onClose={onClose} />
              ),
            )
          )}
        </List>
      </MuiDrawer>
    </>
  );
}
