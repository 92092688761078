import React, { useState } from "react";
import { Collapse, List, ListItemButton, ListItemText } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import DrawerItem from "./DrawerItem";
import useAuth from "../auths/hooks/useAuth";

export default function DrawerGroup({ item, onClose }) {
  const { auth } = useAuth();

  const [isOpen, setIsOpen] = useState(true);
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  if (item.roles && !item.roles.includes(auth.role)) {
    return null;
  }

  return item.children ? (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemText primary={item.title} />
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {item.children.map((child) => (
            <DrawerItem item={child} nested={true} onClose={onClose} key={child.title} />
          ))}
        </List>
      </Collapse>
    </>
  ) : null;
}
