import { createTheme } from "@mui/material/styles";
//import { deDE } from '@mui/material/locale';

const zebBlue = "#268ee0";

const createCustomTheme = () => {
  return createTheme(
    {
      props: { MuiSkeleton: { animation: false } },
      palette: {
        primary: {
          main: Color(zebBlue, 0), // zeb blue
          dark: Color(zebBlue, -0.3),
          light: Color(zebBlue, 0.25),
          contrastText: "#ffffff",
        },
        secondary: {
          main: "#666666", // zeb Grey
          dark: "#3c3c3c",
          light: "#949494",
          contrastText: "#ffffff",
        },
        supplementary: {
          light: "#ffffff",
          main: "#fafafa",
          dark: "#f0f0f0",
        },
        text: { primary: "#444444" },
        error: {
          main: "#d30000", // Zeb red
        },
        warning: {
          main: "#ffc900", // yellow
        },
        info: {
          main: "#00acd3", // Hellblue
        },
        success: {
          main: "#009864", // darkgreen
        },
        action: {
          main: "#009864", // darkgreen
        },
        disabled: {
          main: "#666666", // zeb Grey
        },
        zebBlue: { main: "#268ee0" },
        darkGrey: { main: "#666666" },
        mittelGrey: { main: "#e3e3e3" },
        hellGrey: { main: "#f0f0f0" },
        Grey3: { main: "#333333" },
        Grey4: { main: "#444444" },
        GreyC: { main: "#cccccc" },
        GreyF6: { main: "#f6f6f6" },
        green: { main: "#679023" },
        weiss: { main: "#ffffff" },
        schwarz: { main: "#000000" },
        yellow: { main: "#ffc900" },
        orange: { main: "#ff8f00" },
        lila: { main: "#9b348e" },
        blue: { main: "#2c57d2" },
        hellblue: { main: "#00acd3" },
        darkgreen: { main: "#009864" },
        hellgreen: { main: "#8cbe22" },
        FIRST_PRIMARY: { main: "#268ee0" },
        SECOND_PRIMARY: { main: "#666666" },
        ERROR: { main: "#268ee0" },
        SUCCESS: { main: "#4caf50" },
        WARN: { main: "#ffeb3b" },
        INFO: { main: "#304ffe" },
        background: {
          default: "#f0f0f0",
        },
      },
    },
    //deDE,
  );
};

function Color(hex, brightness) {
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, "");
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  brightness = brightness || 0;

  // convert to decimal and change brightness
  let rgb = "#";
  let c;
  let i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);

    if (brightness >= 0) {
      // fade to white
      c = Math.round(Math.min(Math.max(0, brightness * (255 - c) + c), 255)).toString(16);
    } else {
      // fade to black
      c = Math.round(Math.min(Math.max(0, -brightness * (0 - c) + c), 255)).toString(16);
    }

    rgb += ("00" + c).substr(c.length);
  }

  return rgb;
}

export default createCustomTheme;
