import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import React, { useCallback, useEffect, useState, useRef, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../auths/hooks/useAxiosPrivate";
import useAuth from "../auths/hooks/useAuth";
import CLIENT_LIST from "../../utilities/client_list";
import ROLES_LIST from "../auths/roles_list";

import { Button, ButtonGroup, Grid, Autocomplete, TextField, Popover, Typography, List, ListItem, ListItemText } from "@mui/material";
import FormControl from "@mui/material/FormControl";

const LOGIN_URL = "/login";
const SEPARATOR = "_V_";

const renderEuro = (p) => {
  const amount = p.value;
  return (
    Math.round(amount)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €"
  );
};

const mostFrequentValueGetter = (params) => {
  const values = params.values;
  const frequencyMap = {};
  let maxFrequency = 0;
  let mostFrequentValue = null;

  values.forEach((value) => {
    const frequency = (frequencyMap[value] || 0) + 1;
    frequencyMap[value] = frequency;
    if (frequency > maxFrequency) {
      maxFrequency = frequency;
      mostFrequentValue = value;
    }
  });

  return mostFrequentValue;
};

const leftColumns = [
  {
    rowDrag: true,
    maxWidth: 80,
    resizable: true,
    suppressHeaderMenuButton: true,
    filter: false,
    rowDragText: (params, dragItemCount) => {
      if (dragItemCount > 1) {
        return dragItemCount + " Kunden";
      }
      return params.rowNode.data.unit_name + "__:__" + params.rowNode.data.person_name;
    },
  },
  {
    colId: "checkbox",
    maxWidth: 50,
    checkboxSelection: true,
    suppressHeaderMenuButton: true,
    //headerCheckboxSelection: true,
    filter: false,
  },
  {
    headerName: "Details Aufblättern",
    children: [
      {
        field: "verbund_nr",
        //headerName: 'Betreuung Einheit',
        rowGroup: true,
        //aggFunc: 'first',
        hide: true,
        suppressSizeToFit: true,
        sort: "desc",
      },
      {
        field: "unit_name",
        headerName: "Bestehende Einheit Name",
        minWidth: 150,
        //rowGroup: true,
        //aggFunc: 'first',
        //hide: true,
        sort: "desc",
        //columnGroupShow: 'open',
      },
      {
        field: "person_name",
        headerName: "Einzelkunde Name",
        minWidth: 150,
        //sort: 'asc',
      },
      {
        field: "person_nr",
        minWidth: 100,
        headerName: "Person Nr.",
        //columnGroupShow: 'open',
        //aggFunc: 'first'
      },
      // {
      //   field: 'risk_unit_nr',
      //   headerName: 'Einheiten-Nr Risikoeinheit',
      //   //rowGroup: true,
      //   //sortable: true,
      //   //hide: true,
      // },
      // {
      //   field: 'risk_unit_contact_pers_nr',
      //   headerName: 'Personennummer Ansprechpartner Risikoeinheit',
      //   columnGroupShow: 'open',
      // },
      {
        field: "consultant_id",
        headerName: "Kundenberater",
        filter: "agSetColumnFilter",
        aggFunc: mostFrequentValueGetter,
        editable: true,
      },
      {
        field: "risk_volume",
        headerName: process.env.REACT_APP_CLIENT === CLIENT_LIST.EWD ? "Aktivvolumen" : "Obligo",
        aggFunc: "sum",
        columnGroupShow: "open",
        cellRenderer: renderEuro,
        filter: "agNumberColumnFilter",
      },
      {
        field: "passive_volume",
        headerName: "Passivvolumen",
        aggFunc: "sum",
        columnGroupShow: "open",
        cellRenderer: renderEuro,
        filter: "agNumberColumnFilter",
      },
      {
        field: "sales_fk",
        headerName: "Umsatz",
        columnGroupShow: "open",
        cellRenderer: renderEuro,
        aggFunc: "sum",
        filter: "agNumberColumnFilter",
      },
      {
        field: "branch",
        headerName: "Zweigstelle",
        filter: "agSetColumnFilter",
        columnGroupShow: "open",
        aggFunc: mostFrequentValueGetter,
      },
      {
        field: "plz",
        headerName: "Postleitzahl",
        filter: "agSetColumnFilter",
        aggFunc: mostFrequentValueGetter,
        columnGroupShow: "open",
      },
      {
        field: "eco_branch",
        headerName: "Wirtschaftszweig",
        filter: "agSetColumnFilter",
        aggFunc: mostFrequentValueGetter,
        columnGroupShow: "open",
      },
      {
        field: "right_form",
        filter: "agSetColumnFilter",
        headerName: "Rechtsformausprägung",
        aggFunc: mostFrequentValueGetter,
        columnGroupShow: "open",
      },
      /*       {
        field: 'risk_consultant_id',
        headerName: 'Kundenberater_RiE',
        filter: 'agSetColumnFilter',
        aggFunc: mostFrequentValueGetter,
        columnGroupShow: 'open',
      },
      {
        field: 'segment',
        headerName: 'Segment',
        filter: 'agSetColumnFilter',
        aggFunc: mostFrequentValueGetter,
        columnGroupShow: 'open',
      },
      {
        field: 'risk_segment',
        headerName: 'Kundensegment_RiE',
        filter: 'agSetColumnFilter',
        columnGroupShow: 'open',
        aggFunc: mostFrequentValueGetter,
      }, 
      {
        field: 'sales',
        aggFunc: 'sum',
        headerName: 'Jahresumsatz',
        cellRenderer: renderEuro,
        filter: 'agNumberColumnFilter',
        columnGroupShow: 'open',
      },

      {
        field: 'sales_calc',
        headerName: 'Berechneter Habenumsatz',
        columnGroupShow: 'open',
        aggFunc: 'sum',
        cellRenderer: renderEuro,
        filter: 'agNumberColumnFilter',
      },
      {
        field: 'revenue',
        headerName: 'Umsatzerlöse',
        aggFunc: 'sum',
        cellRenderer: renderEuro,
        filter: 'agNumberColumnFilter',
        columnGroupShow: 'open',
      },
      */
    ],
  },
];

const rightColumns = [
  {
    rowDrag: true,
    maxWidth: 50,
    filter: false,
    suppressHeaderMenuButton: true,
    rowDragText: (params, dragItemCount) => {
      if (dragItemCount > 1) {
        return dragItemCount + " Kunden";
      }
      return params.rowNode.data.unit_name + "__:__" + params.rowNode.data.person_name;
    },
  },
  {
    headerName: "Details Aufblättern",
    children: [
      // {
      //   field: 'verbund_nr',
      //   //headerName: 'Betreuung Einheit',
      //   //rowGroup: true,
      //   //aggFunc: 'first',
      //   //hide: true,
      //   suppressSizeToFit: true,
      //   sort: 'desc',
      // },
      {
        field: "unit_name",
        headerName: "Bestehende Einheit Name",
        //rowGroup: true,
        //aggFunc: 'first',
        //hide: true,
        sort: "asc",
      },
      {
        field: "person_name",
        headerName: "Einzelkunden",
        minWidth: 200,
        sort: "asc",
      },
      {
        field: "person_nr",
        headerName: "Person Nr.",
        //aggFunc: 'first'
      },
      {
        field: "consultant_id",
        headerName: "Kundenberater",
        filter: "agSetColumnFilter",
        aggFunc: mostFrequentValueGetter,
        editable: true,
      },
      {
        field: "risk_volume",
        headerName: "Risk Volumen",
        aggFunc: "sum",
        columnGroupShow: "open",
        cellRenderer: renderEuro,
        filter: "agNumberColumnFilter",
      },
      {
        field: "passive_volume",
        headerName: "Passiv Volumen",
        aggFunc: "sum",
        columnGroupShow: "open",
        cellRenderer: renderEuro,
        filter: "agNumberColumnFilter",
      },
      {
        field: "sales_fk",
        headerName: "Umsatz FK*",
        columnGroupShow: "open",
        cellRenderer: renderEuro,
        aggFunc: "sum",
        filter: "agNumberColumnFilter",
      },
      {
        field: "branch",
        headerName: "Zweigstelle",
        filter: "agSetColumnFilter",
        columnGroupShow: "open",
        aggFunc: mostFrequentValueGetter,
      },
      {
        field: "plz",
        headerName: "Postleitzahl",
        filter: "agSetColumnFilter",
        aggFunc: mostFrequentValueGetter,
        columnGroupShow: "open",
      },
      {
        field: "eco_branch",
        headerName: "Wirtschaftszweig",
        filter: "agSetColumnFilter",
        aggFunc: mostFrequentValueGetter,
        columnGroupShow: "open",
      },
      {
        field: "right_form",
        filter: "agSetColumnFilter",
        headerName: "Rechtsformausprägung",
        aggFunc: mostFrequentValueGetter,
        columnGroupShow: "open",
      },
      /*       {
        field: 'risk_consultant_id',
        headerName: 'Kundenberater_RiE',
        filter: 'agSetColumnFilter',
        aggFunc: mostFrequentValueGetter,
        columnGroupShow: 'open',
      },
      {
        field: 'segment',
        headerName: 'Segment',
        filter: 'agSetColumnFilter',
        aggFunc: mostFrequentValueGetter,
        columnGroupShow: 'open',
      },
      {
        field: 'risk_segment',
        headerName: 'Kundensegment_RiE',
        filter: 'agSetColumnFilter',
        columnGroupShow: 'open',
        aggFunc: mostFrequentValueGetter,
      }, 
      {
        field: 'sales',
        aggFunc: 'sum',
        headerName: 'Jahresumsatz',
        cellRenderer: renderEuro,
        filter: 'agNumberColumnFilter',
        columnGroupShow: 'open',
      },

      {
        field: 'sales_calc',
        headerName: 'Berechneter Habenumsatz',
        columnGroupShow: 'open',
        aggFunc: 'sum',
        cellRenderer: renderEuro,
        filter: 'agNumberColumnFilter',
      },
      {
        field: 'revenue',
        headerName: 'Umsatzerlöse',
        aggFunc: 'sum',
        cellRenderer: renderEuro,
        filter: 'agNumberColumnFilter',
        columnGroupShow: 'open',
      },
      */
    ],
  },
];

const defaultColDef = {
  flex: 1,
  minWidth: 130,
  filter: true,
  sortable: true,
  floatingFilter: true,
  resizable: true,
  wrapHeaderText: true,
  autoHeaderHeight: true,
};
const autoGroupColumnDef = {
  headerName: "FK-Einheit (Vorschlag)",
  minWidth: 200,
  maxWidth: 500,
  wrapHeaderText: true,
  autoHeaderHeight: true,
};

const GridtoGrid = () => {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const [leftApi, setLeftApi] = useState(null);
  //const [leftColumnApi, setLeftColumnApi] = useState(null);
  const [rightApi, setRightApi] = useState(null);
  const [rawData, setRawData] = useState([]);
  const [leftRowData, setLeftRowData] = useState(null);
  const [rightRowData, setRightRowData] = useState([]);
  //const [radioChecked, setRadioChecked] = useState(0);
  //const [checkBoxSelected, setCheckBoxSelected] = useState(true);
  const assignEinheitId = useRef("");
  const [selectEinheitId, setSelectEinheitId] = useState("");
  const [inputEinheitId, setInputEinheitId] = useState("");
  const [einheitIds, setEinheitIds] = useState([]);
  //const [contactPerson, setContactPerson] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [einheitName, setEinheitName] = useState("");
  const [einheitNameWithNr, setEinheitNameWithNr] = useState(""); // replace with default value of valueA
  const [personNrList, setPersonNrList] = useState([]); // replace with default value of valueB
  const [contactPersonNr, setContactPersonNr] = useState(""); // replace with default value of valueB
  const [collapsed, setCollapsed] = useState(true);
  const [showAllClients, setShowAllClients] = useState(false);
  //const inputRef = useRef(null);

  const handlePopoverOpen = (event) => {
    //console.log(event);
    setAnchorEl(event.target);
    //console.log({ contactPersonNr });
    //setAnchorEl(null); // set anchorEl to null to make popover floating
  };

  const handlePopOverInputChange = (event) => {
    setEinheitNameWithNr(event.target.value + SEPARATOR + contactPersonNr);
    setEinheitName(event.target.value);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setEinheitName("");
  };

  const handleConfirm = async () => {
    // add your axios call here with new valueA
    try {
      //console.log('Assign contact person to verbund with new name: ' + einheitNameWithNr);
      console.log("call /client_persons/assign");
      await axiosPrivate.post("/client_persons/assign", {
        client_person_id_list: personNrList,
        new_name: einheitNameWithNr,
        person_nr: contactPersonNr,
      });
      loadGrids();
      setAnchorEl(null);
      setPersonNrList([]);
      setEinheitNameWithNr("");
      setContactPersonNr("");
      handlePopoverClose();
    } catch (err) {
      if (!err?.response) {
        console.log("No Server Response for /client_persons/assign" + err);
      } else {
        console.log("Failed /client_persons/assign: " + err.response);
      }
    }
  };
  const findOtherConsultants = async (client_person_id_list) => {
    try {
      //console.log('findOtherConsultants: ' + client_person_id_list);
      const response = await axiosPrivate.post("/client_persons/otherConsultants", {
        client_person_id_list,
      });
      const otherConsultants = response.data;
      console.log("called /client_persons/otherConsultants");
      return otherConsultants;
    } catch (err) {
      if (!err?.response) {
        console.log("No Server Response for /client_persons/otherConsultants" + err);
      } else {
        console.log("Failed /client_persons/otherConsultants: " + JSON.stringify(err.response));
      }
      throw err;
    }
  };
  const getEinheitNameWithNr = async (client_person_id_list) => {
    try {
      //console.log('getEinheitNameWithNr with ' + client_person_id_list);
      console.log("call /client_persons/newEinheitName");
      const response = await axiosPrivate.post("/client_persons/newEinheitName", {
        client_person_id_list,
      });
      const new_name = response.data;
      return new_name;
    } catch (err) {
      if (!err?.response) {
        console.log("No Server Response for /client_persons/getEinheitNameWithNr" + err);
      } else {
        console.log("Failed /client_persons/getEinheitNameWithNr: " + err.response);
      }
      throw err;
    }
  };
  const assignEinheitTarget = useCallback(
    //Axios Call
    async (event) => {
      try {
        console.log("assignEinheitTarget");

        // const person_name = selectedData[0].person_name;
        // console.log({ person_nr });
        // console.log({ person_name });

        // construct the person ID list
        let client_person_id_list = [];
        rightApi.forEachNode((node) => {
          const data = node.data;
          //console.log(data);
          var id = data.person_nr;
          client_person_id_list.push(id);
          //rightApi.setDeltaSort(true);
        });
        const uniqueSortedItems = [...new Set(client_person_id_list)].sort();

        //console.log({ uniqueSortedItems });
        setPersonNrList(uniqueSortedItems);

        //check the competence
        //Axios call if emptylist returned continue otherwise return with alert
        const otherConsultants = await findOtherConsultants(uniqueSortedItems);
        if (otherConsultants.length > 0) {
          alert("Bitte Berater: " + JSON.stringify(otherConsultants) + "kontaktieren, um die Bearbeitung fortzusetzen.");
          return;
        }

        //fetch the selected contact person
        var person_nr = "";
        var new_name = "";
        // fetch the selected contact person name and einheitname from existing data
        let selectedData = rightApi.getSelectedRows();
        if (selectedData !== undefined && selectedData.length > 0) {
          person_nr = selectedData[0].person_nr;
          //console.log('set contact person based on selection: ' + person_nr);
          new_name = selectedData[0].verbund_person_target;
          //console.log('set einheitsname based on existing vorschlag:' + new_name);
        }
        //calculate the einheitsname if it doesn't exist yet.
        if (new_name === "") {
          new_name = await getEinheitNameWithNr(uniqueSortedItems);
        }

        setEinheitNameWithNr(new_name);
        //console.log({ new_name });

        selectContactPersonAndSetEinheit(new_name, person_nr);

        handlePopoverOpen(event);
      } catch (err) {
        console.log("assignEinheitTarget failed:" + err);
      }
    },
    [axiosPrivate, rightApi, rightRowData, leftApi, contactPersonNr, einheitNameWithNr],
  );

  const handleAssignEinheitSelection = (event, newValue) => {
    assignEinheitId.current = newValue;
    //console.log({ assignEinheitId });--
    setSelectEinheitId(newValue);
    //console.log({ selectEinheitId });
  };

  const selectContactPersonAndSetEinheit = (assign_einheit_id, contact_person_nr) => {
    // Find the row node that corresponds to the pre-selected row
    // assume that the assign_einheit_id has the pattern of some_string_V12345
    const str = assign_einheit_id; //"some_string_V12345";
    const parts = str.split(SEPARATOR); // split the string into an array at the "_V" substring
    const numbers = parts.pop(); // retrieve the last element of the array, which should be the numbers after "_V"
    const einheitname = parts.pop();

    if (contact_person_nr === undefined || contact_person_nr === "") {
      contact_person_nr = numbers;
      //console.log('contact person is not selected, default chosen: ' + numbers);
    }
    // Find the first row that matches the person_name
    rightApi.forEachNode((node) => {
      //console.log(node.data);
      if (node.data["person_nr"] === contact_person_nr) {
        node.setSelected(true);
        //console.log('select contact person found: ' + contact_person_nr);
        return;
      }
    });
    //console.log({ contact_person_nr });
    setContactPersonNr(contact_person_nr);
    setEinheitName(einheitname);
    //console.log({ einheitName });
  };

  const loadClientsInEinheit = useCallback(async () => {
    const assign_einheit_id = assignEinheitId.current;
    if (!assign_einheit_id) {
      console.log("no Einheit selected");
      return;
    } else {
      console.log("loadClientsInEinheit with " + assign_einheit_id);
    }
    try {
      await axiosPrivate
        //.get('client_persons/clients/' + encodeURIComponent(assign_einheit_id))
        .post("client_persons/clients", {
          verbund_person_target: assign_einheit_id,
        })
        .then((res) => res.data)
        .then((data) => {
          setRightRowData([...data]);
          selectContactPersonAndSetEinheit(assign_einheit_id);
        });
    } catch (err) {
      if (!err?.response) {
        console.log("No Server Response for client_persons/clients/" + assign_einheit_id + err);
      } else {
        console.log("readConsultantIds Failed for  client_persons/clients with " + assign_einheit_id + err.response);
      }
    }
  }, [rightApi]);

  const readEinheitIds = useCallback(async () => {
    try {
      await axiosPrivate
        .get("client_persons/bundles")
        .then((res) => res.data)
        .then((data) => {
          setEinheitIds(data);
        });
    } catch (err) {
      if (!err?.response) {
        console.log("No Server Response for client_persons/bundles: " + err);
      } else {
        console.log("readConsultantIds Failed for client_persons/bundles: " + err.response.toString);
      }
    }
  }, [axiosPrivate]);

  // const onRightSelectionChanged = useCallback(
  //   (side) => {
  //     if (side === 1) {
  //       const selectedRows = rightApi.getSelectedRows();
  //       const contact = selectedRows.length === 1 ? selectedRows[0].person_name : '';
  //       setContactPerson(contact);
  //     }
  //   },
  //   [rightApi],
  // );

  const removeEinheitTarget = useCallback(
    //Axios Call
    async () => {
      //fetch the Einheittarget from dropdown list
      const verbund_person_target = assignEinheitId.current;
      if (!verbund_person_target) {
        console.log("no Einheit selected");
        return;
      }
      // } else {
      //   console.log('removeEinheitTarget with: ' + verbund_person_target);
      // }

      // construct the person ID list
      let client_person_id_list = [];
      rightApi.forEachNode((node) => {
        const data = node.data;
        //console.log(data);
        var id = data.person_nr;
        client_person_id_list.push(id);
        //rightApi.setDeltaSort(true);
      });

      //console.log({ client_person_id_list });

      try {
        //console.log('Remove Einheit target: ' + verbund_person_target + ' for current list on the right.');
        console.log("call /client_persons/remove");
        const response = await axiosPrivate.post("/client_persons/remove", {
          client_person_id_list,
          verbund_person_target,
        });
        const personList = response.data;
        if (personList.length > 0) {
          alert("Bitte Berater: " + JSON.stringify(personList) + "kontaktieren, um die Bearbeitung fortzusetzen.");
        }
        loadGrids();
      } catch (err) {
        if (!err?.response) {
          console.log("No Server Response for /client_persons/remove" + err);
        } else {
          console.log("/client_persons/remove faile: " + err.response);
        }
      }
    },
    [axiosPrivate, rightApi, rightRowData, leftApi],
  );

  const batchAssignNoEinheit = useCallback(async () => {
    let selectedData = leftApi.getSelectedRows();

    if (!selectedData) {
      console.log("no data selected to process");
      return;
    }
    let client_person_id_list = [];
    if (selectedData.length > 0) {
      console.log("batchAssignNoEinheit for " + selectedData.length + " clients.");
      for (const item of selectedData) {
        var id = item.person_nr;
        //console.log({ id });
        client_person_id_list.push(id);
      }
      //console.log({ client_person_id_list });

      try {
        await axiosPrivate.post("client_persons/notassigned", {
          client_person_id_list,
        });
      } catch (err) {
        if (!err?.response) {
          console.log("No Server Response for put client_persons/notassigned" + err);
        } else {
          console.log("batchAssignNoEinheit Failed " + err.response.toString);
        }
      }
    }
    loadGrids();
  }, [leftApi, axiosPrivate]);

  const loadGrids = useCallback(async () => {
    //reading data
    console.log("read data and rawData.length: " + rawData.length);
    //if (!rawData.length) {
    try {
      setRightRowData([]);
      readEinheitIds();
      await axiosPrivate
        .get("client_persons/notassigned", { timeout: 600000 })
        .then((res) => res.data)
        .then((data) => {
          console.log("read data successful");
          let filteredData = data;
          setRawData(filteredData);
          setLeftRowData([...filteredData]);
          if (leftApi) {
            leftApi.deselectAll();
          }
        });
    } catch (err) {
      if (!err?.response) {
        console.log("No Server Response for client_persons/notassigned: " + err);
      } else {
        console.log("ReadData Failed by loadGrids " + err);
      }
      navigate(LOGIN_URL);
    }
    //}
  }, [rawData, auth.competences, axiosPrivate, leftApi, rightApi]);

  const loadAllClients = useCallback(async () => {
    //if (!rawData.length) {
    try {
      await axiosPrivate
        .get("client_persons/notassignedAll", { timeout: 600000 })
        .then((res) => res.data)
        .then((data) => {
          console.log("read complete data successful");
          let filteredData = data;
          setRawData(filteredData);
          setLeftRowData([...filteredData]);
          if (leftApi) {
            leftApi.deselectAll();
          }
        });
    } catch (err) {
      if (!err?.response) {
        console.log("No Server Response for client_persons/notassignedAll: " + err);
      } else {
        console.log("ReadData Failed by loadAllClients " + err);
      }
      navigate(LOGIN_URL);
    }
    //}
  }, [rawData, axiosPrivate, leftApi]);

  const loadMyClients = useCallback(async () => {
    //if (!rawData.length) {
    try {
      console.log("loadMyClients");
      await axiosPrivate
        .get("client_persons/notassigned", { timeout: 600000 })
        .then((res) => res.data)
        .then((data) => {
          console.log("read complete data successful");
          let filteredData = data;
          setRawData(filteredData);
          setLeftRowData([...filteredData]);
          if (leftApi) {
            leftApi.deselectAll();
          }
        });
    } catch (err) {
      if (!err?.response) {
        console.log("No Server Response for client_persons/notassigned: " + err);
      } else {
        console.log("ReadData Failed by loadMyClients " + err);
      }
      navigate(LOGIN_URL);
    }
    //}
  }, [rawData, axiosPrivate, leftApi]);

  const toggleLeftDate = useCallback(async () => {
    console.log("toggleLeftDate");
    if (showAllClients) {
      setShowAllClients(false);
      loadMyClients();
    } else {
      setShowAllClients(true);
      alert("Bitte einige Minuten warten, bis die Seite sich neu lädt.");
      loadAllClients();
    }
  }, [rawData, axiosPrivate, leftApi, showAllClients]);

  const getRowId = (params) => params.data._id; //person_nr + '_RID_' + params.data.risk_unit_nr;

  const addGridDropZone = (side, api) => {
    const dropApi = side === "Left" ? rightApi : leftApi;
    const dropZone = dropApi.getRowDropZoneParams({
      onDragStop: async function (params) {
        if (auth.role == ROLES_LIST.Auditor) {
          alert("Auditor darf keine Änderung vornehmen.");
          return;
        }
        const nodes = params.nodes;
        const nodedata = nodes.map(function (node) {
          return node.data;
        });

        if (side === "Right") {
          const client_person_id_list = nodedata.map(function (person) {
            return person.person_nr;
          });
          const first_row_data = nodedata[0];
          const verbund_person_target = first_row_data.verbund_person_target;
          const parts = verbund_person_target.split(SEPARATOR);
          const contact_person_nr = parts[1];
          //console.log({ verbund_person_target, contact_person_nr, client_person_id_list });

          const blockDrop = client_person_id_list.includes(contact_person_nr);
          if (blockDrop) {
            alert("Ansprechsperson darf nicht vom Betreuungseinheit gelöscht werden, bitte zuerst einen neuen wählen");
            return null;
          } else {
            api.applyTransaction({
              remove: nodedata,
            });
            try {
              //console.log('onDragStop side:' + side);
              const response = await axiosPrivate.post("/client_persons/remove", {
                client_person_id_list,
                verbund_person_target,
              });
              const mod_consultants = response.data;
              if (mod_consultants.length > 0) {
                alert("Bitte Berater " + JSON.stringify(mod_consultants) + " kontaktieren, um die Bearbeitung fortzusetzen.");
              }
            } catch (err) {
              if (!err?.response) {
                console.log("No Server Response for /client_persons/remove" + err);
              } else {
                console.log("onDragStopR2L Failed " + err.response);
              }
            }
          }
        } else {
          api.applyTransaction({
            remove: nodedata,
          });
        }
      },
    });
    api.addRowDropZone(dropZone);
  };
  useEffect(() => {
    if (rightApi && leftApi) {
      addGridDropZone("Right", rightApi);
      addGridDropZone("Left", leftApi);
    }
  }, [leftApi, rightApi]);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agTotalAndFilteredRowCountComponent", align: "left" },
        { statusPanel: "agTotalRowCountComponent", align: "center" },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    };
  }, []);

  const onGridReady = (params, side) => {
    console.log("onGridReady");
    if (side === 0) {
      setLeftApi(params.api);
      //setLeftColumnApi(params.columnApi);
    }

    if (side === 1) {
      setRightApi(params.api);
    }
    loadGrids();
  };
  const toggleCollapse = useCallback(() => {
    if (collapsed) {
      leftApi.expandAll();
      setCollapsed(false);
    } else {
      leftApi.collapseAll();
      setCollapsed(true);
    }
  });

  const clearFilter = useCallback(() => {
    // leftApi.clearFilter();
    leftApi.setFilterModel(null);
  }, [leftApi]);

  const resetGrids = useCallback(() => {
    console.log("resetGrids");
    loadGrids();
  }, [leftApi, rightApi]);
  const getTopToolBar = () => (
    <div className="example-toolbar panel panel-default">
      <div className="panel-body">
        {/* <input type="checkbox" id="toggleCheck" checked={checkBoxSelected} onChange={onCheckboxChange} />
        <label htmlFor="toggleCheck">Menge Bearbeitung</label> */}
        <button onClick={toggleCollapse}>{collapsed ? "Aufblättern" : "Ausblenden"}</button>
        {/* <button onClick={collapseAll}>Nur Einheiten zeigen</button> */}
        <button onClick={toggleLeftDate}>{showAllClients ? "Nur meine Kunden" : "Alle Kunden"}</button>
        {/* <button onClick={loadMyClients}>Nur meine Kunden</button> */}
        <button onClick={clearFilter}>Filter Zurücksetzen</button>
        {
          <span className="input-group-button">
            <button type="button" className="btn btn-default reset" style={{ marginLeft: "5px" }} onClick={resetGrids}>
              <i className="fas fa-redo" style={{ marginRight: "5px" }}></i>Neu laden
            </button>
          </span>
        }
      </div>
    </div>
  );

  const getEinheitButtons = () => (
    <div className="example-toolbar panel panel-default">
      <div className="panel-body">
        {/* <input type="checkbox" id="toggleCheck" checked={checkBoxSelected} onChange={onCheckboxChange} />
        <label htmlFor="toggleCheck">Menge Bearbeitung</label> */}
        <Button variant="contained" onClick={assignEinheitTarget} disabled={auth.role === ROLES_LIST.Auditor}>
          Einheit bilden
        </Button>
        <Button variant="contained" onClick={removeEinheitTarget} disabled={auth.role === ROLES_LIST.Auditor}>
          löschen
        </Button>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          style={{ width: "auto", height: "auto" }}
        >
          <div style={{ padding: "16px" }}>
            <Typography variant="h6" gutterBottom>
              Zu bildende Einheit überprüfen
            </Typography>
            <TextField
              label="Betreuungseinheit"
              defaultValue={einheitName}
              onChange={handlePopOverInputChange}
              autoFocus={true}
              style={{ width: "400px", marginTop: "1rem" }}
              InputProps={{
                style: { width: "100%" },
              }}
            />
            <Typography variant="body2" style={{ marginTop: "1rem" }}>
              {" "}
              Ausgewählter Verbundführer:{contactPersonNr}
            </Typography>
            <Typography variant="body2" style={{ marginTop: "1rem" }}>
              {" "}
              Insgesamt {personNrList.length} Kunden in der Einheit:{" "}
            </Typography>
            <List dense>
              {personNrList.map((item) => (
                <ListItem key={item}>
                  <ListItemText primary={item} />
                </ListItem>
              ))}
            </List>
            <br></br>
            <Button onClick={handleConfirm} disabled={auth.role === ROLES_LIST.Auditor}>
              Bestätigen
            </Button>
            <Button onClick={handlePopoverClose} disabled={auth.role === ROLES_LIST.Auditor}>
              Abbrechen
            </Button>
          </div>
        </Popover>
        {/* <span className="input-group-button">
          <button type="button" className="btn btn-default reset" style={{ marginLeft: '5px' }} onClick={onGridReady}>
            <i className="fas fa-redo" style={{ marginRight: '5px' }}></i>Neu laden
          </button>
        </span> */}
      </div>
    </div>
  );

  const submitChange = useCallback(
    async (item) => {
      //console.log('update client Person data for: ', item);
      console.log("update client Person data ");
      try {
        await axiosPrivate.post("/client_persons", item);
      } catch (err) {
        if (!err?.response) {
          console.log("No Server Response" + err);
        } else {
          console.log("SubmitChange Failed");
        }
      }
    },
    [axiosPrivate],
  );

  const onCellValueChanged = useCallback((event) => {
    //console.log(event);
    console.log("onCellValueChanged");
    if (auth.role == ROLES_LIST.Auditor) {
      alert("Auditor darf keine Änderung vornehmen.");
      return;
    }
    // make axios call to update the data in the backend
    const oldData = event.data;
    const field = event.colDef.field;
    const newData = { ...oldData };
    newData[field] = event.newValue;
    //console.log('onCellValueChanged, updating ' + field + ' to ' + event.newValue);
    const tx = {
      update: [newData],
    };
    event.api.applyTransaction(tx);
    //console.log({ newData });

    //Axios call
    submitChange(newData);
  }, []);

  const getGridWrapper = (id) => (
    <div className="panel panel-primary" style={{ marginRight: "10px", flex: id === 0 ? "60%" : "40%" }}>
      <div className="panel-heading">{id === 0 ? "Nicht zugeordnete Kunden" : "Kunden im aktuellen Einheit"}</div>
      <div className="panel-body" style={{ height: "calc(100vh - 180px" }}>
        <AgGridReact
          style={{ height: "100%;" }}
          suppressAggFuncInHeader={true}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          groupDisplayType={"multipleColumns"}
          groupSelectsChildren={true}
          groupSelectsFiltered={true}
          getRowId={getRowId}
          rowDragManaged={true}
          //onRowDragEnd={onRowDrag}
          //onDragStop={id === 0 ? onDragStopR2L : onDragStopL2R}
          animateRows={true}
          rowSelection={id === 0 ? "multiple" : "single"}
          rowDragMultiRow={true}
          suppressRowClickSelection={id === 0 ? true : false}
          //onSelectionChanged={() => onRightSelectionChanged(id)}
          suppressMoveWhenRowDragging={true}
          onCellValueChanged={onCellValueChanged}
          statusBar={statusBar}
          rowData={id === 0 ? leftRowData : rightRowData}
          columnDefs={id === 0 ? leftColumns : rightColumns}
          onGridReady={(params) => onGridReady(params, id)}
        ></AgGridReact>
      </div>
    </div>
  );
  return (
    <div className="top-container">
      <Grid container spacing={0}>
        <Grid item xs={7}>
          <Grid container spacing={0}>
            <Grid item xs={10} align="left">
              {getTopToolBar()}
            </Grid>
            <Grid item xs={2} align="right">
              <Button variant="contained" onClick={batchAssignNoEinheit} disabled={auth.role === ROLES_LIST.Auditor}>
                Keine Einheit
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <Grid container spacing={0}>
            <Grid item xs={5} align="center">
              {getEinheitButtons()}
            </Grid>
            <Grid item xs={7} align="right">
              <Grid container dir="row" justifyContent="flex-end">
                <Grid item>
                  <FormControl variant="standard" size="small" sx={{ minWidth: 120 }}>
                    <Autocomplete
                      autoComplete
                      includeInputInList
                      id="assign_einheit_selector"
                      options={einheitIds}
                      sx={{ width: 300 }}
                      value={selectEinheitId}
                      inputValue={inputEinheitId}
                      onChange={handleAssignEinheitSelection}
                      onInputChange={(event, newInputValue) => {
                        setInputEinheitId(newInputValue);
                      }}
                      renderInput={(params) => <TextField {...params} label="Einheit Eintippen" variant="standard" />}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <ButtonGroup>
                    <Button
                      id="loadClientsInEinheit"
                      variant="contained"
                      onClick={loadClientsInEinheit}
                      disabled={auth.role === ROLES_LIST.Auditor}
                    >
                      Laden
                    </Button>
                  </ButtonGroup>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className="grid-wrapper ag-theme-alpine">
        {getGridWrapper(0, "3")}
        {getGridWrapper(1, "1")}
      </div>
    </div>
  );
};
export default GridtoGrid;
