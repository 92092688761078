import React, { useCallback, useMemo, useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { ColumnsToolPanelModule, LicenseManager } from "ag-grid-enterprise";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ErrorIcon from "@mui/icons-material/Error";
import PanToolIcon from "@mui/icons-material/PanTool";
import HandshakeIcon from "@mui/icons-material/Handshake";
import useAxiosPrivate from "../auths/hooks/useAxiosPrivate";
import { Button, ButtonGroup, Grid, Autocomplete, TextField } from "@mui/material";
import useAuth from "../auths/hooks/useAuth";
//import { grey } from '@mui/material/colors';
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import HelpIcon from "@mui/icons-material/Help";
import FormControl from "@mui/material/FormControl";
import EditIcon from "@mui/icons-material/Edit";
import ROLES_LIST from "../auths/roles_list";
import CLIENT_LIST from "../../utilities/client_list";
import CLIENT_TYP from "../../utilities/client_type_list";

import GppGoodIcon from "@mui/icons-material/GppGood";
import GppBadIcon from "@mui/icons-material/GppBad";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import debounce from "lodash.debounce";

const MAX_BATCH_SIZE = 1000;
const LOGIN_URL = "/login";

const greyColor = "#656565";
const greenColor = "#006400";
const orangeColor = "#FFA500";
const blueColor = "#1AA7EC";
const braunColor = "#80471C";
//const noneditableColor = '#F5F5F5';
//const editableColor = '#FFFFFF';

const status_zurAbgabe = "Abgabe";
const status_abgegeben = "Bearbeitet";
const status_beibehalten = "Veto";
const status_zugeordnet = "Zugeordnet";
const status_stammkunde = "Stammkunde";
const status_aufgenommen = "Aufgenommen";

const ansichtSegment = "segment";
const ansichtAbgabe = "abgabe";
const ansichtZuordnen = "zuordnen";
const ansichtAufnahme = "aufnahme";
const ansichtGesamt = "gesamtbestand";

LicenseManager.setLicenseKey(process.env.REACT_APP_AGGRID_LICENCE);

const chunkArray = (array, chunkSize) => {
  const numberOfChunks = Math.ceil(array.length / chunkSize);

  return [...Array(numberOfChunks)].map((value, index) => {
    return array.slice(index * chunkSize, (index + 1) * chunkSize);
  });
};

const renderEuro = (p) => {
  const amount = p.value;
  return (
    Math.round(amount)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €"
  );
};

// const renderDefaultString = (p) => {
//   return p.value === '' ? 'n.n.' : p.value;
// };
const renderConsultantTypMatchSegment = (p) => {
  if (p.value) {
    return <GppGoodIcon sx={{ color: greenColor }} size="large" />;
  } else {
    return <GppBadIcon sx={{ color: orangeColor }} size="large" />;
  }
};
const renderSegmentAngepasst = (p) => {
  if (p.value) {
    return <></>;
  } else {
    return <ErrorIcon sx={{ color: orangeColor }} size="large" />;
  }
};

const renderIsAbzugeben = (p) => {
  if (p.value) {
    return <></>;
  } else {
    return <TransferWithinAStationIcon sx={{ color: blueColor }} size="large" />;
  }
};

const segment_changed = (p) => {
  return p.getValue("segment_new") === p.getValue("segment_target");
};
const grob_segment_changed = (p) => {
  return p.getValue("grob_segment_new") === p.getValue("grob_segment_target");
};

const is_Abzugeben = (p) => {
  // console.log(
  //   "p.getValue('consultant_id_target'): " +
  //     p.getValue('consultant_id_target') +
  //     " p.getValue('consultant_id'): " +
  //     p.getValue('consultant_id') +
  //     ' : ' +
  //     (p.getValue('consultant_id_target') === p.getValue('consultant_id')),
  // );
  return p.getValue("consultant_id_target") === p.getValue("consultant_id");
};

const renderChoice = (p) => {
  const client_status = p.value;
  switch (client_status) {
    case status_stammkunde:
      // status_stammkunde
      return <SelfImprovementIcon sx={{ color: braunColor }} size="large" />;

    case status_abgegeben:
      //green saved
      return <ThumbUpIcon sx={{ color: greenColor }} size="large" />;
    case status_beibehalten:
      // red saved
      return <PanToolIcon sx={{ color: orangeColor }} size="large" />;
    case status_zugeordnet:
      // blue saved
      return <PublishedWithChangesIcon sx={{ color: blueColor }} size="large" />;
    case status_aufgenommen:
      // blue saved
      return <HandshakeIcon sx={{ color: greenColor }} size="large" />;
    case status_zurAbgabe:
      return <ThumbUpIcon sx={{ color: greenColor }} size="large" />;
    default:
      //no choice made all blank
      return <HelpIcon sx={{ color: greyColor }} size="large" />;
  }
};

/* 
///////////////////Enable charts
// const createQuarterlySalesChart = (gridApi) => {
//   gridApi.createCrossFilterChart({
//     chartType: 'column',
//     cellRange: {
//       columns: ['consultant_id', 'verbund_person_nr'],
//     },
//     aggFunc: 'count',
//     chartThemeOverrides: {
//       common: {
//         title: {
//           enabled: true,
//           text: 'Berater Kundenmenge',
//         },
//         legend: {
//           enabled: false,
//         },
//         axes: {
//           category: {
//             label: {
//               rotation: 0,
//             },
//           },
//           number: {
//             label: {
//               formatter: (params) => {
//                 return params.value; /// 1000 + 'k';
//               },
//             },
//           },
//         },
//       },
//     },
//     chartContainer: document.querySelector('#columnChart'),
//   });
// };

// const createSalesByRefChart = (gridApi) => {
//   gridApi.createCrossFilterChart({
//     chartType: 'pie',
//     cellRange: {
//       columns: ['client_status', 'verbund_person_nr'],
//     },
//     aggFunc: 'count',
//     chartThemeOverrides: {
//       common: {
//         title: {
//           enabled: true,
//           text: 'Bearbeitungsstatus',
//         },
//       },
//       pie: {
//         series: {
//           title: {
//             enabled: false,
//           },
//           label: {
//             enabled: false,
//           },
//         },
//       },
//     },
//     chartContainer: document.querySelector('#pieChart'),
//   });
// };
/////////////////////Enable charts

// const createHandsetSalesChart = (gridApi) => {
//   gridApi.createCrossFilterChart({
//     chartType: 'bar',
//     cellRange: {
//       columns: ['segment_target', 'verbund_person_nr'],
//     },
//     aggFunc: 'count',
//     chartThemeOverrides: {
//       common: {
//         title: {
//           enabled: true,
//           text: 'Ziel Segment Kundenmenge',
//         },
//         legend: {
//           enabled: false,
//         },
//       },
//     },
//     chartContainer: document.querySelector('#barChart'),
//   });
// };

/////////////////////Enable printer
// const setPrinterFriendly = (api) => {
//   const eGridDiv = document.querySelector('#myDataGrid');
//   eGridDiv.style.width = '';
//   eGridDiv.style.height = '';
//   api.setDomLayout('print');
// };

// const setNormal = (api) => {
//   const eGridDiv = document.querySelector('#myDataGrid');
//   eGridDiv.style.width = '';
//   eGridDiv.style.height = '';
//   api.setDomLayout();
// };
/////////////////////Enable printer */

const DataGrid = ({ ansicht }) => {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const gridRef = useRef();
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState();
  const getRowId = (params) => params.data.verbund_person_nr;
  const assignConsultantId = useRef("");
  const [selectConsultantId, setSelectConsultantId] = useState(auth.consultant_id ? auth.consultant_id : "");
  const [inputConsultantId, setInputConsultantId] = useState(auth.consultant_id ? auth.consultant_id : "");
  const [consultantIds, setConsultantIds] = useState([]);
  const [segmentNames, setSegmentNames] = useState([]);
  const [grobSegmentNames, setGrobSegmentNames] = useState([]);
  const [segmentLookup, setSegmentLookup] = useState([]);
  const [lock, setLock] = useState(false);
  const [lockdown, setLockDown] = useState(false);

  const [columnDefs, setColumnDefs] = useState([
    //setColumnDefs
  ]);
  const defaultColDef = useMemo(() => {
    return {
      //flex: 1,
      filter: true,
      sortable: true,
      floatingFilter: true,
      resizable: true,
      wrapHeaderText: false,
      autoHeaderHeight: true,
      sizeColumnsToFit: true,
    };
  }, []);

  const handleAssignConsultantSelection = (event, newValue) => {
    assignConsultantId.current = newValue;
    setSelectConsultantId(newValue);
    console.log("Selected Consultant:", newValue);
  };

  const onlyMyClients = useCallback(() => {
    if (gridRef.current && gridRef.current.api) {
      const consultantIdTargetComponent = gridRef.current.api.getFilterInstance("consultant_id_target");
      console.log(gridRef.current.api.getFilterInstance());
      if (consultantIdTargetComponent) {
        consultantIdTargetComponent.setModel({
          values: [auth.consultant_id],
        });
        gridRef.current.api.onFilterChanged();
      } else {
        console.error("Filter instance 'consultant_id_target' not found.");
      }
    } else {
      console.error("Grid API is not available.");
    }
  }, [auth.consultant_id]);

  const allVisibleClients = useCallback(() => {
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.setFilterModel(null);
    } else {
      console.error("Grid API is not available.");
    }
  }, []);

  /* Undo + Redo
  // const disable = (id, disabled) => {
  //   document.querySelector(id).disabled = disabled;
  // };

  // const setValue = (id, value) => {
  //   document.querySelector(id).value = value;
  // };
  // const onFirstDataRendered = useCallback(() => {
  //   setValue("#undoInput", 0);
  //   disable("#undoInput", true);
  //   disable("#undoBtn", true);
  //   setValue("#redoInput", 0);
  //   disable("#redoInput", true);
  //   disable("#redoBtn", true);
  // }, []);
  // const onCellValueChanged = useCallback(() => {
  //   let undoSize = gridRef.current.api.getCurrentUndoSize();
  //   setValue("#undoInput", undoSize);
  //   disable("#undoBtn", undoSize < 1);
  //   let redoSize = gridRef.current.api.getCurrentRedoSize();
  //   setValue("#redoInput", redoSize);
  //   disable("#redoBtn", redoSize < 1);
  // }, []);

  // const undo = useCallback(() => {
  //   gridRef.current.api.undoCellEditing();
  // }, []);

  // const redo = useCallback(() => {
  //   gridRef.current.api.redoCellEditing();
  // }, []); */

  // const submitChange = useCallback(
  //   async (item) => {
  //     console.log('update client bundle data ');

  //     try {
  //       await axiosPrivate.post('/client_bundles/updateSingleField', item);
  //     } catch (err) {
  //       if (!err?.response) {
  //         console.log('No Server Response' + err);
  //       } else {
  //         console.log('SubmitChange Failed');
  //       }
  //     }
  //   },
  //   [axiosPrivate],
  // );

  const submitChange = useCallback(
    async (verbund_person_nr, field_name, field_value) => {
      //console.log('update client data for ' + verbund_person_nr);

      const item = {
        verbund_person_nr: verbund_person_nr,
        field_name: field_name,
        field_value: field_value,
      };
      //console.log('item: ' + JSON.stringify(item));
      try {
        ColumnsToolPanelModule.log(field_name);
        if (field_name !== "reason_abgabe") {
          await axiosPrivate.post("/client_bundles/updateSingleField", item);
        }
      } catch (err) {
        if (!err?.response) {
          console.log("No Server Response" + err);
        } else {
          console.log("SubmitChange Failed");
        }
      }
    },
    [axiosPrivate],
  );

  const submitStatusChange = useCallback(
    //Axios Call
    async (consultant_id, verbund_id_list, client_status) => {
      try {
        await axiosPrivate.post("/client_bundles/update", {
          consultant_id,
          verbund_id_list,
          client_status: client_status,
        });
        //.then((res) => console.log('SubmitHandoverChange: ' + res?.data?.modifiedCount + ' data updated.'));
      } catch (err) {
        if (!err?.response) {
          console.log("No Server Response" + err);
        } else {
          console.log(err.response.toString);
        }
      }
    },
    [axiosPrivate],
  );

  const assignChunkClient_verbund = useCallback(
    //Axios Call
    async (consultant_id, verbund_id_list, consultant_id_target, consultant_typ_target, is_pure_segment) => {
      try {
        // console.log(
        //   'assignChunkClient_verbund for ' +
        //     consultant_id +
        //     ' to ' +
        //     consultant_id_target +
        //     ' with ' +
        //     consultant_typ_target +
        //     ' and therefore is_pure_segment becomes ' +
        //     is_pure_segment +
        //     ' with  ' +
        //     verbund_id_list.length +
        //     ' verbund daten ',
        // );
        //console.log('call /client_bundles/assignBatch');
        await axiosPrivate.post("/client_bundles/assignBatch", {
          consultant_id,
          verbund_id_list,
          consultant_id_target,
          consultant_typ_target,
          is_pure_segment,
        });
        //.then((res) => console.log('SubmitHandoverChange: ' + res?.data?.modifiedCount + ' data updated.'));
      } catch (err) {
        if (!err?.response) {
          console.log("No Server Response" + err);
        } else {
          console.log(err.response.toString);
        }
      }
    },
    [axiosPrivate],
  );

  const submitBatchedStatusChange = (consultant_id, verbund_id_list, client_status) => {
    //console.log('update client bundle data for: ', consultant_id);

    let chunks = chunkArray(verbund_id_list, MAX_BATCH_SIZE);
    for (const chunk of chunks) {
      //console.log('process a chunck of max. ' + MAX_BATCH_SIZE + ' status changes');
      submitStatusChange(consultant_id, chunk, client_status);
    }
  };

  /*
  const readConsultantIds = useCallback(async () => {
    try {
      await axiosPrivate
        .get('consultants/ids')
        .then((res) => res.data)
        .then((data) => {
          setConsultantIds(data);
        });
    } catch (err) {
      if (!err?.response) {
        console.log('No Server Response' + err);
      } else {
        console.log('readConsultantIds Failed' + err.response.toString);
      }
    }
  }, [axiosPrivate]);
*/
  const readConsultantIds = () => {
    setConsultantIds(auth.competences);
  };
  const fetchSegments = async () => {
    try {
      await axiosPrivate
        .get("client_bundles/segs")
        .then((res) => res.data)
        .then((data) => {
          setSegmentNames(data);
        });
    } catch (err) {
      if (!err?.response) {
        console.log("No Server Response" + err);
      } else {
        console.log("readSegmentNames Failed" + err);
      }
    }
  };

  const fetchGrobSegments = async () => {
    try {
      await axiosPrivate
        .get("client_bundles/grobSegs")
        .then((res) => res.data)
        .then((data) => {
          setGrobSegmentNames(data);
        });
    } catch (err) {
      if (!err?.response) {
        console.log("No Server Response" + err);
      } else {
        console.log("readGrobSegmentNames Failed" + err);
      }
    }
  };

  const fetchSegmentLookup = async () => {
    try {
      const res = await axiosPrivate.get("client_bundles/seglookup");
      setSegmentLookup(res.data);
      //console.log(res.data);
    } catch (error) {
      console.error("Failed to fetch segment lookup:", error);
      // handle error appropriately
    }
  };

  useEffect(() => {
    gridRef?.current?.api?.refreshCells();
  }, [rowData]); // Pass the data as a dependency

  useEffect(() => {
    fetchSegments();
    //console.log('fetch segment');
    if (process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK) {
      fetchGrobSegments();
      //console.log('fetch grob segment');
    }
    fetchSegmentLookup();
    //console.log('fetch segment lookup');
  }, []); // Run only once when component mounts

  useEffect(() => {
    //console.log('segmentNames updated... ');
    // Update the columnDefs state when fetchedData changes
    setColumnDefs([
      {
        headerName: "",
        children: [
          {
            field: "verbund_person_nr",
            headerName:
              process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK || process.env.REACT_APP_CLIENT === CLIENT_LIST.HASPA
                ? "Personennummer"
                : "Verbund Personennummer",
            suppressHeaderMenuButton: true,
            pinned: "left",
            chartDataType: "series",
          },
          {
            field: "verbund_nr",
            headerName: "Verbundführer",
            suppressHeaderMenuButton: true,
            pinned: "left",
            chartDataType: "series",
          },
          {
            field: "consultant_id",
            headerName: process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK ? "Berater alt" : "Berater OE",
            suppressHeaderMenuButton: true,
            pinned: "left",
          },
          {
            field: "consultant_typ",
            headerName: "Funktion",
            hide: ansicht === ansichtSegment,
            pinned: "left",
          },
        ],
      },
      {
        headerName: "Details",
        children: [
          {
            field: "lastname",
            headerName: process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK ? "FK-Einheit (gebildet)" : "Nachname",
          },
          {
            field: "firstname",
            headerName: process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK ? "Ansprechpartner FK-Einheit" : "Vorname",
            suppressHeaderMenuButton: true,
          },
          {
            field: "verbund_client_age",
            headerName: "Alter",
            suppressHeaderMenuButton: true,
          },
          {
            field: "street",
            headerName: "Straße",
            columnGroupShow: "open",
            hide: process.env.REACT_APP_CLIENT === CLIENT_LIST.HASPA || process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK,
          },
          {
            field: "post_nr",
            headerName: "PLZ",
            chartDataType: "category",
            hide: process.env.REACT_APP_CLIENT === CLIENT_LIST.HASPA,
          },
          {
            field: "city",
            headerName: "Stadt",
            columnGroupShow: "open",
            hide: process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK || process.env.REACT_APP_CLIENT === CLIENT_LIST.HASPA,
          },
          {
            field: "filial_nr_new",
            headerName: "Filiale",
            columnGroupShow: "open",
            hide: process.env.REACT_APP_TYP !== CLIENT_TYP.VB_FK,
          },
          {
            field: "verbund_ek_bew",
            headerName: process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK ? "Passivvolumen" : "Verbund Einkommen",
            columnGroupShow: "open",
            cellRenderer: renderEuro,
            chartDataType: "series",
            filter: "agNumberColumnFilter",
          },
          {
            field: "verbund_haspa_spar",
            headerName: "Verbund Sparfähigkeit",
            columnGroupShow: "open",
            cellRenderer: renderEuro,
            chartDataType: "series",
            filter: "agNumberColumnFilter",
          },
          {
            field: "verbund_asset",
            headerName: process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK ? "Umsatz" : "Verbund Vermögen",
            columnGroupShow: "open",
            cellRenderer: renderEuro,
            chartDataType: "series",
            filter: "agNumberColumnFilter",
          },
          {
            field: "verbund_asset_wp",
            headerName: "Verbund Wertpapier",
            columnGroupShow: "open",
            cellRenderer: renderEuro,
            chartDataType: "series",
            filter: "agNumberColumnFilter",
            hide: process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK,
          },
          {
            field: "verbund_fin_vol",
            headerName: "Verbund Finanzierungsvolumen",
            columnGroupShow: process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK ? "close" : "open",
            cellRenderer: renderEuro,
            chartDataType: "series",
            filter: "agNumberColumnFilter",
          },
          {
            field: "verbund_fin_rate",
            headerName: "Verbund Darlehensrate",
            columnGroupShow: "open",
            cellRenderer: renderEuro,
            chartDataType: "series",
            filter: "agNumberColumnFilter",
          },
          {
            field: "right_form",
            headerName: "Rechtsform",
            columnGroupShow: "open",
            hide: true,
          },
          {
            field: "grob_segment_old",
            headerName: "Grob Segment Alt",
            suppressHeaderMenuButton: true,
            hide: true,
          },
          {
            field: "grob_segment_new",
            headerName: "Grobsegment",
            suppressHeaderMenuButton: true,
            hide: ansicht !== ansichtSegment || process.env.REACT_APP_TYP !== CLIENT_TYP.VB_FK,
          },
          {
            field: "grob_segment_target",
            headerName: "Grobsegment angepasst",
            suppressHeaderMenuButton: true,
            editable: ansicht === ansichtSegment && !lock && !lockdown,
            cellEditor: "agSelectCellEditor",
            cellEditorParams: {
              values: grobSegmentNames,
            },
            hide: process.env.REACT_APP_TYP !== CLIENT_TYP.VB_FK,
          },
          {
            headerName: "Ist Grobsegment angepasst",
            valueGetter: (params) => grob_segment_changed(params),
            cellRenderer: renderSegmentAngepasst,
            hide: ansicht !== ansichtSegment || process.env.REACT_APP_TYP !== CLIENT_TYP.VB_FK,
          },
          {
            field: "double_person_nr",
            headerName: "Pers Nr Doppelkunde",
            hide:
              process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK ||
              process.env.REACT_APP_CLIENT === CLIENT_LIST.SKSP ||
              process.env.REACT_APP_CLIENT === CLIENT_LIST.HASPA,
            columnGroupShow: "open",
          },
          {
            field: "segment_old",
            headerName: process.env.REACT_APP_CLIENT === CLIENT_LIST.HASPA ? "Kompetenzwelt Alt" : "Segment Alt",
            suppressHeaderMenuButton: true,
            hide:
              process.env.REACT_APP_CLIENT === CLIENT_LIST.HASPA ||
              process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK ||
              ansicht !== ansichtSegment,
          },
          {
            field: "segment_new",
            headerName: process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK ? "Spezialsegment" : process.env.REACT_APP_CLIENT === CLIENT_LIST.HASPA ? "Kompetenzwelt Vorschlag" : "Segment Vorschlag",
            suppressHeaderMenuButton: true,
            hide: process.env.REACT_APP_CLIENT === CLIENT_LIST.HASPA || ansicht !== ansichtSegment,
          },
          {
            field: "segment_target",
            headerName: process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK ? "Spezialsegment angepasst" : process.env.REACT_APP_CLIENT === CLIENT_LIST.HASPA ? "Kompetenzwelt Ziel" : "Segment Ziel",
            suppressHeaderMenuButton: true,
            editable: ansicht === ansichtSegment && !lock && !lockdown,
            cellEditor: "agSelectCellEditor",
            cellEditorParams: {
              values: segmentNames,
            },
          },
          {
            headerName: process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK ? "Ist Spezialsegment angepasst" : "Segment Vorschlag angepasst",
            valueGetter: (params) => segment_changed(params),
            cellRenderer: renderSegmentAngepasst,
            hide: process.env.REACT_APP_CLIENT === CLIENT_LIST.HASPA || ansicht !== ansichtSegment,
          },
          {
            field: "fein_segment",
            headerName: "Feinsegment",
            suppressHeaderMenuButton: true,
            hide:
              process.env.REACT_APP_CLIENT === CLIENT_LIST.HASPA ||
              ansicht !== ansichtZuordnen ||
              process.env.REACT_APP_TYP !== CLIENT_TYP.VB_FK,
          },
          {
            field: "note",
            headerName: "Bemerkung",
            hide: process.env.REACT_APP_CLIENT !== CLIENT_LIST.HASPA && ansicht !== ansichtSegment,
            suppressHeaderMenuButton: true,
            editable: ansicht === ansichtSegment && !lock && !lockdown,
            cellRenderer: (params) => {
              return ansicht === ansichtSegment && !lock && !lockdown ? (
                <span>
                  {params.value}
                  <EditIcon></EditIcon>
                </span>
              ) : (
                <span>{params.value}</span>
              );
            },
          },
          {
            field: "reason_abgabe",
            headerName: "Veto Abgabe",
            hide: ansicht !== ansichtAbgabe && ansicht !== ansichtGesamt && ansicht !== ansichtZuordnen,
            suppressHeaderMenuButton: true,
            editable: ansicht === ansichtAbgabe && !lock && !lockdown,
            cellEditor: "agSelectCellEditor",
            cellEditorParams: {
              values:
                process.env.REACT_APP_CLIENT === CLIENT_LIST.SKHL
                  ? [
                      "",
                      "Laufender Erbfall",
                      "Temporäre Überschreitung Einkommen/Vermögen",
                      "Innerhalb der letzten 24 Monate übergeleitet",
                      "Konkretes Geschäftsvorhaben",
                      "Meinungsbildner / VIP",
                    ]
                  : process.env.REACT_APP_CLIENT === CLIENT_LIST.HASPA
                    ? [
                        "",
                        "Kunde ist kontaktiert (Kontakt lag vor)",
                        "Temporäre Erfüllung der Segmentierungskriterien",
                        "Kunde verstorben/Nachlassabwicklung",
                      ]
                    : [
                        "",
                        "Langjährige Kundenbeziehung",
                        "Einmaliges Unter-/Überschreiten Segmentgrenzen",
                        "Konkretes Geschäftsvorhaben",
                        "Risikorelevante Kreditengagements",
                        "Abgabe aufgrund Sanierung",
                        "Neukunden",
                        "Volljährige Kinder / LebenspartnerIn aus zugeordneten Kunden",
                        "FK Immobilienkunde",
                        "Falsch ermitteltes Segment",
                        "Fehlerhafte Verbundbildung",
                        "Umgekehrte Co-Betreuung",
                        "Stiftungen",
                        "Generalbevollmächtigte / Betreuung",
                        "PB-Entwicklungskunde mit Potential",
                      ],
            },
            chartDataType: "category",
          },
          {
            field: "reason_aufnahme",
            headerName: "Veto Aufnahme",
            suppressHeaderMenuButton: true,
            editable: ansicht === ansichtZuordnen && !lock && !lockdown,
            cellEditor: "agSelectCellEditor",
            cellEditorParams: {
              values: ["", "Private Verflechtung", "Überschreiten der Betreuungsrelationen"],
            },
            chartDataType: "category",
            hide: ansicht !== ansichtZuordnen,
          },
          {
            field: "filial_nr_new",
            headerName: "Filiale Neu",
            suppressHeaderMenuButton: true,
            columnGroupShow: "open",
            hide: process.env.REACT_APP_CLIENT === CLIENT_LIST.HASPA,
          },
          {
            field: "filial_typ_new",
            headerName: "Filiale Name Neu",
            suppressHeaderMenuButton: true,
            columnGroupShow: "open",
            hide: process.env.REACT_APP_CLIENT === CLIENT_LIST.HASPA,
          },
          {
            field: "consultant_id_new",
            headerName: process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK ? "Berater neu" : "Berater OE Vorschlag",
            suppressHeaderMenuButton: true,
            chartDataType: "category",
            hide: ansicht === ansichtSegment || ansicht === ansichtAbgabe || ansicht === ansichtGesamt,
          },
          {
            field: "consultant_typ_new",
            headerName: "Funktion Vorschlag",
            chartDataType: "category",
            suppressHeaderMenuButton: true,
            hide: ansicht === ansichtAbgabe || ansicht === ansichtGesamt,
          },
          {
            field: "consultant_id_target",
            headerName: process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK ? "Berater angepasst" : "Berater OE Ziel",
            suppressHeaderMenuButton: true,
            columnGroupShow: ansicht !== ansichtSegment || process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK ? "close" : "open",
            editable: false,
            chartDataType: "category",
            filter: "agSetColumnFilter", // Make sure filtering is enabled
            hide: ansicht === ansichtAbgabe || ansicht === ansichtGesamt,
          },
          {
            field: "consultant_typ_target",
            headerName: process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK ? "Beratertyp angepasst" : "Funktion Ziel",
            chartDataType: "category",
            suppressHeaderMenuButton: true,
            editable: false,
            hide: ansicht === ansichtSegment || ansicht === ansichtAbgabe || ansicht === ansichtGesamt,
          },
          {
            field: "coresp_typ",
            headerName: "Anspracheweg",
            filter: "agSetColumnFilter",
            suppressHeaderMenuButton: true,
            hide:
              [ansichtZuordnen, ansichtAbgabe].indexOf(ansicht) == -1 ||
              process.env.REACT_APP_TYP !== CLIENT_TYP.VB_FK ||
              process.env.REACT_APP_CLIENT === CLIENT_LIST.HASPA,
            editable: ansicht !== ansichtGesamt,
            cellEditor: "agSelectCellEditor",
            cellEditorParams: {
              values:
                process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK
                  ? ["Tandem-Gespräch", "Telefonischer Kontakt", "Anschreiben", "Übergeleitet", "keine Ansprache"]
                  : ["Persönliche Ansprache", "Schriftliche Information", "Kalte Überleitung", "Keine Überleitung"],
            },
            chartDataType: "category",
          },
          {
            field: "capacity",
            headerName: "Kapazität",
            columnGroupShow: "open",
            hide: process.env.REACT_APP_TYP !== CLIENT_TYP.VB_FK || process.env.REACT_APP_CLIENT === CLIENT_LIST.HASPA,
          },
          {
            field: "is_done",
            headerName: "Initial Bearbeitet",
            columnGroupShow: "open",
            hide: process.env.REACT_APP_TYP !== CLIENT_TYP.VB_FK || process.env.REACT_APP_CLIENT === CLIENT_LIST.HASPA,
          },
          {
            field: "last_modified",
            headerName: "Letzte Bearbeitung Zeit",
            columnGroupShow: "open",
            hide: process.env.REACT_APP_TYP !== CLIENT_TYP.VB_FK || process.env.REACT_APP_CLIENT === CLIENT_LIST.HASPA,
          },
          {
            field: "mod_consultant_id",
            headerName: "Letzter Bearbeiter OE",
            columnGroupShow: "open",
            hide: process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK || process.env.REACT_APP_CLIENT === CLIENT_LIST.HASPA,
          },
          {
            field: "is_pure_segment",
            headerName: "Beratertyp passt zu Segment",
            suppressHeaderMenuButton: true,
            editable: false,
            cellRenderer: renderConsultantTypMatchSegment,
            hide:
              ansicht === ansichtSegment ||
              ansicht === ansichtAbgabe ||
              ansicht === ansichtGesamt ||
              process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK,
          },
        ],
      },
      {
        field: "client_status",
        headerName: "Status",
        suppressHeaderMenuButton: true,
        cellRenderer: renderChoice,
        checkboxSelection: ansicht !== ansichtGesamt,
        pinned: "right",
        chartDataType: "category",
      },
      {
        field: "is_locked",
        headerName: "Gesperrt",
        suppressHeaderMenuButton: true,
        hide: ansicht !== ansichtGesamt || process.env.REACT_APP_TYP !== CLIENT_LIST.HASPA,
        columnGroupShow: "open",
      },
    ]);
  }, [segmentNames, grobSegmentNames]);

  const readLockdown = useCallback(async () => {
    //console.log('readLockdown');
    try {
      await axiosPrivate
        .get("administration/lockdown")
        .then((res) => res.data)
        .then((data) => {
          if (data.value === "true") {
            setLockDown(true);
            //console.log('setLockdown to true... ');
          }
        });
    } catch (err) {
      if (!err?.response) {
        console.log("No Server Response" + err);
      } else {
        console.log("readLockdown Failed" + err.response.toString);
      }
      //console.log('readLockdown unchanged');
    }
  }, [axiosPrivate, lockdown]);

  const readData = useCallback(async () => {
    let method = "client_bundles";
    // Rheinhessen
    if (process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK) {
      method = method + "/my";
    } else if (ansicht === ansichtSegment) {
      method = method + "/segment";
    } else if (ansicht === ansichtAbgabe) {
      if (process.env.REACT_APP_CLIENT === CLIENT_LIST.SKHL) {
        method = method + "/beibehalten";
      } else {
        //SKSP
        method = method + "/abgabe";
      }
    } else if (ansicht === ansichtZuordnen) {
      method = method + "/assign";
    } else if (ansicht === ansichtAufnahme) {
      method = method + "/aufnahme";
    } else {
      // auth.role === ROLES_LIST.Admin
      //take the whole data for all other cases
    }

    try {
      await axiosPrivate
        .get(
          method,
          { data: auth.competences },
          {
            timeout: 30000,
          },
        )
        .then((res) => res.data)
        .then((data) => {
          let filteredData = data.foundClient_bundles;
          if (auth.role === ROLES_LIST.Auditor) {
            setLock(true);
          }
          //console.log(filteredData);
          setRowData(filteredData);
        });
    } catch (err) {
      if (!err?.response) {
        console.log("No Server Response" + err);
      } else {
        console.log("SubmitChange Failed" + err);
      }
      navigate(LOGIN_URL);
    }
  }, [ansicht, auth.competences, axiosPrivate, lock, lockdown]);

  const batchChangeSelectedStatus = useCallback(
    async (target_status) => {
      let selectedData = gridRef.current.api.getSelectedRows();

      if (!selectedData) {
        console.log("no data selected to process");
        return;
      }

      if (
        (ansicht === ansichtAbgabe || ansicht === ansichtSegment) &&
        auth.role == ROLES_LIST.Auditor
        //selectedData.some((e) => e.is_locked === true) &&
        //auth.role != ROLES_LIST.Supervisor
      ) {
        alert("Änderungen aktuell nicht mehr möglich");
        return;
      }

      let locked_data = [];
      let processed_data = [];
      let to_change = [];
      let process_amount = 0;
      let items_to_update = [];
      for (const item of selectedData) {
        if (item.is_locked) {
          locked_data.push(item.verbund_person_nr);
        } else if (item.client_status === status_zurAbgabe) {
          process_amount = process_amount + 1;
          //console.log({ process_amount });
        }

        if (item.client_status === target_status) {
          processed_data.push(item.verbund_person_nr);
        } else {
          to_change.push(item.verbund_person_nr);
          item.client_status = target_status;
          items_to_update.push(item);
        }
      }

      if (to_change.length > 0) {
        //update frontend data
        //gridRef.current.api.setDeltaSort(true);
        gridRef.current.api.applyTransaction({ update: items_to_update });
        //gridRef.current.api.setFilterModel([]);
        //update backend data
        let chunks = chunkArray(to_change, MAX_BATCH_SIZE);
        for (const chunk of chunks) {
          //console.log('process a chunck of max. ' + MAX_BATCH_SIZE + ' status changes');
          submitStatusChange(auth.consultant_id, chunk, target_status);
        }
      }

      // const msg =
      //   'Sie haben ' +
      //   selectedData.length +
      //   ' Verbünden gewählt.\nDavon ' +
      //   locked_data.length +
      //   ' gesperrt Verbünde und ' +
      //   processed_data.length +
      //   ' schon ' +
      //   target_status +
      //   ' Daten werden ignoriert,\n nur ' +
      //   to_change.length +
      //   ' Verbünden werden ' +
      //   target_status;

      //if (locked_data.length > 0 || processed_data.length > 0) console.log(msg);

      gridRef.current.api.deselectAll();
      gridRef.current.api.refreshCells();
    },
    [submitBatchedStatusChange],
  );

  const batchAssignSelectedClients = useCallback(
    async (consultant_id_target) => {
      let selectedData = gridRef.current.api.getSelectedRows();

      if (!selectedData) {
        console.log("no data selected to process");
        return;
      }

      let verbund_id_list_true = [];
      let verbund_id_list_false = [];

      let items_to_update = [];
      let consultant_typ_target = "";
      if (selectedData.length > 0) {
        //console.log('batchAssignSelectedClients for ' + selectedData.length + ' clients.');

        try {
          await axiosPrivate
            .post("consultants/consultantTargetTyp", {
              consultant_id_target,
            })
            .then((res) => res.data)
            .then((data) => {
              consultant_typ_target = data;
            });
        } catch (err) {
          if (!err?.response) {
            console.log("No Server Response" + err);
          } else {
            console.log("readConsultantIds Failed" + err.response.toString);
          }
        }

        if (consultant_typ_target === "") {
          console.log("consultant_typ_target is empty.");
          return;
        }

        for (const item of selectedData) {
          let id = item.verbund_person_nr;
          //gridRef.current.api.setDeltaSort(true);
          item.client_status = status_zugeordnet;
          item.consultant_id_target = consultant_id_target;
          item.consultant_typ_target = consultant_typ_target;

          // Assuming fein_segment and consultant_typ are properties of the item
          const key = item.segment_target + "-" + consultant_typ_target;
          //console.log(key);
          //console.log(segmentLookup);
          // Check if the key exists in the segmentLookup object
          if (segmentLookup[key]) {
            item.is_pure_segment = true;
            verbund_id_list_true.push(id);
          } else {
            item.is_pure_segment = false;
            verbund_id_list_false.push(id);
          }

          // try {
          //   const segment_target = item.segment_target;
          //   const verbund_person_nr = id;

          //   console.log('call /client_bundles/getUpdatedPureSeg');
          //   await axiosPrivate
          //     .post('/client_bundles/getUpdatedPureSeg', {
          //       verbund_person_nr,
          //       segment_target,
          //       consultant_typ_target,
          //     })
          //     .then((res) => res.data)
          //     .then((data) => {
          //       item.is_pure_segment = data;
          //       console.log('getUpdatedPureSeg: ' + verbund_person_nr + ' : ' + data);
          //     });
          // } catch (err) {
          //   if (!err?.response) {
          //     console.log('No Server Response' + err);
          //   } else {
          //     console.log(err.response.toString);
          //   }
          // }

          items_to_update.push(item);
        }
        //update frontend data
        gridRef.current.api.applyTransaction({ update: items_to_update });
        //gridRef.current.api.setFilterModel(null);//fix the bug that the filter is cleared when changes made
        //update backend data

        //console.log({ verbund_id_list_true });
        //console.log({ verbund_id_list_false });

        const chunks_true = chunkArray(verbund_id_list_true, MAX_BATCH_SIZE);
        for (const chunk of chunks_true) {
          //console.log('process a chunck of max. ' + MAX_BATCH_SIZE + ' assignement change with segment true');
          assignChunkClient_verbund(auth.consultant_id, chunk, consultant_id_target, consultant_typ_target, true);
        }

        const chunks_false = chunkArray(verbund_id_list_false, MAX_BATCH_SIZE);
        for (const chunk of chunks_false) {
          //console.log('process a chunck of max. ' + MAX_BATCH_SIZE + ' assignement change with segment false');
          assignChunkClient_verbund(auth.consultant_id, chunk, consultant_id_target, consultant_typ_target, false);
        }
      }

      // const msg =
      //   'Führungskraft: ' +
      //   auth.consultant_id +
      //   ' hat ' +
      //   selectedData.length +
      //   ' Verbünden \n zum Zielberater: ' +
      //   consultant_id_target +
      //   ' zuzuordnen.\n';
      //console.log(msg);

      gridRef.current.api.deselectAll();
    },
    [assignChunkClient_verbund, segmentLookup],
  );

  const handoverSelected = useCallback(async () => {
    batchChangeSelectedStatus(status_abgegeben);
  }, [ansicht, batchChangeSelectedStatus]);

  const aufnahmeSelected = useCallback(async () => {
    batchChangeSelectedStatus(status_aufgenommen);
  }, [batchChangeSelectedStatus]);

  const reverseAufnahme = useCallback(async () => {
    batchChangeSelectedStatus(status_zurAbgabe);
  }, [batchChangeSelectedStatus]);

  const setVeto = useCallback(async () => {
    let selectedData = gridRef.current.api.getSelectedRows();

    if (!selectedData || selectedData.length === 0) {
      console.log("No data selected to process");
      alert("Bitte wählen Sie mindestens eine Zeile aus um ein Veto zu setzen.");
      return;
    }

    if (process.env.REACT_APP_CLIENT === CLIENT_LIST.HSK || process.env.REACT_APP_CLIENT === CLIENT_LIST.HASPA) {
      if (ansicht === ansichtZuordnen) {
        const invalidRows = selectedData.filter((item) => !item.reason_aufnahme || item.reason_aufnahme === "");
        if (invalidRows.length > 0) {
          alert('Bitte zuerst einen Grund in der Spalte "Veto Aufnahme" auswählen.');
          return;
        }
      } else {
        const invalidRows = selectedData.filter((item) => !item.reason_abgabe || item.reason_abgabe === "");
        if (invalidRows.length > 0) {
          alert('Bitte zuerst einen Grund in der Spalte "Veto Abgabe" auswählen.');
          return;
        }
      }
    }

    // Iterate over each selected row and process it
    const promises = selectedData.map(async (item) => {
      let verbund_person_nr = item.verbund_person_nr;
      item.client_status = status_beibehalten;
      item.consultant_id_target = item.consultant_id;
      item.consultant_typ_target = item.consultant_typ;
      item.mod_consultant_id = auth.consultant_id;

      let veto_params = {};

      if (ansicht === ansichtZuordnen) {
        veto_params = {
          verbund_person_nr,
          reason_aufnahme: item.reason_aufnahme,
        };
      } else {
        veto_params = {
          verbund_person_nr,
          reason_abgabe: item.reason_abgabe,
        };
      }

      try {
        // Call /client_bundles/assign for each selected item
        await axiosPrivate.post("/client_bundles/assign", veto_params);

        const segment_target = item.segment_target;
        const consultant_typ_target = item.consultant_typ;
        // Call /client_bundles/getUpdatedPureSeg for each selected item
        const data = await axiosPrivate.post("/client_bundles/getUpdatedPureSeg", {
          verbund_person_nr,
          segment_target,
          consultant_typ_target,
        });

        item.is_pure_segment = data.data;
      } catch (err) {
        if (!err?.response) {
          console.log("No Server Response", err);
        } else {
          console.log(err.response.toString);
        }
      }
      return item;
    });

    try {
      // Wait for all promises to resolve
      const updatedItems = await Promise.all(promises);
      // Apply updates to the grid
      gridRef.current.api.applyTransaction({ update: updatedItems });
    } catch (error) {
      console.log("Error processing selected rows", error);
    }
  }, [axiosPrivate, auth, gridRef]);

  const revertVeto = useCallback(async () => {
    let selectedData = gridRef.current.api.getSelectedRows();

    if (!selectedData || selectedData.length === 0) {
      console.log("no data selected to process");
      return;
    }

    if (ansicht === ansichtAbgabe) {
      const invalidRows = selectedData.filter((item) => !item.reason_abgabe || item.reason_abgabe === "");
      if (invalidRows.length > 0) {
        return;
      }
    }

    for (let item of selectedData) {
      let verbund_person_nr = item.verbund_person_nr;
      item.client_status = status_zurAbgabe;
      item.consultant_id_target = item.consultant_id_new;
      item.consultant_typ_target = item.consultant_typ_new;
      item.mod_consultant_id = auth.consultant_id;

      const reason = ansicht === ansichtZuordnen ? "aufnahme" : "abgabe";
      if (reason === "abgabe") {
        item.reason_abgabe = "";
        if (!item.reason_aufnahme || item.reason_aufnahme === "") {
          item.client_status = status_zurAbgabe;
        }
      } else {
        item.reason_aufnahme = "";
        if (!item.reason_abgabe || item.reason_abgabe === "") {
          item.client_status = status_zurAbgabe;
        }
      }

      try {
        //console.log('call /client_bundles/reverse');
        await axiosPrivate.post("/client_bundles/reverse", {
          verbund_person_nr,
          reason: reason,
        });

        const segment_target = item.segment_target;
        const consultant_typ_target = item.consultant_typ_new;
        //console.log('call /client_bundles/getUpdatedPureSeg');
        await axiosPrivate
          .post("/client_bundles/getUpdatedPureSeg", {
            verbund_person_nr,
            segment_target,
            consultant_typ_target,
          })
          .then((res) => res.data)
          .then((data) => {
            item.is_pure_segment = data;
            //console.log('getUpdatedPureSeg: ' + verbund_person_nr + ' : ' + data);
          });
      } catch (err) {
        if (!err?.response) {
          console.log("No Server Response" + err);
        } else {
          console.log(err.response.toString);
        }
      }
    }
    //update frontend data
    gridRef.current.api.applyTransaction({ update: selectedData });
  }, [axiosPrivate]);


  const assignSelected = useCallback(() => {
    const assign_cons_id = assignConsultantId.current === "" ? auth.consultant_id : assignConsultantId.current;
    if (!assign_cons_id) {
      console.log("no target consultant selected");
      return;
    } else {
      //console.log({ assign_cons_id });
    }
    batchAssignSelectedClients(assign_cons_id);
  }, [batchAssignSelectedClients]);

  const onCellEditRequest = useCallback(
    (event) => {
      //console.log('onCellEditRequest with lock: ' + lock + ' lockdown: ' + lockdown);
      if (lockdown || lock) {
        alert("Änderungen aktuell nicht mehr möglich");
        return;
      }
      if ((ansicht === ansichtSegment || ansicht === ansichtAbgabe) && lock) {
        alert("Änderungen aktuell nicht mehr möglich");
        return;
      }
      const oldData = event.data;
      const field = event.colDef.field;
      const newData = { ...oldData };
      newData[field] = event.newValue;
      //console.log('onCellEditRequest, updating ' + field + ' to ' + event.newValue);
      const tx = {
        update: [newData],
      };
      event.api.applyTransaction(tx);
      //console.log({ newData });

      //Axios call
      const verbund_person_nr = event.node.id;
      //console.log('verbund_person_nr: ' + getRowId);

      if (field === "reason_abgabe") {
        //console.log('reason is changed');
        const rowNode = gridRef.current.api.getRowNode(verbund_person_nr);
        // Select the row programmatically
        if (rowNode) {
          rowNode.setSelected(true);
        }
      }
      //Axios call
      submitChange(verbund_person_nr, field, event.newValue);
    },
    [submitChange, lock, lockdown],
  );

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agTotalAndFilteredRowCountComponent", align: "left" },
        { statusPanel: "agTotalRowCountComponent", align: "center" },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    };
  }, []);

  /* ////////////////////Enable Charts
  // const enableCharts = ansicht === ansichtGesamt ? true : false;
  /////////////////////Enable Charts */

  const onGridReady = useCallback(
    async (params) => {
      //console.log('onGridReady');
      readLockdown();
      //console.log({ lockdown });
      if (ansicht === ansichtZuordnen) readConsultantIds();
      readData();
      gridRef.current.api = params.api;
    },
    [readLockdown, readData, lockdown, lock],
  );
  /* ///////////////////Enable Charts
    // if (ansicht === ansichtGesamt) {
    //   createQuarterlySalesChart(params.api);
    //   createSalesByRefChart(params.api);
    //   createHandsetSalesChart(params.api);
    // }
    /////////////////////Enable Charts*/
  const onFirstDataRendered = useCallback(() => {
    //console.log('onFirstDataRendered');
    const debouncedSizeColumnsToFit = debounce(() => {
      gridRef.current.api.sizeColumnsToFit();
    }, 300);
    debouncedSizeColumnsToFit();
  }, []);

  const clearFilter = useCallback(() => {
    gridRef.current.api.setFilterModel(null);
  }, []);

  const getTopToolBar = () => (
    <div className="example-toolbar panel panel-default">
      <div className="panel-body">
        {/* <input type="checkbox" id="toggleCheck" checked={checkBoxSelected} onChange={onCheckboxChange} />
        <label htmlFor="toggleCheck">Menge Bearbeitung</label> */}
        {/* <button onClick={toggleCollapse}>{collapsed ? 'Aufblättern' : 'Ausblenden'}</button> */}
        <button onClick={clearFilter}>Filter Zurücksetzen</button>
        {
          <span className="input-group-button">
            <button type="button" className="btn btn-default reset" style={{ marginLeft: "5px" }} onClick={readData}>
              <i className="fas fa-redo" style={{ marginRight: "5px" }}></i>Neu laden
            </button>
          </span>
        }
      </div>
    </div>
  );

  /*///////////////////Enable Printer
  // const onBtPrint = useCallback(() => {
  //   const api = gridRef.current.api;
  //   setPrinterFriendly(api);
  //   console.log('print');
  //   setTimeout(function () {
  //     print();
  //     //setNormal(api);
  //   }, 2000);
  // }, []);
  /////////////////////Enable Printer */
  return (
    <div style={containerStyle}>
      {/* /////////////////////Enable Charts {ansicht === ansichtGesamt ? (
        <>
          <div id="top">
            <div id="columnChart" className="ag-theme-alpine-dark"></div>
            <div id="pieChart" className="ag-theme-alpine-dark"></div>
          </div>
          <div id="barChart" className="ag-theme-alpine-dark"></div>
        </>
      ) : (
        <></>
      )}/////////////////////Enable Charts */}

      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <Grid container dir="row" mb={2} justifyContent="space-between">
          <Grid item>{getTopToolBar()}</Grid>
          {/* <Grid item> /////////////////////Enable Printer
            <ButtonGroup>
              <Button variant="contained" onClick={onBtPrint}>
                Drucken
              </Button>
            </ButtonGroup>
          </Grid> /////////////////////Enable Printer*/}
          <Grid item>
            <Grid container spacing={2}>
              {ansicht === ansichtSegment ? (
                <Grid item>
                  <Button id="handoverSelected" variant="contained" disabled={lock || lockdown} onClick={handoverSelected}>
                    Speichern
                  </Button>
                </Grid>
              ) : ansicht === ansichtAbgabe ? (
                <>
                  <Grid item>
                    <Button id="resetSelected" variant="contained" disabled={lock || lockdown} onClick={revertVeto}>
                      Zurücksetzen
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button id="keepSelected" variant="contained" disabled={lock || lockdown} onClick={setVeto}>
                      Veto
                    </Button>
                  </Grid>
                </>
              ) : ansicht === ansichtZuordnen ? (
                <Grid container dir="row" spacing={2} alignItems="flex-end">
                  <Grid item>
                    <FormControl variant="standard" size="small" sx={{ minWidth: 120 }}>
                      <Autocomplete
                        autoComplete
                        includeInputInList
                        id="assign_consultant_selector"
                        options={consultantIds.filter((id) => id.length === 7)}
                        sx={{ width: 300 }}
                        value={selectConsultantId}
                        inputValue={inputConsultantId}
                        onChange={handleAssignConsultantSelection}
                        onInputChange={(_event, newInputValue) => {
                          setInputConsultantId(newInputValue);
                        }}
                        renderInput={(params) => <TextField {...params} label="Berater OE Auswählen" variant="standard" />}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <ButtonGroup>
                      <Button id="assignSelected" variant="contained" disabled={lock || lockdown} onClick={assignSelected}>
                        Zuordnen
                      </Button>
                      {/* <Button
                        variant="contained"
                        onClick={submitAufnahmeChanges}
                      >
                        Speichern
                      </Button> */}
                    </ButtonGroup>
                  </Grid>

                  <Grid item>
                    <Button id="resetSelected" variant="contained" disabled={lock || lockdown} onClick={revertVeto}>
                      Zurücksetzen
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button id="keepSelected" variant="contained" disabled={lock || lockdown} onClick={setVeto}>
                      Veto
                    </Button>
                  </Grid>
                </Grid>
              ) : ansicht === ansichtAufnahme ? (
                auth.role === ROLES_LIST.Admin ? (
                  <>
                    <Grid item>
                      <Button id="reverseAufnahme" variant="contained" disabled={lock || lockdown} onClick={reverseAufnahme}>
                        Gespräch offen
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button id="aufnahmeSelected" variant="contained" disabled={lock || lockdown} onClick={aufnahmeSelected}>
                        Aufnahmegespräch durchgeführt
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <Grid item>
                    <Button id="aufnahmeSelected" variant="contained" disabled={lock || lockdown} onClick={aufnahmeSelected}>
                      Aufnahmegespräch durchgeführt
                    </Button>
                  </Grid>
                )
              ) : (
                <></>
                //unten ist für den Gesamtbestand Ansicht
                // <Grid item>
                //   <Grid container spacing={2}>
                //     <Grid item>
                //       <Button variant="contained" onClick={onlyMyClients}>
                //         Nur meine Kunden
                //       </Button>
                //     </Grid>
                //     <Grid item>
                //       <Button variant="contained" onClick={allVisibleClients}>
                //         Alle
                //       </Button>
                //     </Grid>
                //   </Grid>
                // </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <div style={{ flexGrow: "1", height: "calc(100vh - 150px" }}>
          {/* height: 100% is not enough as AGGrid don't know the wrapper's height */}
          <div id="myDataGrid" className="ag-theme-alpine" style={gridStyle}>
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              getRowId={getRowId}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              //editType={'fullRow'}
              //suppressRowClickSelection={true}
              rowSelection={"multiple"}
              rowMultiSelectWithClick={true}
              onGridReady={onGridReady}
              onFirstDataRendered={undefined}
              //undoRedoCellEditing={true}
              //undoRedoCellEditingLimit={5}
              //enableCellChangeFlash={true}
              animateRows={true}
              stopEditingWhenCellsLoseFocus={true}
              readOnlyEdit={true}
              singleClickEdit={true}
              onCellEditRequest={onCellEditRequest}
              statusBar={statusBar}
              //sideBar={ansicht === ansichtGesamt ? true : false}
              //paginationAutoPageSize={true}
              //pagination={true}
              /////////////////////Enable Charts
              // enableCharts={enableCharts}
              /////////////////////Enable Charts
              gridOptions={[
                {
                  getRowStyle: (params) => {
                    if (params.data?.is_locked || lockdown) {
                      //console.log('rowstyle params.data.is_locked : ' + params.data.is_locked);
                      return { background: "lightgrey" };
                    }
                  },
                },
                {
                  autoSizeStrategy: {
                    type: "fitCellContents",
                  },
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataGrid;
