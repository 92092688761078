import ExcelJS from "exceljs";
import { useCallback, useRef, useState, React } from "react";
import { Button, Grid } from "@mui/material";
import useAxiosPrivate from "../auths/hooks/useAxiosPrivate";

export default function UploadSegment() {
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState("");
  const axiosPrivate = useAxiosPrivate();

  const overwriteSegment = useCallback(
    async (segmentList, length) => {
      alert("overwrite Segment for: " + length + " entries");
      try {
        await axiosPrivate.post("/client_bundles/segmentList", { segmentList });
      } catch (err) {
        if (!err?.response) {
          console.log("No Server Response" + err);
        } else {
          console.log("overwrite Segment Failed" + err);
        }
      }
    },
    [axiosPrivate],
  );
  const calculatePureSegment = useCallback(async () => {
    //Axios Call
    console.log("calculatePureSegment");
    try {
      await axiosPrivate.post("/client_bundles/calcPureSeg");
      alert("Reihe Segment wird markiert.");
    } catch (err) {
      if (!err?.response) {
        alert("No Server Response" + err);
      } else {
        alert(err.response);
      }
    }
  }, []);
  const readUploadSegmentFile = async (e) => {
    e.preventDefault();

    if (!e.target.files) {
      setErrMsg("No file to read");
      return;
    }

    try {
      const file = e.target.files[0];
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(file);
      const worksheet = workbook.worksheets.find((sheet) => sheet.name);

      if (!worksheet) {
        alert("Nichts wurde hochgeladen.");
        return;
      }

      const headerRow = worksheet.getRow(1).values;
      const json = [];

      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        if (rowNumber === 1) {
          // Skip header row
          return;
        }

        const rowData = {};
        row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
          const header = headerRow[colNumber];
          if (cell.value === undefined || cell.value === null) {
            rowData[header] = "";
          } else if (typeof cell.value === "object") {
            if (cell.value instanceof Date) {
              // Convert date to string or handle it as you need
              rowData[header] = cell.value.toISOString();
            } else if (cell.value.text !== undefined) {
              rowData[header] = cell.value.text.trim();
            } else {
              // Handle other object types as needed
              rowData[header] = JSON.stringify(cell.value);
            }
          } else if (typeof cell.value === "string") {
            rowData[header] = cell.value.trim();
          } else {
            rowData[header] = cell.value;
          }
        });

        if (!rowData[headerRow[1]] && rowData[headerRow[1]] !== 0) {
          //console.log('Error: Row data does not contain an entry for the first cell');
        } else {
          json.push(rowData);
        }
      });

      // axios call
      const jsonString = JSON.stringify(json);
      const length = json.length;
      overwriteSegment(jsonString, length);
      alert("Datei wurde erfolgreich hochgeladen.");
    } catch (error) {
      setErrMsg("Etwas ist schiefgelaufen, bitte erneut hochladen.");
      console.error(error);
    }
  };
  return (
    <section>
      <form>
        <label htmlFor="upload">
          <h1>Segment Liste Hochladen (Hinzufügen bzw. ändern)</h1>
          Excel wie in der Segmentliste steht hochladen.
          <br></br>
          Dabei sind (kennzeichen) Pflichfelder.
          <br></br>
          Es darf keine Duplikate bei kennzeichen existieren.
        </label>
        <br></br>
        <br></br>
        <input type="file" name="upload" id="uploadCredentials" onChange={readUploadSegmentFile} />
      </form>
      <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
        {errMsg}
      </p>
      <p></p>
      <p></p>
      <p></p>
      <p></p>
      <p></p>
      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <Grid container dir="row">
          <h1>Segment Reinheit Vorbereiten</h1>
          <Grid container dir="row" alignItems="flex-end">
            <Grid item>
              <Button variant="contained" onClick={calculatePureSegment}>
                Setze Segment Reinheit Kennzeichen
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </section>
  );
}
