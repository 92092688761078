import ExcelJS from "exceljs";
import { useCallback, useRef, useState, React } from "react";
import useAxiosPrivate from "../auths/hooks/useAxiosPrivate";
import iconv from "iconv-lite";

export default function UploadZielberater() {
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState("");
  const axiosPrivate = useAxiosPrivate();

  const upsertZielberater = useCallback(
    async (item) => {
      console.log("update Zielberater ");
      item.consultant_id_target = item.consultant_id_target.toString();
      try {
        await axiosPrivate.post("/consultants/upsertZielberater", item);
      } catch (err) {
        if (!err?.response) {
          console.log("No Server Response " + err);
        } else {
          console.log("upsertZielberater Failed " + err);
          alert("Failure beim Hochladen, bitte überprüfe die Datei Datenqualität: " + JSON.stringify(item));
        }
      }
    },
    [axiosPrivate],
  );

  const readUploadZielberaterFile = async (e) => {
    e.preventDefault();

    if (!e.target.files) {
      setErrMsg("No file to read");
      return;
    }

    try {
      const file = e.target.files[0];
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(file);
      const worksheet = workbook.worksheets.find((sheet) => sheet.name);

      if (!worksheet) {
        alert("Nichts wurde hochgeladen.");
        return;
      }

      const headerRow = worksheet.getRow(1).values;
      let updated_entries = 0;
      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        if (rowNumber === 1) {
          // Skip header row
          return;
        }

        const rowData = {};
        row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
          const header = headerRow[colNumber];
          let cellData = "";

          if (cell.value === undefined || cell.value === null) {
            console.log("undefined Header: ", header, " | Value: ", cell.value);
            cellData = "";
          } else if (typeof cell.value === "object") {
            if (cell.value instanceof Date) {
              // Convert date to string or handle it as you need
              cellData = cell.value.toISOString();
            } else if (cell.value.text !== undefined) {
              cellData = cell.value.text.trim();
            } else {
              // Handle other object types as needed
              cellData = JSON.stringify(cell.value);
            }
          } else if (typeof cell.value === "string") {
            console.log("string Header: ", header, " | Value: ", cell.value);
            cellData = cell.value.trim();
          } else {
            cellData = cell.value;
          }

          // Check if the data is already UTF-8 encoded
          const isUTF8 = iconv.encodingExists("UTF-8");
          if (isUTF8 && iconv.decode(iconv.encode(cellData, "UTF-8"), "UTF-8") === cellData) {
            rowData[header] = cellData;
          } else {
            // Encode the data to UTF-8
            cellData = iconv.encode(cellData, "UTF-8").toString();
            console.log("converting cell to UTF-8: " + cellData);
            rowData[header] = cellData;
          }
        });

        //console.log('Row data:', rowData);
        if (!rowData[headerRow[1]] && rowData[headerRow[1]] !== 0) {
          //console.log('Error: Row data does not contain an entry for the first cell');
        } else {
          updated_entries = updated_entries + 1;
          upsertZielberater(rowData);
        }
      });

      alert("Datei wurde erfolgreich hochgeladen, " + updated_entries + " Daten sind aktualisiert.");
    } catch (error) {
      setErrMsg("Etwas ist schiefgelaufen, bitte erneut hochladen.");
      console.error(error);
    }
  };

  return (
    <section>
      <form>
        <label htmlFor="upload">
          <h1>Ziel Berater Liste Hochladen zum Hinzufügen</h1>
          Excel wie in der Beraterliste steht hochladen. Dabei bitte beachten:
          <ol>
            <li>
              Alle bestehende Berater, die nicht geändert werden soll, müssen nicht wiederholend in der Hochlade Excel stehen. Die
              bestehende Beraterdaten werden unverändert, wenn deren Berater OE nicht in der hochgeladene Excel steht.
            </li>
            <li>Bitte achte drauf, dass die hinzugefügte Berater auch beim (Alt)Beraterliste hinzugefügt wird.</li>
            <li>Das Feld Ziel Berater OE (consultant_id_target) ist Pflichfeld für neue Berater.</li>
            <li>
              Es darf keine Quote (Doppelte oder Einfache Anführungszeichen) enthalten sein, weil es in JSON vorreserviert sind. Bitte alle
              Anführungszeichen durch andere Zeichen ersetzen, z.B. durch Unterstrich.
            </li>
            <li>Berater OE (consultant_id_target) darf keine Duplikate enthalten</li>
            <li>
              Wenn das consultant_id noch nicht exisiert, wird es erneut hinzugefügt. Wenn es schon existiert, wird die andere Werte durch
              hochgeladene Werte ersetzt. Passwort muss in dem Fall nicht immer befüllt sein.
            </li>
            <li>
              Email soll aber gültige Email sein (z.B. keine Umlaute und Sondernzeilen), weil ansonsten der Einloggen und Automatische
              Versand nicht mehr funktionieren können. Dabei muss Groß und Klein schreiben nicht geachtet werden. Beim Hochladen werden alle
              zum kleinschreiben automatisch umgewandet.
            </li>
          </ol>
        </label>
        <br></br>
        <br></br>
        <input type="file" name="upload" id="uploadCredentials" onChange={readUploadZielberaterFile} />
      </form>
      <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
        {errMsg}
      </p>
    </section>
  );
}
