import React from "react";
import NavBar from "./NavBar";
import { Box } from "@mui/system";

export default function Layout({ children }) {
  return (
    <>
      <Box display="flex" flexDirection="column" minHeight="100vh">
        <NavBar />
        <Box px={{ sm: 3, lg: 4, xl: 8 }} mt={2} flex="1" flexDirection="column" style={{ backgroundColor: "#F0F0F0" }}>
          {children}
        </Box>
      </Box>
    </>
  );
}
