import React, { useCallback, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import useAxiosPrivate from "../auths/hooks/useAxiosPrivate";
//import { grey } from '@mui/material/colors';
import { useNavigate } from "react-router-dom";
import CLIENT_LIST from "../../utilities/client_list";

const ClientListe = () => {
  const axiosPrivate = useAxiosPrivate();
  const gridRef = useRef();
  const navigate = useNavigate();
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState();
  //const ID_SEPERATOR = '_ID_';
  const getRowId = (params) => params.data._id;

  const LOGIN_URL = "/login";

  const [columnDefs] = useState([
    //setColumnDefs
    {
      headerName: "Details aufblättern",
      children: [
        {
          field: "verbund_nr",
        },
        {
          field: "wirtschaftsverbund_nr",
        },
        {
          field: "person_nr",
        },
        {
          field: "lastname",
        },
        {
          field: "firstname",
        },
        {
          field: "right_form",
          filter: "agSetColumnFilter",
        },
        {
          field: "consultant_id",
        },
        {
          field: "role",
          filter: "agSetColumnFilter",
        },
        {
          field: "branch",
          filter: "agSetColumnFilter",
        },
        {
          field: "client_type",
          filter: "agSetColumnFilter",
        },
        {
          field: "double_person_nr",
          hide: process.env.REACT_APP_CLIENT === CLIENT_LIST.SKPDH ? false : true,
        },
        {
          field: "lei_code",
          hide: process.env.REACT_APP_CLIENT === CLIENT_LIST.SKDU ? false : true,
        },
        {
          field: "active_volume",
          filter: "agNumberColumnFilter",
        },
        {
          field: "aussen_umsatz",
          filter: "agNumberColumnFilter",
        },
        {
          field: "mod_consultant_id",
          initialWidth: 150,
        },
        {
          field: "last_modified",
          initialWidth: 150,
        },
      ],
    },
  ]);
  const defaultColDef = useMemo(() => {
    return {
      //flex: 1,
      filter: true,
      sortable: true,
      floatingFilter: true,
      resizable: true,
      minWidth: 150,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      editable: true,
    };
  }, []);
  const submitChange = useCallback(
    async (id, field_name, field_value) => {
      console.log("update client data for " + id);

      const item = {
        id: id,
        field_name: field_name,
        field_value: field_value,
      };
      console.log("item: " + JSON.stringify(item));
      try {
        await axiosPrivate.post("/clients/updateSingleField", item);
      } catch (err) {
        if (!err?.response) {
          console.log("No Server Response" + err);
        } else {
          console.log("SubmitChange Failed");
        }
      }
    },
    [axiosPrivate],
  );
  const onCellEditRequest = useCallback(
    (event) => {
      const oldData = event.data;
      const field = event.colDef.field;
      const newData = { ...oldData };
      newData[field] = event.newValue;
      console.log("onCellEditRequest, updating " + field + " to " + event.newValue);
      const tx = {
        update: [newData],
      };
      event.api.applyTransaction(tx);
      //console.log({ newData });
      const row_id = event.node.id;
      console.log("extracted from rowID: " + row_id);
      //Axios call
      submitChange(row_id, field, event.newValue);
    },
    [submitChange],
  );
  const readData = useCallback(async () => {
    try {
      await axiosPrivate
        .get("clients")
        .then((res) => res.data)
        .then((data) => {
          setRowData(data);
        });
    } catch (err) {
      if (!err?.response) {
        console.log("No Server Response" + err);
      } else {
        console.log("ReadData Failed" + err.response.toString);
      }
      navigate(LOGIN_URL);
    }
  }, [axiosPrivate]);

  const onGridReady = useCallback(
    (params) => {
      readData();
      gridRef.current.api = params.api;
    },
    [readData],
  );
  const onFirstDataRendered = useCallback(() => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);
  // const onBtPrint = useCallback(() => {
  //   console.log('print');
  //   setTimeout(function () {
  //     print();
  //     //setNormal(api);
  //   }, 2000);
  // }, []);
  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agTotalAndFilteredRowCountComponent", align: "left" },
        { statusPanel: "agTotalRowCountComponent", align: "center" },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    };
  }, []);
  return (
    <div style={containerStyle}>
      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <div style={{ flexGrow: "1", height: "calc(100vh - 150px" }}>
          {/* height: 100% is not enough as AGGrid don't know the wrapper's height */}
          <div id="myStatisticGrid" className="ag-theme-alpine" style={gridStyle}>
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              getRowId={getRowId}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              onFirstDataRendered={onFirstDataRendered}
              onCellEditRequest={onCellEditRequest}
              readOnlyEdit={true}
              //enableCellChangeFlash={true}
              animateRows={true}
              statusBar={statusBar}
              onGri
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientListe;
