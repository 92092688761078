import React, { useCallback, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { LicenseManager } from "ag-grid-enterprise";
import useAxiosPrivate from "../auths/hooks/useAxiosPrivate";
import useAuth from "../auths/hooks/useAuth";
//import { grey } from '@mui/material/colors';
import { Grid } from "@mui/material";
import CLIENT_ROLES_LIST from "../../utilities/client_role_list";
const LOGIN_URL = "/login";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import CLIENT_LIST from "../../utilities/client_list";

const greenColor = "#006400";

LicenseManager.setLicenseKey(process.env.REACT_APP_AGGRID_LICENCE);

const renderEuro = (p) => {
  const amount = p.value;
  return (
    Math.round(amount)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €"
  );
};

// var rowDrag = function (params) {
//   // only rows that are NOT groups should be draggable
//   return !params.node.group && !params.node.data.is_verbund_leader;
// };

const mostFrequentValueGetter = (params) => {
  const values = params.values;
  const frequencyMap = {};
  let maxFrequency = 0;
  let mostFrequentValue = null;

  values.forEach((value) => {
    const frequency = (frequencyMap[value] || 0) + 1;
    frequencyMap[value] = frequency;
    if (frequency > maxFrequency) {
      maxFrequency = frequency;
      mostFrequentValue = value;
    }
  });

  return mostFrequentValue;
};

const customAggFunc = (params) => {
  const uniquePersons = new Set(params.rowNode.allLeafChildren.map((node) => node.data.person_nr));
  let sum = 0;
  //console.log({ uniquePersons });
  uniquePersons.forEach((person) => {
    const personItems = params.rowNode.allLeafChildren.filter((node) => node.data.person_nr === person);
    const personAmount = personItems.reduce((total, node) => total + node.data.active_volume, 0);
    sum += personAmount;
  });
  return sum;
};

// const customValueGetter = (fieldToDisplay) => {
//   return function (params) {
//     console.log('customValueGetter');
//     const node = params.node;
//     console.log(node);
//     if (node.group) {
//       const representativeRow = node.allLeafChildren.find(
//         (childNode) => childNode.data.role === CLIENT_ROLES_LIST.LEADER_ROLE,
//       );
//       console.log(representativeRow);
//       if (representativeRow) {
//         return representativeRow.data[fieldToDisplay];
//       }
//     }
//     // Fallback to default aggregation for other fields
//     console.log(params);
//     return params.value ? String(params.value) : null;
//   };
// };

const customCellRenderer = (params) => {
  //console.log('customCellRenderer');
  const node = params.node;
  //console.log(params);
  if (node.group && !node.footer) {
    const representativeRow = node.allLeafChildren.find((childNode) => childNode.data.role === CLIENT_ROLES_LIST.LEADER_ROLE);
    //console.log(representativeRow);
    if (representativeRow) {
      return <span>{representativeRow.data[params.colDef.field]}</span>;
    }
  }
  // Render the default cell value for other rows
  //console.log(params);
  return params.value ? String(params.value) : null;
};

const customBearbeiterRenderer = (params) => {
  //console.log('customCellRenderer');
  const node = params.node;
  //console.log(params);
  if (node.group && !node.footer) {
    var groupHasNonEmptyString = node.allLeafChildren.some(function (child) {
      return child.data.mod_consultant_id !== "" && child.data.mod_consultant_id !== undefined;
    });

    if (groupHasNonEmptyString) {
      return <SyncAltIcon sx={{ color: greenColor }} size="large" />;
    } else {
      return <></>;
    }
  }
  // Render the default cell value for other rows
  //console.log(node);
  const last_modified_consulutant = node.data.mod_consultant_id;
  if (last_modified_consulutant) {
    return last_modified_consulutant;
  } else {
    return <></>;
  }
};

const processPivotResultColDef = (colDef) => {
  if (colDef.aggFunc === "sum") {
    const field = colDef.person_nr;
    colDef.aggFunc = (params) => {
      const uniqueValues = new Set(params.rowNode.allLeafChildren.map((node) => node.data[field]));
      //console.log({ uniqueValues });
      const sum = Array.from(uniqueValues).reduce((acc, curr) => acc + curr, 0);
      return sum;
    };
  }
};

const EinheitSichten = () => {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const gridRef = useRef();
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState();
  const getRowId = (params) => params.data._id; //unit_name + params.data.person_name;
  const [collapsed, setCollapsed] = useState(true);
  const [showAllClients, setShowAllClients] = useState(false);

  const [columnDefs] = useState([
    //setColumnDefs
    {
      field: "mod_consultant_id",
      headerName: "Bearbeitet",
      // valueGetter: function (params) {
      //   return params;
      // },
      cellRenderer: customBearbeiterRenderer,
    },
    {
      field: "verbund_nr",
      headerName: "Personennummer Verbundführer",
      rowGroup: true,
      //aggFunc: 'first',
      hide: true,
      suppressSizeToFit: true,
      sort: "asc",
      filter: "agTextColumnFilter",
    },
    {
      field: "wirtschaftsverbund_nr",
      headerName: process.env.REACT_APP_CLIENT === CLIENT_LIST.SKDU ? "Akquisitionsverbund Nr." : "Wirtschaftsverbund Nr.",
      minWidth: 150,
      cellRenderer: customCellRenderer,
    },
    {
      field: "person_nr",
      minWidth: 100,
      headerName: "Personen Nr.",
      cellRenderer: customCellRenderer,
    },
    {
      field: "lastname",
      minWidth: 100,
      headerName: "Nachname",
      //valueGetter: customValueGetter,
      cellRenderer: customCellRenderer,
    },
    {
      field: "firstname",
      minWidth: 100,
      headerName: "Vorname",
      //valueGetter: customValueGetter,
      cellRenderer: customCellRenderer,
    },
    {
      field: "right_form",
      minWidth: 100,
      headerName: "Rechtsform",
      //valueGetter: customValueGetter,
      cellRenderer: customCellRenderer,
      hide: true,
    },
    {
      field: "consultant_id",
      headerName: "OE Kundenbetreuer",
      filter: "agSetColumnFilter",
      aggFunc: mostFrequentValueGetter,
      editable: true,
      //valueGetter: customValueGetter,
      cellRenderer: customCellRenderer,
    },
    {
      field: "role",
      minWidth: 100,
      headerName: "Rolle der Person",
      filter: "agSetColumnFilter",
    },
    {
      field: "branch",
      minWidth: 100,
      headerName: "Wirtschaftszweig",
      //valueGetter: customValueGetter,
      cellRenderer: customCellRenderer,
      filter: "agSetColumnFilter",
    },
    {
      field: "client_type",
      minWidth: 100,
      headerName: "Kundentyp",
      columnGroupShow: "open",
      hide: process.env.REACT_APP_CLIENT === CLIENT_LIST.SKDU ? true : false,
    },
    {
      field: "double_person_nr",
      minWidth: 100,
      headerName: "Doppelte Kunden",
      hide: process.env.REACT_APP_CLIENT === CLIENT_LIST.SKPDH ? false : true,
    },
    {
      field: "lei_code",
      minWidth: 100,
      headerName: "LEI Code",
      cellRenderer: customCellRenderer,
      hide: process.env.REACT_APP_CLIENT === CLIENT_LIST.SKDU ? false : true,
    },
    {
      field: "active_volume",
      headerName: "Aktivvolumen",
      aggFunc: "sum",
      cellRenderer: renderEuro,
      filter: "agNumberColumnFilter",
    },
    {
      field: "aussen_umsatz",
      headerName: "Außenumsatz",
      cellRenderer: renderEuro,
      aggFunc: "sum",
      filter: "agNumberColumnFilter",
    },
  ]);
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: true,
      sortable: true,
      floatingFilter: true,
      resizable: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      //backgroundColor: grey,
      minWidth: 100,
    };
  }, []);

  const autoGroupColumnDef = useMemo(() => {
    return {
      //headerName: 'Betreuung Einheit',
      minWidth: 220,
      maxWidth: 500,
      sortable: true,
      field: "group_field", // add a field to identify the top level group nodes
      cellRenderer: "agGroupCellRenderer", // use the default group cell renderer
      cellRendererParams: {
        suppressCount: false, // suppress the count label
      },
      aggFunc: customAggFunc,
      children: [
        {
          field: "active_volume",
          aggFunc: customAggFunc,
        },
      ],
    };
  }, []);

  const loadAllClients = useCallback(async () => {
    //if (!rawData.length) {
    try {
      const api = gridRef.current.api;
      await axiosPrivate
        .get("clients/myclients", { timeout: 600000 })
        .then((res) => res.data)
        .then((data) => {
          console.log("read complete data successful");
          let filteredData = data;
          setRowData(filteredData);
          if (api) {
            api.deselectAll();
          }
        });
    } catch (err) {
      if (!err?.response) {
        console.log("No Server Response for clients/myclients: " + err);
      } else {
        console.log("ReadData Failed by loadAllClients " + err);
      }
      navigate(LOGIN_URL);
    }
    //}
  }, [rowData, axiosPrivate]);

  const loadMyClients = useCallback(async () => {
    //if (!rawData.length) {
    try {
      const api = gridRef.current.api;
      console.log("loadMyClients");
      await axiosPrivate
        .get("clients/myOwnClients", { timeout: 600000 })
        .then((res) => res.data)
        .then((data) => {
          console.log("read complete data successful");
          let filteredData = data;
          setRowData(filteredData);
          if (api) {
            api.deselectAll();
          }
        });
    } catch (err) {
      if (!err?.response) {
        console.log("No Server Response for clients/myOwnClients: " + err);
      } else {
        console.log("ReadData Failed by loadMyClients " + err);
      }
      navigate(LOGIN_URL);
    }
    //}
  }, [rowData, axiosPrivate]);

  const toggleData = useCallback(async () => {
    console.log("toggleLeftDate");
    if (showAllClients) {
      setShowAllClients(false);
      loadMyClients();
    } else {
      setShowAllClients(true);
      //alert('Bitte einige Minuten warten, bis die Seite sich neu lädt.');
      loadAllClients();
    }
  }, [axiosPrivate, showAllClients]);

  const readData = useCallback(async () => {
    var method = showAllClients ? "clients/myclients" : "clients/myOwnClients";
    try {
      await axiosPrivate
        .get(
          method,
          { data: auth.competences },
          {
            timeout: 600000,
          },
        )
        .then((res) => res.data)
        .then((data) => {
          //console.log({ data });
          setRowData(data);
          gridRef.current.api.refresh;
        });
    } catch (err) {
      if (!err?.response) {
        console.log("No Server Response" + err);
      } else {
        console.log(method + " Failed" + err.response.toString);
      }
      navigate(LOGIN_URL);
    }
  }, [auth.competences, axiosPrivate, showAllClients]);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agTotalAndFilteredRowCountComponent", align: "left" },
        { statusPanel: "agTotalRowCountComponent", align: "center" },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    };
  }, []);

  const onGridReady = useCallback(
    async (params) => {
      readData();
      gridRef.current.api = params.api;
    },
    [readData],
  );
  const onFirstDataRendered = useCallback(() => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const toggleCollapse = useCallback(() => {
    if (collapsed) {
      gridRef.current.api.expandAll();
      setCollapsed(false);
    } else {
      gridRef.current.api.collapseAll();
      setCollapsed(true);
    }
  }, [collapsed]);

  const clearFilter = useCallback(() => {
    // leftApi.clearFilter();
    gridRef.current.api.setFilterModel(null);
  }, []);

  const getTopToolBar = () => (
    <div className="example-toolbar panel panel-default">
      <div className="panel-body">
        {/* <input type="checkbox" id="toggleCheck" checked={checkBoxSelected} onChange={onCheckboxChange} />
        <label htmlFor="toggleCheck">Menge Bearbeitung</label> */}
        <button onClick={toggleCollapse}>{collapsed ? "Aufblättern" : "Ausblenden"}</button>
        <button onClick={toggleData}>{showAllClients ? "Nur meine Kunden" : "Alle Kunden"}</button>
        <button onClick={clearFilter}>Filter Zurücksetzen</button>
        {
          <span className="input-group-button">
            <button type="button" className="btn btn-default reset" style={{ marginLeft: "5px" }} onClick={readData}>
              <i className="fas fa-redo" style={{ marginRight: "5px" }}></i>Neu laden
            </button>
          </span>
        }
      </div>
    </div>
  );

  return (
    <div style={containerStyle}>
      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <Grid container dir="row" mb={2} justifyContent="space-between">
          <Grid item>{getTopToolBar()}</Grid>
          <Grid item></Grid>
        </Grid>
        <div style={{ flexGrow: "1", height: "calc(100vh - 150px" }}>
          {/* height: 100% is not enough as AGGrid don't know the wrapper's height */}
          <div id="myGroupDataGrid" className="ag-theme-alpine" style={gridStyle}>
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              getRowId={getRowId}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              autoGroupColumnDef={autoGroupColumnDef}
              processPivotResultColDef={processPivotResultColDef}
              groupDisplayType={"multipleColumns"}
              suppressAggFuncInHeader={true}
              //showOpenedGroup={true}
              rowDragEntireRow={true}
              rowDragMultiRow={true}
              rowSelection={"multiple"}
              rowMultiSelectWithClick={true}
              onGridReady={onGridReady}
              onFirstDataRendered={onFirstDataRendered}
              animateRows={true}
              stopEditingWhenCellsLoseFocus={true}
              readOnlyEdit={true}
              //onCellEditRequest={onCellEditRequest}
              //onRowDragMove={onRowDragMove}
              statusBar={statusBar}
              enableRowGroup={true}
              enablePivot={true}
              onGri
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EinheitSichten;
